export class SolanProject {
  readonly projectId: string;
  readonly studentUserId: string;
  readonly schoolYear: number;
  readonly name: string;
  readonly description: string;
  readonly started: boolean;
  readonly completed: boolean;
  readonly studentInputLocked: boolean;

  constructor(
    projectId: string,
    studentUserId: string,
    schoolYear: number,
    name: string,
    description: string,
    started: boolean,
    completed: boolean,
    studentInputLocked: boolean
  ) {
    this.projectId = projectId;
    this.studentUserId = studentUserId;
    this.schoolYear = schoolYear;
    this.name = name;
    this.description = description;
    this.started = started;
    this.completed = completed;
    this.studentInputLocked = studentInputLocked;
  }
}
