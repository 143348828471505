





















import Vue, { PropType } from "vue";
import { Loadable } from "@/ts/Loadable";
import { CurriculumPeriodMode } from "@/ts/objects/enum/CurriculumPeriodMode";
import { CurriculumTabName } from "@/ts/objects/enum/CurriculumTabName";
import { EECurriculum } from "@/ts/objects/noneditable/curriculum/EECurriculum";
import { EECJournal, EECJournalTree } from "@/ts/objects/noneditable/curriculum/EECJournal";
import TipBlock from "@/components/TipBlock.vue";
import { isNullish } from "@/ts/utils/CommonUtil";

export default Vue.extend({
  name: "CurriculumReadMenuEECItem",
  components: { TipBlock },
  props: {
    /**
     * 月別表示/年間表示。
     */
    periodMode: { type: String as PropType<CurriculumPeriodMode>, required: true },

    /**
     * 選択中の月。
     */
    selectedMonth: { type: Number as PropType<number | null | undefined> },

    /**
     * 文書評価教科。
     */
    eeCurriculum: { type: Object as PropType<EECurriculum>, required: true },

    /**
     * 現在表示中の児童生徒配下の、 **全教科の** 学習記録。
     */
    allJournalTrees: { type: Object as PropType<Loadable<EECJournalTree[]>>, required: true },

    /**
     * 選択中の教科のリソース名。
     * 数値評価教科でも文書評価教科でもありうる。
     */
    selectedCurriculum: { type: String as PropType<string | null | undefined> },

    /**
     * 教科学習のパスを取得する関数。
     */
    getPath: {
      type: Function as PropType<
        (
          tab: CurriculumTabName,
          periodMode: CurriculumPeriodMode | null,
          month: number | null,
          resourceName: string | null
        ) => string
      >,
      required: true
    }
  },
  computed: {
    /**
     * このタブ(教科)が選択状態ならtrue。
     */
    selected(): boolean {
      return this.selectedCurriculum === this.eeCurriculum.resourceName;
    },
    /**
     * チェック対象の学習記録のリスト。
     * このリストの要素が1件以上で、すべて教師コメント済なら、「コメント済」マークを表示する。
     */
    journalsToCheck(): EECJournal[] {
      const journals = this.allJournalTrees.data;
      if (journals === null) return [];

      return journals
        .filter(
          j =>
            j.self.eecId === this.eeCurriculum.eecId &&
            (this.periodMode === "yearly" || j.self.month === this.selectedMonth)
        )
        .map(j => j.self);
    },
    /**
     * チェック対象の学習記録が、すべてコメント済ならtrue。
     * ※ ただし、これがtrueでも、そもそもチェック対象が0件なら「コメント済」マークを付けない。
     */
    allCommented(): boolean {
      return this.journalsToCheck.every(j => !isNullish(j.teacherComment) && j.teacherComment !== "");
    }
  }
});
