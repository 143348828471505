















import Vue from "vue";
import log from "loglevel";

export type SelectItem = {
  key: string;
  label: string;
};

export default Vue.extend({
  name: "PopupSelectButton",
  props: {
    items: { type: Array as () => SelectItem[], default: () => [] },
    align: { type: String, default: "left" }, // left, center, right
    closeOnSelect: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false }
  },
  data(): {
    showPopup: boolean;
  } {
    return {
      showPopup: false
    };
  },

  methods: {
    togglePopup() {
      if (this.disabled || this.items.length === 0) {
        this.showPopup = false;
      } else {
        this.showPopup = !this.showPopup;
      }
    },
    onClickOutside() {
      this.showPopup = false;
    },
    onClickItem(key: string) {
      log.debug(`onClickItem: key=${key}`);
      this.$emit("input", key);
      if (this.closeOnSelect) {
        this.showPopup = false;
      }
    }
  }
});
