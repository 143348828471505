import { YearMonth } from "@/ts/objects/noneditable/value/YearMonth";
import { projectRnameToId } from "@/ts/utils/AppUtil";
import { Quarter } from "@/ts/objects/noneditable/value/Quarter";

export class Project {
  readonly self: string;
  readonly projectId: string;
  readonly classId: string;
  readonly quarter: Quarter;
  readonly name: string;
  readonly description: string;
  readonly relatedSyllabus: string;
  readonly viewPointSEnabled: boolean;
  readonly viewPointAEnabled: boolean;
  readonly viewPointBEnabled: boolean;
  readonly viewPointCEnabled: boolean;
  readonly startingMonth: YearMonth;
  readonly endingMonth: YearMonth;
  readonly published: boolean;
  readonly completed: boolean;
  readonly rubrics: string[];

  constructor(
    self: string,
    classId: string,
    quarter: Quarter,
    name: string,
    description: string,
    relatedSyllabus: string,
    viewPointSEnabled: boolean,
    viewPointAEnabled: boolean,
    viewPointBEnabled: boolean,
    viewPointCEnabled: boolean,
    startingYear: number,
    startingMonth: number,
    endingYear: number,
    endingMonth: number,
    published: boolean,
    completed: boolean,
    rubrics: string[]
  ) {
    this.self = self;
    this.projectId = projectRnameToId(self) ?? "unknown"; // TODO これどうにかする？
    this.classId = classId;
    this.quarter = quarter;
    this.name = name;
    this.description = description;
    this.relatedSyllabus = relatedSyllabus;
    this.viewPointSEnabled = viewPointSEnabled;
    this.viewPointAEnabled = viewPointAEnabled;
    this.viewPointBEnabled = viewPointBEnabled;
    this.viewPointCEnabled = viewPointCEnabled;
    this.startingMonth = new YearMonth(startingYear, startingMonth);
    this.endingMonth = new YearMonth(endingYear, endingMonth);
    this.published = published;
    this.completed = completed;
    this.rubrics = rubrics;
  }
}
