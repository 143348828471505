import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { NECurriculumTree } from "@/ts/objects/noneditable/curriculum/NECurriculum";
import { Err } from "@/ts/objects/Err";
import { CurriculumTestData, getCurriculumTestData } from "@/test-tools/CurriculumTestData";
import { Grade } from "@/ts/objects/noneditable/value/Grade";
import { EECurriculumTree } from "@/ts/objects/noneditable/curriculum/EECurriculum";
import { NECEvaluation } from "@/ts/objects/noneditable/curriculum/NECEvaluation";
import { EECJournalStudentTree } from "@/ts/objects/noneditable/curriculum/EECJournal";
import log from "loglevel";

export class CurriculumRepositoryMock extends CurriculumRepository {
  private _testData: CurriculumTestData | null = null;
  private get testData(): CurriculumTestData {
    if (this._testData !== null) return this._testData;
    this._testData = getCurriculumTestData(this);
    return this._testData;
  }

  async listNECurriculums(schoolYear: number, grade: Grade): Promise<NECurriculumTree[] | Err> {
    log.debug(`CurriculumRepositoryMock.listNECurriculums: schoolYear=${schoolYear}, grade=${grade.value}`);
    const data = Object.values(this.testData.neCurriculum).filter(
      nec => nec.self.schoolYear === schoolYear && nec.self.grade.value === grade.value
    );
    log.debug(
      `CurriculumRepositoryMock.listNECurriculums: schoolYear=${schoolYear}, grade=${
        grade.value
      }, data=${JSON.stringify(data)}`
    );
    return data;
  }

  async listEECurriculums(schoolYear: number, grade: Grade): Promise<EECurriculumTree[] | Err> {
    return Object.values(this.testData.eeCurriculum).filter(
      eec => eec.self.schoolYear === schoolYear && eec.self.grade.value === grade.value
    );
  }

  async listNECEvaluations(
    necId: string,
    viewPointId?: string,
    contentId?: string,
    month?: number,
    studentUserId?: string
  ): Promise<NECEvaluation[] | Err> {
    return this.testData.necEvaluation.filter(
      e =>
        e.necId === necId &&
        (viewPointId === undefined || e.viewPointId === viewPointId) &&
        (contentId === undefined || e.contentId === contentId) &&
        (month === undefined || e.month === month) &&
        (studentUserId === undefined || e.studentUserId === studentUserId)
    );
  }

  async listEECJournalStudents(eecId: string, studentUserId?: string): Promise<EECJournalStudentTree[] | Err> {
    return Object.values(this.testData.eecJournalStudent).filter(
      s => s.self.eecId === eecId && (studentUserId === undefined || s.self.studentUserId === studentUserId)
    );
  }
}
