







































import Vue, { PropType } from "vue";
import { NECurriculumTree } from "@/ts/objects/noneditable/curriculum/NECurriculum";
import { EECurriculumTree } from "@/ts/objects/noneditable/curriculum/EECurriculum";
import { Grade } from "@/ts/objects/noneditable/value/Grade";
import { CurriculumPeriodMode } from "@/ts/objects/enum/CurriculumPeriodMode";
import { Loadable } from "@/ts/Loadable";
import YearMonthControl from "@/views/curriculum/components/YearMonthControl/YearMonthControl.vue";
import { CurriculumTabName } from "@/ts/objects/enum/CurriculumTabName";
import { NECEvaluation } from "@/ts/objects/noneditable/curriculum/NECEvaluation";
import { EECJournalTree } from "@/ts/objects/noneditable/curriculum/EECJournal";
import CurriculumReadMenuNECItem from "@/views/curriculum/student/CurriculumReadMenu/components/CurriculumReadMenuNECItem.vue";
import CurriculumReadMenuEECItem from "@/views/curriculum/student/CurriculumReadMenu/components/CurriculumReadMenuEECItem.vue";

export default Vue.extend({
  name: "CurriculumReadMenu",
  components: { CurriculumReadMenuEECItem, CurriculumReadMenuNECItem, YearMonthControl },
  props: {
    /**
     * 年度。
     */
    schoolYear: { type: Number, required: true },

    /**
     * 学年。
     */
    grade: { type: Object as PropType<Grade>, required: true },

    /**
     * 月別表示/年間表示。
     */
    periodMode: { type: String as PropType<CurriculumPeriodMode>, required: true },

    /**
     * 今月。
     */
    thisMonth: { type: Number, required: true },

    /**
     * 選択中の月。
     */
    selectedMonth: { type: Number },

    /**
     * 選択中の教科のリソース名。
     */
    selectedCurriculum: { type: String as PropType<string | null> },

    /**
     * 数値評価教科。
     */
    neCurriculumTrees: { type: Object as PropType<Loadable<NECurriculumTree[]>>, required: true },

    /**
     * 文書評価教科。
     */
    eeCurriculumTrees: { type: Object as PropType<Loadable<EECurriculumTree[]>>, required: true },

    /**
     * 現在表示中の児童生徒配下の、 **全教科の** 評価。
     */
    allEvaluations: { type: Object as PropType<Loadable<NECEvaluation[]>>, required: true },

    /**
     * 現在表示中の児童生徒配下の、 **全教科の** 学習記録。
     */
    allJournalTrees: { type: Object as PropType<Loadable<EECJournalTree[]>>, required: true },

    /**
     * 教科学習のパスを取得する関数。
     */
    getPath: {
      type: Function as PropType<
        (
          tab: CurriculumTabName,
          periodMode: CurriculumPeriodMode | null,
          month: number | null,
          resourceName: string | null
        ) => string
      >,
      required: true
    }
  },
  computed: {
    neCurriculumTreesData(): NECurriculumTree[] {
      return this.neCurriculumTrees.data ?? [];
    },
    eeCurriculumTreesData(): EECurriculumTree[] {
      return this.eeCurriculumTrees.data ?? [];
    },
    selectedMonthOrHyphen(): string {
      const month = this.selectedMonth;
      if (month === null) return "-";
      return `${month}`;
    }
  }
});
