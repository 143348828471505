import { EditableObject } from "@/ts/objects/editable/EditableObject";
import {
  EditableBoolean,
  EditableHashedString,
  EditableString
} from "@/ts/objects/editable/value/EditablePrimitiveValue";
import { DisplayableErr, Err } from "../../Err";
import { Editable } from "@/ts/objects/editable/Editable";
import { projectLookbackRnameToId } from "@/ts/utils/AppUtil";
import { messages } from "@/ts/const/Messages";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";

export class EditableProjectLookback extends EditableObject {
  readonly self: string;
  readonly projectId: string;
  readonly lookbackId: string;
  readonly studentUserId: string;
  readonly _studentComment: EditableHashedString;
  readonly _studentRating: EditableString;
  readonly _teacherComment: EditableHashedString;
  readonly _teacherRating: EditableString;
  readonly _teacherInputPublished: EditableBoolean;
  readonly _guardianComment: EditableHashedString;
  readonly studentInputLocked: boolean;
  readonly guardianInputLocked: boolean;

  constructor(
    repo: ProjectRepository,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean,
    self: string,
    studentUserId: string,
    studentComment: string,
    studentCommentHash: string,
    studentRating: string,
    teacherComment: string,
    teacherCommentHash: string,
    teacherRating: string,
    teacherInputPublished: boolean,
    guardianComment: string,
    guardianCommentHash: string,
    studentInputLocked: boolean,
    guardianInputLocked: boolean
  ) {
    super();
    this.self = self;
    const [projectId, lookbackId] = projectLookbackRnameToId(self) ?? ["unknown", "unknown"]; // TODO これどうにかする？
    this.projectId = projectId;
    this.lookbackId = lookbackId;
    this.studentUserId = studentUserId;
    this._studentComment = new EditableHashedString(
      "studentComment",
      studentComment,
      studentCommentHash,
      studentInputSavable,
      async (value, hash) => {
        const resp = await repo.patchLookback(projectId, lookbackId, { studentComment: { value, hash } });
        if (resp instanceof Err) return resp;
        return [resp.studentComment.value, resp.studentComment.hash];
      }
    );
    this._studentRating = new EditableString("studentRating", studentRating, studentInputSavable, async value => {
      const resp = await repo.patchLookback(projectId, lookbackId, { studentRating: value });
      if (resp instanceof Err) return resp;
      return resp.studentRating;
    });
    this._teacherComment = new EditableHashedString(
      "teacherComment",
      teacherComment,
      teacherCommentHash,
      teacherInputSavable,
      async (value, hash) => {
        const resp = await repo.patchLookback(projectId, lookbackId, { teacherComment: { value, hash } });
        if (resp instanceof Err) return resp;
        if (resp.teacherComment === undefined)
          return new DisplayableErr(`invalid response: ${JSON.stringify(resp)}`, messages.failedToLoadData);
        return [resp.teacherComment.value, resp.teacherComment.hash];
      }
    );
    this._teacherRating = new EditableString("teacherRating", teacherRating, teacherInputSavable, async value => {
      const resp = await repo.patchLookback(projectId, lookbackId, { teacherRating: value });
      if (resp instanceof Err) return resp;
      if (resp.teacherRating === undefined)
        return new DisplayableErr(`invalid response: ${JSON.stringify(resp)}`, messages.failedToLoadData);
      return resp.teacherRating;
    });
    this._teacherInputPublished = new EditableBoolean(
      "teacherInputPublished",
      teacherInputPublished,
      teacherInputSavable,
      async value => {
        const resp = await repo.patchLookback(projectId, lookbackId, { teacherInputPublished: value });
        if (resp instanceof Err) return resp;
        return resp.teacherInputPublished;
      }
    );
    this._guardianComment = new EditableHashedString(
      "guardianComment",
      guardianComment,
      guardianCommentHash,
      guardianInputSavable,
      async (value, hash) => {
        const resp = await repo.patchLookback(projectId, lookbackId, { guardianComment: { value, hash } });
        if (resp instanceof Err) return resp;
        return [resp.guardianComment.value, resp.guardianComment.hash];
      }
    );
    this.studentInputLocked = studentInputLocked;
    this.guardianInputLocked = guardianInputLocked;
  }

  protected allEditables(): Editable[] {
    return [
      this._studentComment,
      this._studentRating,
      this._teacherComment,
      this._teacherRating,
      this._teacherInputPublished,
      this._guardianComment
    ];
  }

  get studentComment(): string {
    return this._studentComment.value;
  }

  set studentComment(value: string) {
    this._studentComment.value = value;
  }

  get studentRating(): string {
    return this._studentRating.value;
  }

  set studentRating(value: string) {
    this._studentRating.value = value;
  }

  get teacherRating(): string {
    return this._teacherRating.value;
  }

  set teacherRating(value: string) {
    this._teacherRating.value = value;
  }

  get teacherComment(): string {
    return this._teacherComment.value;
  }

  set teacherComment(value: string) {
    this._teacherComment.value = value;
  }

  get teacherInputPublished(): boolean {
    return this._teacherInputPublished.value;
  }

  set teacherInputPublished(value: boolean) {
    this._teacherInputPublished.value = value;
  }

  get guardianComment(): string {
    return this._guardianComment.value;
  }

  set guardianComment(value: string) {
    this._guardianComment.value = value;
  }
}

// async function saveStudentComment(
//   projectId: string,
//   lookbackId: string,
//   value: string,
//   hash: string
// ): Promise<[string, string] | Err> {
//   const req: LookbackWrite = { studentComment: { value: value, hash: hash } };
//   const resp = await doReq(() => projectService.patchLookback(projectId, lookbackId, req));
//   if (resp instanceof Err) return resp;
//   return [resp.studentComment.value, resp.studentComment.hash];
// }
//
// async function saveStudentRating(projectId: string, lookbackId: string, value: string): Promise<string | Err> {
//   const req: LookbackWrite = { studentRating: value };
//   const resp = await doReq(() => projectService.patchLookback(projectId, lookbackId, req));
//   if (resp instanceof Err) return resp;
//   return resp.studentRating;
// }
//
// async function saveTeacherComment(
//   projectId: string,
//   lookbackId: string,
//   value: string,
//   hash: string
// ): Promise<[string, string] | Err> {
//   const req: LookbackWrite = { teacherComment: { value: value, hash: hash } };
//   const resp = await doReq(() => projectService.patchLookback(projectId, lookbackId, req));
//   if (resp instanceof Err) return resp;
//   if (resp.teacherComment === undefined)
//     return new DisplayableErr(`invalid response: ${JSON.stringify(resp)}`, messages.failedToLoadData);
//   return [resp.teacherComment.value, resp.teacherComment.hash];
// }
//
// async function saveTeacherRating(projectId: string, lookbackId: string, value: string): Promise<string | Err> {
//   const req: LookbackWrite = { teacherRating: value };
//   const resp = await doReq(() => projectService.patchLookback(projectId, lookbackId, req));
//   if (resp instanceof Err) return resp;
//   if (resp.teacherRating === undefined)
//     return new DisplayableErr(`invalid response: ${JSON.stringify(resp)}`, messages.failedToLoadData);
//   return resp.teacherRating;
// }
//
// async function saveTeacherInputPublished(
//   projectId: string,
//   lookbackId: string,
//   value: boolean
// ): Promise<boolean | Err> {
//   const req: LookbackWrite = { teacherInputPublished: value };
//   const resp = await doReq(() => projectService.patchLookback(projectId, lookbackId, req));
//   if (resp instanceof Err) return resp;
//   return resp.teacherInputPublished;
// }
//
// async function saveGuardianComment(
//   projectId: string,
//   lookbackId: string,
//   value: string,
//   hash: string
// ): Promise<[string, string] | Err> {
//   const req: LookbackWrite = { guardianComment: { value: value, hash: hash } };
//   const resp = await doReq(() => projectService.patchLookback(projectId, lookbackId, req));
//   if (resp instanceof Err) return resp;
//   return [resp.guardianComment.value, resp.guardianComment.hash];
// }

// Loaders

// export async function loadEditableProjectLookback(
//   resourceName: string,
//   teacherInputSavable: boolean,
//   studentInputSavable: boolean,
//   guardianInputSavable: boolean
// ): Promise<EditableProjectLookback | Err> {
//   const ids = projectLookbackRnameToId(resourceName);
//   if (ids === null) return new DisplayableErr(`invalid resourceName: ${resourceName}`, messages.failedToLoadData);
//   const r = await doReq(() => projectService.getLookback(ids[0], ids[1]));
//   if (r instanceof Err) return r;
//
//   return new EditableProjectLookback(
//     teacherInputSavable,
//     studentInputSavable,
//     guardianInputSavable,
//     r.self,
//     r.studentUserId,
//     r.studentComment.value,
//     r.studentComment.hash,
//     r.studentRating,
//     r.teacherComment?.value ?? "",
//     r.teacherComment?.hash ?? "",
//     r.teacherRating ?? "",
//     r.teacherInputPublished,
//     r.guardianComment.value,
//     r.guardianComment.hash,
//     r.studentInputLocked,
//     r.guardianInputLocked
//   );
// }
//
// export async function loadEditableProjectLookbacks(
//   projectId: string,
//   studentUserId: string | undefined,
//   teacherInputSavable: boolean,
//   studentInputSavable: boolean,
//   guardianInputSavable: boolean
// ): Promise<EditableProjectLookback[] | Err> {
//   const r = await doReq(() => projectService.listLookback(projectId, studentUserId));
//   if (r instanceof Err) return r;
//
//   return r.map(
//     l =>
//       new EditableProjectLookback(
//         teacherInputSavable,
//         studentInputSavable,
//         guardianInputSavable,
//         l.self,
//         l.studentUserId,
//         l.studentComment.value,
//         l.studentComment.hash,
//         l.studentRating,
//         l.teacherComment?.value ?? "",
//         l.teacherComment?.hash ?? "",
//         l.teacherRating ?? "",
//         l.teacherInputPublished,
//         l.guardianComment.value,
//         l.guardianComment.hash,
//         l.studentInputLocked,
//         l.guardianInputLocked
//       )
//   );
// }
