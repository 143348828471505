
















import Vue, { PropType } from "vue";
import { EECurriculum } from "@/ts/objects/noneditable/curriculum/EECurriculum";
import { Grade } from "@/ts/objects/noneditable/value/Grade";
import { Loadable } from "@/ts/Loadable";
import { CurriculumTabName } from "@/ts/objects/enum/CurriculumTabName";
import { CurriculumPeriodMode } from "@/ts/objects/enum/CurriculumPeriodMode";

export default Vue.extend({
  name: "CurriculumWriteMenu",
  props: {
    /**
     * 年度。
     */
    schoolYear: { type: Number, required: true },

    /**
     * 学年。
     */
    grade: { type: Object as PropType<Grade>, required: true },

    /**
     * 選択中の教科のリソース名。
     */
    selectedCurriculum: { type: String as PropType<string | null> },

    /**
     * 文書評価教科。
     */
    eeCurriculums: { type: Object as PropType<Loadable<EECurriculum[]>>, required: true },

    /**
     * 教科学習のパスを取得する関数。
     */
    getPath: {
      type: Function as PropType<
        (
          tab: CurriculumTabName,
          periodMode: CurriculumPeriodMode | null,
          month: number | null,
          resourceName: string | null
        ) => string
      >,
      required: true
    }
  },
  computed: {
    eeCurriculumsData(): EECurriculum[] {
      return this.eeCurriculums.data ?? [];
    }
  }
});
