import {
  NECContent,
  NECContentMonth,
  NECViewPoint,
  NECurriculum,
  NECContentTree,
  NECContentMonthTree,
  NECViewPointTree,
  NECurriculumTree
} from "@/ts/objects/noneditable/curriculum/NECurriculum";
import { Color } from "@/ts/objects/noneditable/value/Color";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { NECEvaluation } from "@/ts/objects/noneditable/curriculum/NECEvaluation";
import {
  EECActivity,
  EECActivityTree,
  EECMonth,
  EECMonthTree,
  EECurriculum,
  EECurriculumTree
} from "@/ts/objects/noneditable/curriculum/EECurriculum";
import { Grade } from "@/ts/objects/noneditable/value/Grade";
import {
  EECJournal,
  EECJournalFile,
  EECJournalFileTree,
  EECJournalStudent,
  EECJournalStudentTree,
  EECJournalTree
} from "@/ts/objects/noneditable/curriculum/EECJournal";
import { MonthValue, monthValues } from "@/ts/utils";

export type CurriculumTestData = {
  neCurriculum: Record<string, NECurriculumTree>;
  necEvaluation: NECEvaluation[];
  eeCurriculum: Record<string, EECurriculumTree>;
  eecJournalStudent: Record<string, EECJournalStudentTree>;
};

export function getCurriculumTestData(_repo: CurriculumRepository): CurriculumTestData {
  return {
    /**
     * 数値評価シラバス。
     */
    neCurriculum: {
      "2000-e1-国語": genNECurriculumTree({
        necId: "nec000",
        schoolYear: 2000,
        grade: new Grade("e1"),
        name: "国語",
        viewPoints: {
          viewpoint000: {
            name: "知識・技能",
            color: new Color(255, 87, 147),
            contents: {
              content000: {
                name: "言葉の特徴や使い方に関すること",
                enabledMonths: [4, 5, 6, 8, 9, 3],
                monthBaseText:
                  "司会，記録，提案者，などの役割について理解する．aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
              },
              content001: {
                name: "情報の扱い方に関すること",
                enabledMonths: [6, 8, 9],
                monthBaseText: "段落の役割について理解している．"
              },
              content002: {
                name: "我が国の言語文化に関する事項、いろはにほへとちりぬるをわかよたれそつねならむ",
                enabledMonths: [4, 7, 8, 9],
                monthBaseText: "８つの考え方とその考え方に対応した思考ツールの使い方を理解する．"
              }
            }
          },
          viewpoint001: {
            name: "思考・判断・表現",
            color: new Color(67, 97, 255),
            contents: {
              content000: {
                name: "話すこと・聞くこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText: "目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．"
              },
              content001: {
                name: "書くこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText: "言葉の役割や性質を捉えようとする．"
              },
              content002: {
                name: "読むこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText:
                  "進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。"
              }
            }
          },
          viewpoint002: {
            name: "学びに向かう力・人間性等",
            color: new Color(71, 173, 62),
            contents: {
              // content000: {
              //   name: "話すこと・聞くこと",
              //   enabledMonths: [4, 5, 6, 8, 9],
              //   monthBaseText: "目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．"
              // },
              // content001: {
              //   name: "書くこと",
              //   enabledMonths: [4, 5, 6, 8, 9],
              //   monthBaseText: "言葉の役割や性質を捉えようとする．"
              // },
              // content002: {
              //   name: "読むこと",
              //   enabledMonths: [4, 5, 6, 8, 9],
              //   monthBaseText:
              //     "進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。"
              // }
            }
          }
        }
      }),
      "2000-e1-算数": genNECurriculumTree({
        necId: "nec001",
        schoolYear: 2000,
        grade: new Grade("e1"),
        name: "算数",
        viewPoints: {
          viewpoint000: {
            name: "知識・技能",
            color: new Color(255, 87, 147),
            contents: {
              content000: {
                name: "言葉の特徴や使い方に関すること",
                enabledMonths: [4, 5, 6, 8, 9, 3],
                monthBaseText:
                  "司会，記録，提案者，などの役割について理解する．aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
              },
              content001: {
                name: "情報の扱い方に関すること",
                enabledMonths: [6, 8, 9],
                monthBaseText: "段落の役割について理解している．"
              },
              content002: {
                name: "我が国の言語文化に関する事項、いろはにほへとちりぬるをわかよたれそつねならむ",
                enabledMonths: [4, 7, 8, 9],
                monthBaseText: "８つの考え方とその考え方に対応した思考ツールの使い方を理解する．"
              }
            }
          },
          viewpoint001: {
            name: "思考・判断・表現",
            color: new Color(67, 97, 255),
            contents: {
              content000: {
                name: "話すこと・聞くこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText: "目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．"
              },
              content001: {
                name: "書くこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText: "言葉の役割や性質を捉えようとする．"
              },
              content002: {
                name: "読むこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText:
                  "進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。"
              }
            }
          },
          viewpoint002: {
            name: "学びに向かう力・人間性等",
            color: new Color(71, 173, 62),
            contents: {
              content000: {
                name: "話すこと・聞くこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText: "目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．"
              },
              content001: {
                name: "書くこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText: "言葉の役割や性質を捉えようとする．"
              },
              content002: {
                name: "読むこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText:
                  "進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。"
              }
            }
          }
        }
      }),
      "2000-e1-理科": genNECurriculumTree({
        necId: "nec002",
        schoolYear: 2000,
        grade: new Grade("e1"),
        name: "理科",
        viewPoints: {
          viewpoint000: {
            name: "知識・技能",
            color: new Color(255, 87, 147),
            contents: {
              content000: {
                name: "言葉の特徴や使い方に関すること",
                enabledMonths: [4, 5, 6, 8, 9, 3],
                monthBaseText:
                  "司会，記録，提案者，などの役割について理解する．aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
              },
              content001: {
                name: "情報の扱い方に関すること",
                enabledMonths: [6, 8, 9],
                monthBaseText: "段落の役割について理解している．"
              },
              content002: {
                name: "我が国の言語文化に関する事項、いろはにほへとちりぬるをわかよたれそつねならむ",
                enabledMonths: [4, 7, 8, 9],
                monthBaseText: "８つの考え方とその考え方に対応した思考ツールの使い方を理解する．"
              }
            }
          },
          viewpoint001: {
            name: "思考・判断・表現",
            color: new Color(67, 97, 255),
            contents: {
              content000: {
                name: "話すこと・聞くこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText: "目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．"
              },
              content001: {
                name: "書くこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText: "言葉の役割や性質を捉えようとする．"
              },
              content002: {
                name: "読むこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText:
                  "進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。"
              }
            }
          },
          viewpoint002: {
            name: "学びに向かう力・人間性等",
            color: new Color(71, 173, 62),
            contents: {
              content000: {
                name: "話すこと・聞くこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText: "目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．"
              },
              content001: {
                name: "書くこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText: "言葉の役割や性質を捉えようとする．"
              },
              content002: {
                name: "読むこと",
                enabledMonths: [4, 5, 6, 8, 9],
                monthBaseText:
                  "進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。"
              }
            }
          }
        }
      })
    },
    necEvaluation: [
      // new NECEvaluation("nec000", "viewpoint000", "content000", 4, "student000", "B", "", true),
      // new NECEvaluation("nec000", "viewpoint000", "content001", 4, "student000", "B", true),
      new NECEvaluation("nec000", "viewpoint000", "content002", 4, "student000", "B", true),
      new NECEvaluation("nec000", "viewpoint001", "content000", 4, "student000", "A", true),
      new NECEvaluation("nec000", "viewpoint001", "content001", 4, "student000", "A", true),
      new NECEvaluation("nec000", "viewpoint001", "content002", 4, "student000", "B", true),
      // new NECEvaluation("nec000", "viewpoint002", "content000", 4, "student000", "B", true),
      // new NECEvaluation("nec000", "viewpoint002", "content001", 4, "student000", "C", true),
      // new NECEvaluation("nec000", "viewpoint002", "content002", 4, "student000", "S", true),

      new NECEvaluation("nec000", "viewpoint000", "content000", 5, "student000", "B", true),
      new NECEvaluation("nec000", "viewpoint000", "content001", 5, "student000", "B", true),
      new NECEvaluation("nec000", "viewpoint000", "content002", 5, "student000", "C", true),
      new NECEvaluation("nec000", "viewpoint001", "content000", 5, "student000", "A", true),
      new NECEvaluation("nec000", "viewpoint001", "content001", 5, "student000", "A", true),
      new NECEvaluation("nec000", "viewpoint001", "content002", 5, "student000", "B", true),
      // new NECEvaluation("nec000", "viewpoint002", "content000", 5, "student000", "A", true),
      // new NECEvaluation("nec000", "viewpoint002", "content001", 5, "student000", "B", true),
      // new NECEvaluation("nec000", "viewpoint002", "content002", 5, "student000", "A", "", true),

      new NECEvaluation("nec000", "viewpoint000", "content000", 6, "student000", "A", true),
      new NECEvaluation("nec000", "viewpoint000", "content001", 6, "student000", "B", true),
      new NECEvaluation("nec000", "viewpoint000", "content002", 6, "student000", "A", true),
      new NECEvaluation("nec000", "viewpoint001", "content000", 6, "student000", "A", true),
      new NECEvaluation("nec000", "viewpoint001", "content001", 6, "student000", "S", true),
      new NECEvaluation("nec000", "viewpoint001", "content002", 6, "student000", "A", true),
      // new NECEvaluation("nec000", "viewpoint002", "content000", 6, "student000", "C", true),
      // new NECEvaluation("nec000", "viewpoint002", "content001", 6, "student000", "B", true),
      // new NECEvaluation("nec000", "viewpoint002", "content002", 6, "student000", "A", true),

      new NECEvaluation("nec000", "viewpoint000", "content000", 3, "student000", "C", true),

      new NECEvaluation("nec001", "viewpoint002", "content002", 4, "student000", "A", true),

      new NECEvaluation("nec001", "viewpoint000", "content000", 6, "student000", "A", true),
      new NECEvaluation("nec001", "viewpoint000", "content001", 6, "student000", "A", true),
      new NECEvaluation("nec001", "viewpoint000", "content002", 6, "student000", "A", true),
      new NECEvaluation("nec001", "viewpoint001", "content000", 6, "student000", "A", true),
      new NECEvaluation("nec001", "viewpoint001", "content001", 6, "student000", "A", true),
      new NECEvaluation("nec001", "viewpoint001", "content002", 6, "student000", "A", true),
      new NECEvaluation("nec001", "viewpoint002", "content000", 6, "student000", "A", true),
      new NECEvaluation("nec001", "viewpoint002", "content001", 6, "student000", "A", true),
      new NECEvaluation("nec001", "viewpoint002", "content002", 6, "student000", "A", true)
    ],
    eeCurriculum: {
      "2000-e1-道徳": genEECurriculumTree({
        eecId: "eec000",
        schoolYear: 2000,
        grade: new Grade("e1"),
        name: "道徳",
        months: {
          4: {
            activity000: { enabled: true, text: "みんなで話し合う4-000" },
            activity001: { enabled: true, text: "みんなで話し合う4-001" },
            activity002: { enabled: false, text: "みんなで話し合う4-002" },
            activity003: { enabled: true, text: "みんなで話し合う4-003" }
          },
          5: {
            activity000: { enabled: true, text: "みんなで話し合う5-000" },
            activity001: { enabled: true, text: "みんなで話し合う5-001" },
            activity002: { enabled: true, text: "みんなで話し合う5-002" },
            activity003: { enabled: true, text: "みんなで話し合う5-003" }
          },
          6: {
            activity000: { enabled: true, text: "みんなで話し合う6-000" },
            activity001: { enabled: true, text: "みんなで話し合う6-001" },
            activity002: { enabled: true, text: "みんなで話し合う6-002" },
            activity003: { enabled: true, text: "みんなで話し合う6-003" }
          },
          7: {}
        }
      }),
      "2000-e1-委員会活動": genEECurriculumTree({
        eecId: "eec001",
        schoolYear: 2000,
        grade: new Grade("e1"),
        name: "委員会活動",
        months: {
          4: {
            activity000: { enabled: true, text: "みんなで話し合う4-000" },
            activity001: { enabled: true, text: "みんなで話し合う4-001" },
            activity002: { enabled: false, text: "みんなで話し合う4-002" },
            activity003: { enabled: true, text: "みんなで話し合う4-003" }
          },
          5: {
            activity000: { enabled: true, text: "みんなで話し合う5-000" },
            activity001: { enabled: true, text: "みんなで話し合う5-001" },
            activity002: { enabled: true, text: "みんなで話し合う5-002" },
            activity003: { enabled: true, text: "みんなで話し合う5-003" }
          },
          6: {
            activity000: { enabled: true, text: "みんなで話し合う6-000" },
            activity001: { enabled: true, text: "みんなで話し合う6-001" },
            activity002: { enabled: true, text: "みんなで話し合う6-002" },
            activity003: { enabled: true, text: "みんなで話し合う6-003" }
          },
          7: {}
        }
      })
    },
    eecJournalStudent: {
      "eec000-student000": genEECJournalStudentTree("eec000", "student000", {
        journal000: {
          month: 4,
          activity: "みんなで話し合う！4-0",
          studentComment: "話し合った。4-0",
          teacherComment: "話し合いましたね。4-0",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        },
        journal001: {
          month: 4,
          activity: "みんなで話し合う！4-1",
          studentComment: "話し合った。4-1",
          teacherComment: "話し合いましたね。4-1",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        },
        journal002: {
          month: 5,
          activity: "みんなで話し合う！5-0",
          studentComment: "話し合った。5-0",
          teacherComment: "話し合いましたね。5-0",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        },
        journal003: {
          month: 5,
          activity: "みんなで話し合う！5-1",
          studentComment: "話し合った。5-1",
          teacherComment: "話し合いましたね。5-1",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        },
        journal004: {
          month: 6,
          activity: "みんなで話し合う！6-0",
          studentComment: "話し合った。6-0",
          teacherComment: "話し合いましたね。6-0",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        }
      })
    }
  };
}

export function genNECurriculumTree({
  necId = "nec000",
  schoolYear = 2000,
  grade = new Grade("e1"),
  name = "nec-name",
  viewPoints = {}
}: {
  necId?: string;
  schoolYear?: number;
  grade?: Grade;
  name?: string;
  viewPoints?: {
    [viewPointId: string]: {
      name?: string;
      color?: Color;
      contents?: {
        [contentId: string]: {
          name?: string;
          enabledMonths?: MonthValue[];
          monthBaseText?: string;
        };
      };
    };
  };
}): NECurriculumTree {
  return new NECurriculumTree(
    new NECurriculum(necId, schoolYear, grade, name),
    genNECViewPointTrees(necId, viewPoints)
  );
}

export function genNECViewPointTrees(
  necId: string,
  viewPoints: {
    [viewPointId: string]: {
      name?: string;
      color?: Color;
      contents?: {
        [contentId: string]: {
          name?: string;
          enabledMonths?: MonthValue[];
          monthBaseText?: string;
        };
      };
    };
  }
): NECViewPointTree[] {
  return Object.entries(viewPoints).map(([viewPointId, { name, color, contents }]) =>
    genNECViewPointTree({
      necId,
      viewPointId: viewPointId,
      name: name,
      color: color,
      contents: contents
    })
  );
}

export function genNECViewPointTree({
  necId = "nec000",
  viewPointId = "viewpoint000",
  name = "nec-view-point-name",
  color = new Color(0, 0, 0),
  contents = {}
}: {
  necId?: string;
  viewPointId?: string;
  name?: string;
  color?: Color;
  contents?: {
    [contentId: string]: {
      name?: string;
      enabledMonths?: MonthValue[];
      monthBaseText?: string;
    };
  };
}): NECViewPointTree {
  return new NECViewPointTree(
    new NECViewPoint(necId, viewPointId, name, color),
    genNECContentTrees(necId, viewPointId, contents)
  );
}

export function genNECContentTrees(
  necId: string,
  viewPointId: string,
  contents: {
    [contentId: string]: {
      name?: string;
      enabledMonths?: MonthValue[];
      monthBaseText?: string;
    };
  }
): NECContentTree[] {
  return Object.entries(contents).map(([contentId, { name, enabledMonths, monthBaseText }]) =>
    genNECContentTree({
      necId,
      viewPointId,
      contentId: contentId,
      name: name,
      enabledMonths: enabledMonths,
      monthBaseText: monthBaseText
    })
  );
}

export function genNECContentTree({
  necId = "nec000",
  viewPointId = "viewpoint000",
  contentId = "content000",
  name = "nec-content-name",
  enabledMonths = [],
  monthBaseText = ""
}: {
  necId?: string;
  viewPointId?: string;
  contentId?: string;
  name?: string;
  enabledMonths?: MonthValue[];
  monthBaseText?: string;
}): NECContentTree {
  return new NECContentTree(
    new NECContent(necId, viewPointId, contentId, name),
    genNECContentMonthTrees(necId, viewPointId, contentId, enabledMonths, monthBaseText)
  );
}

export function genNECContentMonthTrees(
  necId: string,
  viewPointId: string,
  contentId: string,
  enabledMonths: MonthValue[],
  baseText: string
): NECContentMonthTree[] {
  return monthValues.map(month =>
    genNECContentMonthTree({
      necId,
      viewPointId,
      contentId,
      month,
      enabledMonths,
      baseText
    })
  );
}

export function genNECContentMonthTree({
  necId = "nec000",
  viewPointId = "viewpoint000",
  contentId = "content000",
  month = 4,
  enabledMonths = [],
  baseText = ""
}: {
  necId?: string;
  viewPointId?: string;
  contentId?: string;
  month?: MonthValue;
  enabledMonths?: MonthValue[];
  baseText?: string;
}): NECContentMonthTree {
  return new NECContentMonthTree(
    new NECContentMonth(necId, viewPointId, contentId, month, enabledMonths.includes(month), `${baseText}${month}`)
  );
}

export function genEECurriculumTree({
  eecId = "eec000",
  schoolYear = 2000,
  grade = new Grade("e1"),
  name = "eec-name",
  months = {}
}: {
  eecId?: string;
  schoolYear?: number;
  grade?: Grade;
  name?: string;
  months?: {
    [month in MonthValue]?: {
      [activityId: string]: { enabled: boolean; text: string };
    };
  };
}): EECurriculumTree {
  const _months = genEECMonthTrees(eecId, months);
  return new EECurriculumTree(new EECurriculum(eecId, schoolYear, grade, name), _months);
}

export function genEECMonthTrees(
  eecId: string,
  months: {
    [month in MonthValue]?: {
      [activityId: string]: { enabled: boolean; text: string };
    };
  }
): EECMonthTree[] {
  return Object.entries(months).map(([monthText, activities]) => {
    if (activities === undefined) throw new Error("genEECMonths: activities === undefined");
    const month = parseInt(monthText, 10) as MonthValue;
    return new EECMonthTree(new EECMonth(eecId, month), genEECActivityTrees(eecId, month, activities));
  });
}

export function genEECActivityTrees(
  eecId: string,
  month: MonthValue,
  activities: {
    [activityId: string]: { enabled: boolean; text: string };
  }
): EECActivityTree[] {
  return Object.entries(activities).map(([activityId, { text, enabled }]) => {
    return new EECActivityTree(new EECActivity(eecId, month, activityId, enabled, text));
  });
}

export function genEECJournalStudentTree(
  eecId: string,
  studentUserId: string,
  journals: {
    [journalId: string]: {
      month: number;
      activity: string;
      studentComment: string;
      teacherComment: string;
      teacherInputPublished: boolean;
      createdAt: string;
      studentInputLocked: boolean;
      journalFiles: {
        [journalFileId: string]: {
          createdAt: string;
        };
      };
    };
  }
): EECJournalStudentTree {
  return new EECJournalStudentTree(
    new EECJournalStudent(eecId, studentUserId),
    genEECJournalTrees(eecId, studentUserId, journals)
  );
}

export function genEECJournalTrees(
  eecId: string,
  studentUserId: string,
  journals: {
    [journalId: string]: {
      month: number;
      activity: string;
      studentComment: string;
      teacherComment: string;
      teacherInputPublished: boolean;
      createdAt: string;
      studentInputLocked: boolean;
      journalFiles: {
        [journalFileId: string]: {
          createdAt: string;
        };
      };
    };
  }
): EECJournalTree[] {
  return Object.entries(journals).map(
    ([
      journalId,
      {
        month,
        activity,
        studentComment,
        teacherComment,
        teacherInputPublished,
        createdAt,
        studentInputLocked,
        journalFiles
      }
    ]) => {
      return new EECJournalTree(
        new EECJournal(
          eecId,
          studentUserId,
          journalId,
          month,
          activity,
          studentComment,
          teacherComment,
          teacherInputPublished,
          createdAt,
          studentInputLocked
        ),
        genEECJournalFileTrees(eecId, studentUserId, journalId, journalFiles)
      );
    }
  );
}

export function genEECJournalFileTrees(
  eecId: string,
  studentUserId: string,
  journalId: string,
  journalFiles: {
    [journalFileId: string]: {
      createdAt: string;
    };
  }
): EECJournalFileTree[] {
  return Object.entries(journalFiles).map(([journalFileId, { createdAt }]) => {
    return new EECJournalFileTree(new EECJournalFile(eecId, studentUserId, journalId, journalFileId, createdAt));
  });
}
