import { Color } from "@/ts/objects/noneditable/value/Color";
import { Grade } from "@/ts/objects/noneditable/value/Grade";
import { Curriculum } from "@/ts/objects/noneditable/curriculum/Curriculum";
import { MonthValue } from "@/ts/utils";

/**
 * 数値評価教科。
 *
 * NEC = Numerically-Evaluated Curriculumの略とする。
 */
export class NECurriculum implements Curriculum {
  constructor(
    public readonly necId: string,
    public readonly schoolYear: number,
    public readonly grade: Grade,
    public readonly name: string
  ) {}

  get resourceName(): string {
    return `/neCurriculums/${this.necId}`;
  }
}

export class NECurriculumTree {
  constructor(public readonly self: NECurriculum, public readonly viewPoints: NECViewPointTree[]) {}
}

/**
 * 数値評価教科の観点。
 */
export class NECViewPoint {
  constructor(
    public readonly necId: string,
    public readonly viewPointId: string,
    public readonly name: string,
    public readonly color: Color
  ) {}

  get necResourceName(): string {
    return `/neCurriculums/${this.necId}`;
  }

  get resourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}`;
  }
}

export class NECViewPointTree {
  constructor(public readonly self: NECViewPoint, public readonly contents: NECContentTree[]) {}
}

/**
 * 数値評価教科の内容構成。
 */
export class NECContent {
  /**
   * コンストラクタ。
   *
   * @param necId
   * @param viewPointId
   * @param contentId
   * @param name
   */
  constructor(
    public readonly necId: string,
    public readonly viewPointId: string,
    public readonly contentId: string,
    public readonly name: string
  ) {}

  get resourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}`;
  }
}

export class NECContentTree {
  /**
   * コンストラクタ。
   *
   * @param self
   * @param months 必ず、4月～3月の順番に、12個あること。
   */
  constructor(public readonly self: NECContent, public readonly months: NECContentMonthTree[]) {}

  getMonth(month: number | null): NECContentMonth | null {
    if (month === null) return null;
    return this.months.map(m => m.self).find(m => m.month === month) ?? null;
  }
}

/**
 * 数値評価教科の内容構成の1か月分の内容。
 */
export class NECContentMonth {
  constructor(
    public readonly necId: string,
    public readonly viewPointId: string,
    public readonly contentId: string,
    public readonly month: MonthValue,
    public readonly enabled: boolean,
    public readonly text: string
  ) {}

  get resourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}/months/${this.month}`;
  }
}

export class NECContentMonthTree {
  constructor(public readonly self: NECContentMonth) {}
}
