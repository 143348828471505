import log from "loglevel";

export abstract class Loadable<T> {
  abstract readonly data: T | null;
  abstract readonly state: LoadableState;

  get isIdle(): boolean {
    return this.state === LoadableState.Idle;
  }

  get isLoading(): boolean {
    return this.state === LoadableState.Loading;
  }

  get isError(): boolean {
    return this.state === LoadableState.Error;
  }

  get isDataLoaded(): boolean {
    return this.state === LoadableState.DataLoaded;
  }

  get isIdleOrLoading(): boolean {
    return this.isIdle || this.isLoading;
  }

  static fromValue<T>(value: T): Loadable<T> {
    return new LoadableDataLoaded(value);
  }

  static fromLoadable<A, T>(loadable: Loadable<A>, projectionFn: (a: A) => T): Loadable<T> {
    if (loadable.isDataLoaded) return new LoadableDataLoaded(projectionFn(loadable.data!));
    if (loadable.isError) return new LoadableError();
    if (loadable.isIdle) return new LoadableIdle();
    return new LoadableLoading();
  }

  static fromLoadable2<A, B, T>(
    loadableA: Loadable<A>,
    loadableB: Loadable<B>,
    projectionFn: (a: A, b: B) => T
  ): Loadable<T> {
    if (loadableA.isDataLoaded && loadableB.isDataLoaded)
      return new LoadableDataLoaded(projectionFn(loadableA.data!, loadableB.data!));
    if (loadableA.isError || loadableB.isError) return new LoadableError();
    if (loadableA.isIdle || loadableB.isIdle) return new LoadableIdle();
    return new LoadableLoading();
  }
}

export class LoadableIdle<T> extends Loadable<T> {
  readonly data: T | null = null;
  readonly state: LoadableState = LoadableState.Idle;
}

export class LoadableLoading<T> extends Loadable<T> {
  readonly data: T | null = null;
  readonly state: LoadableState = LoadableState.Loading;
}

export class LoadableError<T> extends Loadable<T> {
  readonly data: T | null = null;
  readonly state: LoadableState = LoadableState.Error;
}

export class LoadableDataLoaded<T> extends Loadable<T> {
  readonly data: T | null;
  readonly state: LoadableState = LoadableState.DataLoaded;

  constructor(data: T) {
    super();
    this.data = data;
  }
}

export enum LoadableState {
  /**
   * ロード開始前。
   */
  Idle,

  /**
   * ロード中。
   */
  Loading,

  /**
   * エラー。
   */
  Error,

  /**
   * ロード完了済。
   */
  DataLoaded
}

export async function getDataWithTimeout<T>(
  loadableGetter: () => Loadable<T>,
  timeoutSec: number = 20
): Promise<T | null> {
  const ticks = timeoutSec * 2;
  const getData = () => loadableGetter().data;

  const firstTry = getData();
  log.debug(`getDataWithTimeout: start: data=${firstTry}`);
  if (firstTry !== null) return firstTry;

  for (let i = 0; i < ticks; i++) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), 500));
    const data = getData();
    log.debug(`getDataWithTimeout: ${i}/${ticks}: data=${data}`);
    if (data !== null) return data;
  }

  log.debug(`getDataWithTimeout: no data`);
  return null;
}
