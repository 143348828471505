import { render, staticRenderFns } from "./SolanRubrics.vue?vue&type=template&id=af2d0c82&scoped=true&"
import script from "./SolanRubrics.vue?vue&type=script&lang=ts&"
export * from "./SolanRubrics.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af2d0c82",
  null
  
)

export default component.exports