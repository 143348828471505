import {
  Configuration,
  DefaultApi as SolanDefaultApi,
  ProjectWrite,
  Project as ProjectResp,
  RubricWrite,
  Rubric as RubricResp,
  JournalWrite,
  Journal as JournalResp,
  LookbackWrite,
  Lookback as LookbackResp,
  JournalFile as JournalFileResp,
  FullProject
} from "@/ts/api/solan-service";
import { AxiosInstance } from "axios";
import { DisplayableErr, Err } from "@/ts/objects/Err";
import { doReq } from "@/ts/Services";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { Class, ClassStudent } from "@/ts/objects/noneditable/Class";
import { messages } from "@/ts/const/Messages";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { SolanProject } from "@/ts/objects/noneditable/solan/SolanProject";
import { EditableSolanJournal } from "@/ts/objects/editable/solan/EditableSolanJournal";
import { SolanJournalFile } from "@/ts/objects/noneditable/solan/SolanJournalFile";
import { EditableSolanLookback } from "@/ts/objects/editable/solan/EditableSolanLookback";
import { EditableSolanStudent, EditableSolanStudentProject } from "@/ts/objects/editable/solan/EditableSolanStudent";
import { EditableSolanRubric } from "@/ts/objects/editable/solan/EditableSolanRubric";
import { SolanRubric } from "@/ts/objects/noneditable/solan/SolanRubric";
import { EditableSolanProject } from "@/ts/objects/editable/solan/EditableSolanProject";

export abstract class SolanRepository {
  abstract getEditableSolanProject(projectId: string, savable: boolean): Promise<EditableSolanProject | Err>;

  abstract listSolanProjects(
    studentUserId: string | undefined,
    schoolYear: number | undefined
  ): Promise<SolanProject[] | Err>;

  abstract postProject(this: this, studentUserId: string, schoolYear: number): Promise<ProjectResp | Err>;

  abstract patchProject(this: this, projectId: string, projectWrite: ProjectWrite): Promise<ProjectResp | Err>;

  abstract deleteProject(this: this, projectId: string): Promise<void | Err>;

  abstract getEditableSolanLookback(
    this: this,
    projectId: string,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): Promise<EditableSolanLookback | Err>;

  abstract patchLookback(this: this, projectId: string, lookbackWrite: LookbackWrite): Promise<LookbackResp | Err>;

  abstract listSolanRubrics(this: this, projectId: string): Promise<SolanRubric[] | Err>;

  abstract listEditableSolanRubrics(
    this: this,
    projectId: string,
    savable: boolean
  ): Promise<EditableSolanRubric[] | Err>;

  abstract postAndGetEditableSolanRubric(
    this: this,
    projectId: string,
    process: number,
    savable: boolean
  ): Promise<EditableSolanRubric | Err>;

  abstract patchRubric(
    this: this,
    projectId: string,
    rubricId: string,
    rubricWrite: RubricWrite
  ): Promise<RubricResp | Err>;

  abstract deleteRubric(this: this, projectId: string, rubricId: string): Promise<void | Err>;

  abstract listEditableSolanJournals(
    this: this,
    projectId: string,
    teacherInputSavable: boolean,
    studentInputSavable: boolean
  ): Promise<{ rubrics: SolanRubric[]; editableJournals: EditableSolanJournal[] } | Err>;

  abstract patchJournal(
    this: this,
    projectId: string,
    rubricId: string,
    journalWrite: JournalWrite
  ): Promise<JournalResp | Err>;

  abstract listJournalFiles(this: this, projectId: string, rubricId: string): Promise<SolanJournalFile[] | Err>;

  abstract postJournalFile(
    this: this,
    projectId: string,
    rubricId: string,
    file: any,
    timeoutMillis: number
  ): Promise<JournalFileResp | Err>;

  abstract deleteJournalFile(
    this: this,
    projectId: string,
    rubricId: string,
    journalFileId: string
  ): Promise<void | Err>;

  abstract listEditableSolanStudents(
    this: this,
    userRepository: UserRepository,
    cls: Class | null,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): Promise<EditableSolanStudent[] | Err>;

  protected fullProjectsToStudents(
    fullProjects: FullProject[],
    classStudents: Map<string, ClassStudent>,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ) {
    const groupedFullProjects = groupBy(fullProjects, p => p.project.studentUserId);
    const students = Object.entries(groupedFullProjects)
      .map(([studentUserId, fullProjects]) => {
        const student = classStudents.get(studentUserId);
        if (student === undefined) return null;
        return new EditableSolanStudent(
          studentUserId,
          student.studentNumber,
          student.name,
          fullProjects
            .map(p => {
              if (p.project.lookback.teacherComment === undefined) return null;
              if (p.project.lookback.teacherRating === undefined) return null;
              return new EditableSolanStudentProject(
                new SolanProject(
                  p.project.projectId,
                  p.project.studentUserId,
                  p.project.schoolYear,
                  p.project.name.value,
                  p.project.description.value,
                  p.project.started,
                  p.project.completed,
                  p.project.studentInputLocked
                ),
                p.rubrics
                  .map(r => {
                    if (r.journal.teacherComment === undefined) return null;
                    if (r.journal.teacherRating === undefined) return null;
                    return new EditableSolanJournal(
                      this,
                      studentInputSavable,
                      teacherInputSavable,
                      p.project.projectId,
                      r.rubricId,
                      r.process,
                      r.learningActivity.value,
                      r.viewPointS.value,
                      r.viewPointA.value,
                      r.viewPointB.value,
                      r.viewPointC.value,
                      r.studentUserId,
                      r.journal.files.map(
                        f =>
                          new SolanJournalFile(
                            f.projectId,
                            f.journalFileId,
                            f.rubricId,
                            f.type,
                            f.subtype,
                            f.mediaType,
                            f.filename,
                            f.ext,
                            f.gcsObjectPath,
                            f.thumbnailGcsObjectPath ?? null,
                            f.hasThumbnail,
                            f.width ?? null,
                            f.height ?? null,
                            f.createdAt,
                            f.updatedAt
                          )
                      ),
                      r.journal.studentComment.value,
                      r.journal.studentComment.hash,
                      r.journal.studentRating,
                      r.journal.teacherComment.value,
                      r.journal.teacherComment.hash,
                      r.journal.teacherRating,
                      r.journal.teacherInputPublished,
                      r.studentInputLocked
                    );
                  })
                  .filter((v): v is EditableSolanJournal => v !== null),
                new EditableSolanLookback(
                  this,
                  teacherInputSavable,
                  studentInputSavable,
                  guardianInputSavable,
                  p.projectId,
                  p.project.studentUserId,
                  p.project.lookback.studentComment.value,
                  p.project.lookback.studentComment.hash,
                  p.project.lookback.studentRating,
                  p.project.lookback.teacherComment.value,
                  p.project.lookback.teacherComment.hash,
                  p.project.lookback.teacherRating,
                  p.project.lookback.teacherInputPublished,
                  p.project.lookback.guardianComment.value,
                  p.project.lookback.guardianComment.hash,
                  p.project.studentInputLocked,
                  p.project.guardianInputLocked
                )
              );
            })
            .filter((v): v is EditableSolanStudentProject => v !== null)
        );
      })
      .filter((v): v is EditableSolanStudent => v !== null);

    return sortBy(students, ["studentNumber"]);
  }
}

export class SolanRepositoryImpl extends SolanRepository {
  private readonly solanService: SolanDefaultApi;

  constructor(serviceBasePath: string, axiosConf: Configuration | undefined, axiosInstance: AxiosInstance) {
    super();
    this.solanService = new SolanDefaultApi(axiosConf, serviceBasePath, axiosInstance);
  }

  async getEditableSolanProject(projectId: string, savable: boolean): Promise<EditableSolanProject | Err> {
    const r = await doReq(() => this.solanService.getProject(projectId));
    if (r instanceof Err) return r;

    return new EditableSolanProject(
      this,
      savable,
      r.projectId,
      r.studentUserId,
      r.schoolYear,
      r.name.value,
      r.name.hash,
      r.description.value,
      r.description.hash,
      r.started,
      r.completed
    );
  }

  async listSolanProjects(
    studentUserId: string | undefined,
    schoolYear: number | undefined
  ): Promise<SolanProject[] | Err> {
    const resp = await doReq(() => this.solanService.listProject(studentUserId, schoolYear, undefined));
    if (resp instanceof Err) return resp;

    return resp.map(
      p =>
        new SolanProject(
          p.projectId,
          p.studentUserId,
          p.schoolYear,
          p.name.value,
          p.description.value,
          p.started,
          p.completed,
          p.studentInputLocked
        )
    );
  }

  async postProject(this: this, studentUserId: string, schoolYear: number): Promise<ProjectResp | Err> {
    return await doReq(() =>
      this.solanService.postProject({
        studentUserId: studentUserId,
        schoolYear: schoolYear
      })
    );
  }

  async patchProject(this: this, projectId: string, projectWrite: ProjectWrite): Promise<ProjectResp | Err> {
    return await doReq(() => this.solanService.patchProject(projectId, projectWrite));
  }

  async deleteProject(this: this, projectId: string): Promise<void | Err> {
    return await doReq(() => this.solanService.deleteProject(projectId));
  }

  async getEditableSolanLookback(
    projectId: string,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): Promise<EditableSolanLookback | Err> {
    const r = await doReq(() => this.solanService.getProject(projectId));
    if (r instanceof Err) return r;

    return new EditableSolanLookback(
      this,
      teacherInputSavable,
      studentInputSavable,
      guardianInputSavable,
      r.projectId,
      r.studentUserId,
      r.lookback.studentComment.value,
      r.lookback.studentComment.hash,
      r.lookback.studentRating,
      r.lookback.teacherComment?.value ?? "",
      r.lookback.teacherComment?.hash ?? "",
      r.lookback.teacherRating ?? "",
      r.lookback.teacherInputPublished,
      r.lookback.guardianComment.value,
      r.lookback.guardianComment.hash,
      r.studentInputLocked,
      r.guardianInputLocked
    );
  }

  async patchLookback(this: this, projectId: string, lookbackWrite: LookbackWrite): Promise<LookbackResp | Err> {
    const resp = await doReq(() => this.solanService.patchProject(projectId, { lookback: lookbackWrite }));
    if (resp instanceof Err) return resp;
    return resp.lookback;
  }

  async listSolanRubrics(this: this, projectId: string): Promise<SolanRubric[] | Err> {
    const resp = await doReq(() => this.solanService.listRubric(projectId));
    if (resp instanceof Err) return resp;

    return resp.map(
      r =>
        new SolanRubric(
          r.rubricId,
          r.projectId,
          r.process,
          r.learningActivity.value,
          r.viewPointS.value,
          r.viewPointA.value,
          r.viewPointB.value,
          r.viewPointC.value,
          r.createdAt
        )
    );
  }

  async listEditableSolanRubrics(
    this: this,
    projectId: string,
    savable: boolean
  ): Promise<EditableSolanRubric[] | Err> {
    const r = await doReq(() => this.solanService.listRubric(projectId));
    if (r instanceof Err) return r;

    return r.map(
      r =>
        new EditableSolanRubric(
          this,
          savable,
          r.projectId,
          r.rubricId,
          r.studentUserId,
          r.process,
          r.learningActivity.value,
          r.learningActivity.hash,
          r.viewPointS.value,
          r.viewPointS.hash,
          r.viewPointA.value,
          r.viewPointA.hash,
          r.viewPointB.value,
          r.viewPointB.hash,
          r.viewPointC.value,
          r.viewPointC.hash,
          r.createdAt,
          r.studentInputLocked
        )
    );
  }

  async postAndGetEditableSolanRubric(
    this: this,
    projectId: string,
    process: number,
    savable: boolean
  ): Promise<EditableSolanRubric | Err> {
    const r = await doReq(() =>
      this.solanService.postRubric(projectId, {
        process: process
      })
    );
    if (r instanceof Err) return r;

    return new EditableSolanRubric(
      this,
      savable,
      r.projectId,
      r.rubricId,
      r.studentUserId,
      r.process,
      r.learningActivity.value,
      r.learningActivity.hash,
      r.viewPointS.value,
      r.viewPointS.hash,
      r.viewPointA.value,
      r.viewPointA.hash,
      r.viewPointB.value,
      r.viewPointB.hash,
      r.viewPointC.value,
      r.viewPointC.hash,
      r.createdAt,
      r.studentInputLocked
    );
  }

  async patchRubric(
    this: this,
    projectId: string,
    rubricId: string,
    rubricWrite: RubricWrite
  ): Promise<RubricResp | Err> {
    return await doReq(() => this.solanService.patchRubric(projectId, rubricId, rubricWrite));
  }

  async deleteRubric(this: this, projectId: string, rubricId: string): Promise<void | Err> {
    return await doReq(() => this.solanService.deleteRubric(projectId, rubricId));
  }

  async listEditableSolanJournals(
    projectId: string,
    teacherInputSavable: boolean,
    studentInputSavable: boolean
  ): Promise<{ rubrics: SolanRubric[]; editableJournals: EditableSolanJournal[] } | Err> {
    const resp = await doReq(() => this.solanService.listRubric(projectId, true));

    if (resp instanceof Err) return resp;

    const rubrics = resp.map(
      r =>
        new SolanRubric(
          r.rubricId,
          r.projectId,
          r.process,
          r.learningActivity.value,
          r.viewPointS.value,
          r.viewPointA.value,
          r.viewPointB.value,
          r.viewPointC.value,
          r.createdAt
        )
    );

    const editableJournals = resp.map(rubric => {
      const process = rubric?.process ?? -1;
      const learningActivity = rubric?.learningActivity.value ?? "";
      const viewPointS = rubric?.viewPointS.value ?? "";
      const viewPointA = rubric?.viewPointA.value ?? "";
      const viewPointB = rubric?.viewPointB.value ?? "";
      const viewPointC = rubric?.viewPointC.value ?? "";

      const j = rubric.journal;
      return new EditableSolanJournal(
        this,
        studentInputSavable,
        teacherInputSavable,
        rubric.projectId,
        rubric.rubricId,
        process,
        learningActivity,
        viewPointS,
        viewPointA,
        viewPointB,
        viewPointC,
        rubric.studentUserId,
        j.files.map(
          f =>
            new SolanJournalFile(
              f.projectId,
              f.journalFileId,
              f.rubricId,
              f.type,
              f.subtype,
              f.mediaType,
              f.filename,
              f.ext,
              f.gcsObjectPath,
              f.thumbnailGcsObjectPath ?? null,
              f.hasThumbnail,
              f.width ?? null,
              f.height ?? null,
              f.createdAt,
              f.updatedAt
            )
        ),
        j.studentComment.value,
        j.studentComment.hash,
        j.studentRating,
        j.teacherComment?.value ?? "",
        j.teacherComment?.hash ?? "",
        j.teacherRating ?? "",
        j.teacherInputPublished,
        rubric.studentInputLocked
      );
    });

    return { rubrics: rubrics, editableJournals: editableJournals };
  }

  async patchJournal(
    this: this,
    projectId: string,
    rubricId: string,
    journalWrite: JournalWrite
  ): Promise<JournalResp | Err> {
    const resp = await doReq(() => this.solanService.patchRubric(projectId, rubricId, { journal: journalWrite }));
    if (resp instanceof Err) return resp;
    return resp.journal;
  }

  async listJournalFiles(this: this, projectId: string, rubricId: string): Promise<SolanJournalFile[] | Err> {
    const resp = await doReq(() => this.solanService.listJournalFile(projectId, rubricId));
    if (resp instanceof Err) return resp;

    return resp.map(
      r =>
        new SolanJournalFile(
          r.projectId,
          r.journalFileId,
          r.rubricId,
          r.type,
          r.subtype,
          r.mediaType,
          r.filename,
          r.ext,
          r.gcsObjectPath,
          r.thumbnailGcsObjectPath ?? null,
          r.hasThumbnail,
          r.width ?? null,
          r.height ?? null,
          r.createdAt,
          r.updatedAt
        )
    );
  }

  async postJournalFile(
    this: this,
    projectId: string,
    rubricId: string,
    file: any,
    timeoutMillis: number
  ): Promise<JournalFileResp | Err> {
    return await doReq(() => this.solanService.postJournalFile(projectId, rubricId, file, { timeout: timeoutMillis }));
  }

  async deleteJournalFile(this: this, projectId: string, rubricId: string, journalFileId: string): Promise<void | Err> {
    return await doReq(() => this.solanService.deleteJournalFile(projectId, rubricId, journalFileId));
  }

  /**
   * EditableSolanStudentsのリストを取得する。
   * 使用するAPIの権限の関係で、教師のみが使える。
   */
  async listEditableSolanStudents(
    this: this,
    userRepository: UserRepository,
    cls: Class | null,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): Promise<EditableSolanStudent[] | Err> {
    if (cls === null) {
      return new DisplayableErr(`loadEditableSolanStudents: class must not be null`, messages.pleaseSelectClass);
    }
    const classStudents = await cls.classStudents(userRepository);
    const resp = await doReq(() =>
      this.solanService.listFullProjects([cls.schoolYear], Array.from(classStudents.keys()))
    );
    if (resp instanceof Err) return resp;

    return this.fullProjectsToStudents(
      resp,
      classStudents,
      teacherInputSavable,
      studentInputSavable,
      guardianInputSavable
    );
  }
}
