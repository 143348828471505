




















import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import Vue, { PropType } from "vue";
import { NECurriculumTree } from "@/ts/objects/noneditable/curriculum/NECurriculum";
import { NECEvaluation } from "@/ts/objects/noneditable/curriculum/NECEvaluation";
import { messages } from "@/ts/const/Messages";
import { Loadable } from "@/ts/Loadable";
import CurriculumReadMainHeader from "@/views/curriculum/student/components/CurriculumReadMainHeader/CurriculumReadMainHeader.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import CurriculumReadNECMonthlyViewPointRow from "@/views/curriculum/student/CurriculumReadNECMonthly/components/CurriculumReadNECMonthlyViewPointRow/CurriculumReadNECMonthlyViewPointRow.vue";

export default Vue.extend({
  name: "CurriculumReadNECMonthly",
  components: { CurriculumReadNECMonthlyViewPointRow, CurriculumReadMainHeader, MessageView, LoadingBlock },
  props: {
    /**
     * 児童生徒ID。
     */
    studentUserId: { type: String as PropType<string | null>, required: true },

    /**
     * 月。
     */
    month: { type: Number as PropType<number | null>, required: true },

    /**
     * 数値評価教科。
     */
    neCurriculumTree: { type: Object as PropType<Loadable<NECurriculumTree | null>>, required: true },

    /**
     * 現在表示中の教科・児童生徒・月 配下の評価。
     */
    evaluations: { type: Object as PropType<Loadable<NECEvaluation[]>>, required: true }
  },
  computed: {
    messageView(): MessageViewParam | null {
      if (this.studentUserId === null) return { message: messages.pleaseSelectStudent };
      if (this.month === null) return { message: messages.pageNotFound };
      if (
        this.neCurriculumTree.isError ||
        (this.neCurriculumTree.isDataLoaded && this.neCurriculumTree.data === null) ||
        this.evaluations.isError
      )
        return { message: messages.failedToLoadData };
      return null;
    }
  }
});
