



































































import Vue, { PropType } from "vue";
import { Class, SchoolType } from "@/ts/objects/noneditable/Class";
import { AppStateStore } from "@/store/AppStateStore";
import { TeacherState } from "@/ts/objects/appuser/UserState";
import { ProjectStore } from "@/store/ProjectStore";
import PopupMenuButton, { MenuButton, MenuItem } from "@/components/PopupMenuButton.vue";
import { AuthService } from "@/ts/AuthService";

export default Vue.extend({
  name: "AppHeaderT",
  components: { PopupMenuButton },
  props: {
    authService: { type: Object as PropType<AuthService>, required: true },
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    projectStore: { type: Object as PropType<ProjectStore>, required: true }
  },
  data(): {
    userMenuItems: MenuItem[];
  } {
    return {
      userMenuItems: [new MenuButton("changeGoogleAccount", "別のアカウントでログイン", undefined)]
    };
  },
  computed: {
    selectedClass(): Class | null {
      return this.appStateStore.teacherState?.selectedClass() ?? null;
    },
    schoolTypeText(): string {
      switch (this.selectedClass?.schoolType) {
        case SchoolType.ElementarySchool:
          return "小学校";
        case SchoolType.JuniorHighSchool:
          return "中学校";
        default:
          return "";
      }
    },

    isCurrentTabHome(): boolean {
      return this.$route.name === "T.Home";
    },
    isCurrentTabActivity(): boolean {
      return this.$route.name === "T.Activity";
    },
    isCurrentTabCurriculum(): boolean {
      return this.$route.name === "T.Curriculum";
    },
    isCurrentTabProject(): boolean {
      return this.$route.name?.startsWith("T.Project.") ?? false;
    },
    isCurrentTabSolan(): boolean {
      return this.$route.name?.startsWith("T.Solan.") ?? false;
    },
    teacherState(): TeacherState | null {
      return this.appStateStore.teacherState;
    },
    teacherName(): string {
      return this.teacherState?.name ?? "";
    },
    teacherIconUrl(): string {
      return this.teacherState?.iconUrl ?? "";
    }
  },
  methods: {
    async selectUserMenu(menuItem: string) {
      switch (menuItem) {
        case "changeGoogleAccount":
          await this.authService.changeGoogleAccount();
          break;
      }
    }
  }
});
