


























































































import Vue, { PropType } from "vue";
import PopupSelectButton, { SelectItem } from "@/components/PopupSelectButton.vue";
import { ProjectStore } from "@/store/ProjectStore";
import { ProjectInfoOnEditStore } from "@/store/ProjectInfoOnEditStore";
import { Project } from "@/ts/objects/noneditable/project/Project";
import PopupMenuButton, { MenuButton } from "@/components/PopupMenuButton.vue";
import { Err } from "@/ts/objects/Err";
import { Quarter } from "@/ts/objects/noneditable/value/Quarter";
import { AppStateStore } from "@/store/AppStateStore";
import log from "loglevel";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";

export default Vue.extend({
  name: "ProjectHeaderT",
  components: { PopupSelectButton, PopupMenuButton },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    projectStore: { type: Object as PropType<ProjectStore>, required: true },
    projectInfoOnEditStore: { type: Object as PropType<ProjectInfoOnEditStore>, required: true },
    projectRepository: { type: Object as PropType<ProjectRepository>, required: true }
  },
  created() {
    log.debug("ProjectHeaderT created!");
  },
  data() {
    return {
      extraMenuItems: [new MenuButton("newProject", "新しいプロジェクト", ["fas", "plus"])]
    };
  },
  computed: {
    projectBasePath(): string {
      return this.projectStore.teacherBasePath;
    },
    isCurrentTabJournals(): boolean {
      return this.$route.name === "T.Project.Journals";
    },
    isCurrentTabStudentView(): boolean {
      return (
        this.$route.name === "T.Project.StudentView" ||
        (this.$route.name?.startsWith("T.Project.StudentView.") ?? false)
      );
    },
    isCurrentTabAbout(): boolean {
      return this.$route.name === "T.Project.About";
    },

    project(): Project | null {
      return this.projectStore.project.data;
    },
    editing(): boolean {
      return this.projectInfoOnEditStore.info !== null;
    },
    projectName: {
      get(): string | null {
        if (this.projectInfoOnEditStore.info !== null) return this.projectInfoOnEditStore.info.name;
        if (this.project !== null) return this.project.name;
        return null;
      },
      set(value: string) {
        log.debug(`Update name! : ${value}`);
        this.projectInfoOnEditStore.updateName(value);
      }
    },
    startingYear: {
      get(): number | null {
        if (this.projectInfoOnEditStore.info !== null) return this.projectInfoOnEditStore.info.startingYear;
        if (this.project !== null) return this.project.startingMonth.year;
        return null;
      },
      set(value: number) {
        log.debug(`Update startingYear! : ${value}`);
        this.projectInfoOnEditStore.updateStartingYear(value);
      }
    },
    startingMonth: {
      get(): number | null {
        if (this.projectInfoOnEditStore.info !== null) return this.projectInfoOnEditStore.info.startingMonth;
        if (this.project !== null) return this.project.startingMonth.month;
        return null;
      },
      set(value: number) {
        log.debug(`Update startingMonth! : ${value}`);
        this.projectInfoOnEditStore.updateStartingMonth(value);
      }
    },
    endingYear: {
      get(): number | null {
        if (this.projectInfoOnEditStore.info !== null) return this.projectInfoOnEditStore.info.endingYear;
        if (this.project !== null) return this.project.endingMonth.year;
        return null;
      },
      set(value: number) {
        log.debug(`Update endingYear! : ${value}`);
        this.projectInfoOnEditStore.updateEndingYear(value);
      }
    },
    endingMonth: {
      get(): number | null {
        if (this.projectInfoOnEditStore.info !== null) return this.projectInfoOnEditStore.info.endingMonth;
        if (this.project !== null) return this.project.endingMonth.month;
        return null;
      },
      set(value: number) {
        log.debug(`Update endingMonth! : ${value}`);
        this.projectInfoOnEditStore.updateEndingMonth(value);
      }
    },
    quarter(): Quarter | null {
      return this.projectStore.quarter;
    },
    projectSelections(): SelectItem[] {
      const projectsOfQuarter = this.projectStore.projectsOfQuarter.data ?? [];
      return projectsOfQuarter.map(p => {
        return {
          key: `${this.appStateStore.teacherBasePath}/project/${p.quarter.schoolYear}/${p.quarter.quarter}/${p.projectId}`,
          label: p.name !== "" ? p.name : "名称未定プロジェクト"
        };
      });
    }
  },
  methods: {
    selectProject(path: string) {
      log.debug(`selectProject: path=${path}`);
      this.$router.push(path).catch(() => {});
    },
    async selectExtraMenu(menuItem: string) {
      switch (menuItem) {
        case "newProject":
          await this.createNewProjectAndGoEdit();
          break;
      }
    },
    async createNewProjectAndGoEdit() {
      log.debug("NEW PROJECT!");
      const classId = this.appStateStore.teacherState?.selectedClass()?.id;
      const quarter = this.projectStore.quarter;
      if (classId === undefined || quarter === null) return;
      const resp = await this.projectRepository.postProject(classId, quarter);
      if (resp instanceof Err) return; // TODO エラーの場合どうする？
      await this.projectStore.reloadProjects({ projectRepository: this.projectRepository, classIds: [classId] });
      const path = `${this.appStateStore.teacherBasePath}/project/${resp.quarter.schoolYear}/${resp.quarter.quarter}/${resp.projectId}/about`;
      await this.$router.push(path).catch(() => {});
    }
  }
});
