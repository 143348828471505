






































































import Vue, { PropType } from "vue";
import RecentActivityItem from "@/views/activity/teacher/components/RecentActivityItem.vue";
import { AppStateStore } from "@/store/AppStateStore";
import { loadRecentActivityLogs, TabType } from "@/ts/objects/plain/fsActivity";
import { ActivityLog, rawToActivityLog } from "@/ts/objects/plain/ActivityLog";
import { Err } from "@/ts/objects/Err";
import unionBy from "lodash/unionBy";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import log from "loglevel";
import { BasicUserInfo } from "@/ts/objects/noneditable/value/BasicUserInfo";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { ActivityRepository } from "@/ts/repositories/ActivityRepository";

type UniqUserIds = { userId: string; studentUserId: string };

export default Vue.extend({
  name: "RecentActivityView",
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    activityRepository: { type: Object as PropType<ActivityRepository>, required: true },

    onHome: { type: Boolean, default: false }
  },
  components: { RecentActivityItem },
  data(): {
    fsDocuments: any[];
    dbDocuments: any[] | null;
    unMyLists: any[];
    selectedStudentUserId: string | null;
    tab: TabType;
    searchTextInput: string;
    isSearch: boolean;
  } {
    return {
      fsDocuments: [],
      dbDocuments: null,
      unMyLists: [],
      selectedStudentUserId: null,
      tab: TabType.all,
      searchTextInput: "",
      isSearch: false
    };
  },
  computed: {
    recentActivityUniqUserIds(): UniqUserIds[] {
      // 最新activityLogs
      const fsDocuments = this.fsJoinDocuments;
      const userIds: UniqUserIds[] = fsDocuments.map((d: any) => {
        const ids: UniqUserIds = { userId: d.userId, studentUserId: d.studentUserId };
        return ids;
      });
      const uniq = uniqWith(userIds, isEqual).sort();

      return uniq;
    },
    searchActivityUniqUserIds(): UniqUserIds[] {
      // 最新activityLogs
      const dbDocuments = this.dbDocuments;
      if (dbDocuments === null) return [];
      const userIds: UniqUserIds[] = dbDocuments.map((d: any) => {
        const ids: UniqUserIds = { userId: d.userId, studentUserId: d.studentUserId };
        return ids;
      });
      const uniq = uniqWith(userIds, isEqual).sort();

      return uniq;
    },
    isSearching(): boolean {
      return this.isSearch;
    },
    searchResultCount(): number {
      if (this.dbDocuments === null) return -1;
      return this.dbDocuments.length;
    },
    searchResultMessage(): string {
      const count = this.searchResultCount;
      if (count > 0) {
        return `検索結果:${this.searchResultCount}件`;
      } else {
        return ``;
      }
    },
    fsJoinDocuments(): any[] {
      let doc: any[] = this.fsDocuments;
      // マイリストでの☆⇒★にした場合の[unMyLists]のremove
      if (this.tab === TabType.mylist) {
        if (this.unMyLists.length > 0) {
          // _.unionBy uuidが同じ場合は先に検出したレコードを格納する。concatでobjectを結合するため、
          // unMyLists(☆のデータ)が後に存在する。（※一部の操作で発生する。）
          doc = unionBy(this.fsDocuments.concat(this.unMyLists), "uuid").sort((a: any, b: any) => {
            if (a.createdAt > b.createdAt) return -1;
            if (a.createdAt < b.createdAt) return 1;
            return 0;
          });
        }
      }

      return doc;
    },

    isSearchTab(): boolean {
      return this.tab === TabType.search_result;
    },
    isRecentActivities(): boolean {
      return this.tab <= TabType.read;
    }
  },
  asyncComputed: {
    async recentActivityBasicUserInfos(): Promise<BasicUserInfo[]> {
      const basicUserInfos = await Promise.all(
        this.recentActivityUniqUserIds.map(ids => getBasicUserInfo(this.userRepository, ids))
      );
      return basicUserInfos.filter((d: BasicUserInfo | null): d is BasicUserInfo => d !== null);
    },

    async searchActivityBasicUserInfos(): Promise<BasicUserInfo[]> {
      const basicUserInfos = await Promise.all(
        this.searchActivityUniqUserIds.map(ids => getBasicUserInfo(this.userRepository, ids))
      );
      return basicUserInfos.filter((d: BasicUserInfo | null): d is BasicUserInfo => d !== null);
    },

    async recentSearchActivityLogs(): Promise<ActivityLog[]> {
      const myUserId = this.appStateStore.userState?.userId;
      if (myUserId === undefined) return [];

      const dbDocuments = this.dbDocuments;
      if (dbDocuments === null) return [];
      if (dbDocuments.length == 0) return [];
      const activityLogs: (ActivityLog | null)[] = await Promise.all<ActivityLog | null>(
        dbDocuments.map((d: any) => rawToActivityLogTab(d, myUserId, this.tab))
      );
      const reverseActivityLogs = activityLogs.filter((d: ActivityLog | null): d is ActivityLog => d !== null);

      this.isSearch = false;
      return reverseActivityLogs;
    },

    async recentActivityLogs(): Promise<ActivityLog[]> {
      const myUserId = this.appStateStore.userState?.userId;
      if (myUserId === undefined) return [];

      const fsDocuments = this.fsJoinDocuments;
      if (fsDocuments.length == 0) return [];
      const activityLogs: (ActivityLog | null)[] = await Promise.all<ActivityLog | null>(
        fsDocuments.map((d: any) => rawToActivityLogTab(d, myUserId, this.tab))
      );
      const reverseActivityLogs = activityLogs.filter((d: ActivityLog | null): d is ActivityLog => d !== null);

      return reverseActivityLogs;
    }
  },
  mounted: function() {
    this.init();
  },
  methods: {
    search() {
      if (this.searchTextInput === "") {
        this.clearSearchResults();
        return;
      }
      this.isSearch = true;
      this.changeTab(TabType.search_result);
      // this.changeTab(tabType.search_result);
    },
    init() {
      this.changeTab(TabType.all);
    },

    changeRecentActivities(tab: TabType) {
      const myClassId = this.appStateStore.teacherState?.selectedClass()?.id;
      if (myClassId === undefined) return;
      const myUserId = this.appStateStore.userState?.userId;
      if (myUserId === undefined) return;
      const activities: any = loadRecentActivityLogs(this.$firestore, tab, myClassId, myUserId);
      this.$bind("fsDocuments", activities);
    },

    clearSearchResults() {
      this.dbDocuments = [];
    },
    async searchActivity() {
      const myClassId = this.appStateStore.teacherState?.selectedClass()?.id;
      if (myClassId === undefined) return;
      const myUserId = this.appStateStore.userState?.userId;
      if (myUserId === undefined) return;
      if (this.searchTextInput === "") {
        this.clearSearchResults();
        return;
      }
      // ここで activity service の listを取得。
      const activities = await this.activityRepository.getSearchActivities(myClassId, this.searchTextInput);
      if (activities instanceof Err) return;
      if (activities === undefined) return;
      if (activities.length === 0) this.isSearch = false;
      this.dbDocuments = activities;
    },

    selectedRecentActivity(studentUserId: string, uuid: string, date: Date) {
      this.$emit("selectedRecentActivity", studentUserId, uuid, date);
    },

    changeTab(tab: TabType) {
      this.removeUnMyList();
      this.tab = tab;
      if (tab === TabType.search_result) {
        // this.$nextTick(() => this.$refs.searchText.focus());
        this.searchActivity();
      } else {
        this.changeRecentActivities(tab);
      }
    },

    // ★マークをクリック
    async myListClick(studentUserId: string, uuid: string, mylisted: boolean) {
      if (this.tab === TabType.mylist) {
        if (!mylisted) {
          this.pushUnMyList(uuid);
        } else {
          this.removeUnMyList(uuid);
        }
      }
      if (mylisted) {
        await this.activityRepository.postMylistActivityLog(studentUserId, uuid);
      } else {
        await this.activityRepository.deleteMylistActivityLog(studentUserId, uuid);
      }
      // 検索タブの場合は、再検索を行う（★マーク・既読・未読の再読み込み）
      if (this.tab === TabType.search_result) this.search();
    },

    removeUnMyList(uuid: string | null = null) {
      if (this.unMyLists === null) return;
      if (uuid === null) {
        this.unMyLists.splice(0, this.unMyLists.length);
      } else {
        const unMyLists = this.unMyLists;
        this.unMyLists = unMyLists.filter(list => {
          return list.uuid !== uuid;
        });
      }
    },

    pushUnMyList(uuid: string) {
      const vm = this;
      this.fsDocuments.forEach((doc: any) => {
        if (doc.uuid === uuid) {
          const d = doc;
          d.advanced.mylistedUsers = [];
          vm.unMyLists?.push(d);
          return;
        }
      });
    }
  },
  watch: {
    tab: {
      handler(tab: TabType) {
        if (tab !== TabType.search_result) this.changeRecentActivities(tab);
      }
    }
  }
});

async function getBasicUserInfo(userRepository: UserRepository, ids: UniqUserIds): Promise<BasicUserInfo | null> {
  try {
    const userInfo = await userRepository.getBasicUserInfo(ids.userId);
    if (userInfo instanceof Err) return null;

    // 保護者の場合は児童生徒の名称を取得する。
    if (userInfo.userType === "guardian") {
      const studentResults = await userRepository.getBasicUserInfo(ids.studentUserId);
      if (studentResults instanceof Err) return null;
      userInfo.name = `${studentResults.name}の${userInfo.name}`;
    }
    return userInfo;
  } catch (e) {
    log.debug(`Failed to sign in to basicInfo: name=${e.name}, message=${e.message}`);
    return null;
  }
}

async function rawToActivityLogTab(obj: any, myUserId: string, tab: TabType): Promise<ActivityLog | null> {
  // unread取得の際はallと同様のデータ取得しているため、ここで間引く
  if (tab === TabType.unread && obj.advanced.readUsers.includes(myUserId)) return null;
  return rawToActivityLog(Vue.prototype.$storage, obj, myUserId);
}
