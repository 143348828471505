/* tslint:disable */
/* eslint-disable */
/**
 * 教科学習サービス
 * 教科に関する処理を行う。
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 学習活動。  * リソース名: /eeCurriculums/{eecId}/months/{1, 2, ..., 12}/activities/{activityId} * 作成・削除: 可能  文章評価教科の学習活動。  月 : 学習活動  = 1 : n
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * リソース名。
     * @type {string}
     * @memberof Activity
     */
    self: string;
    /**
     * 文書評価教科ID。
     * @type {string}
     * @memberof Activity
     */
    eecId: string;
    /**
     * 月。
     * @type {number}
     * @memberof Activity
     */
    month: number;
    /**
     * 学習活動ID。
     * @type {string}
     * @memberof Activity
     */
    activityId: string;
    /**
     * 有効化(公開)されていればtrue。
     * @type {boolean}
     * @memberof Activity
     */
    enabled: boolean;
    /**
     * 
     * @type {HashedValue}
     * @memberof Activity
     */
    text: HashedValue;
    /**
     * 並び順。0始まり。
     * @type {number}
     * @memberof Activity
     */
    orderNum: number;
}
/**
 * 学習活動(書き込み)。
 * @export
 * @interface ActivityWrite
 */
export interface ActivityWrite {
    /**
     * 有効化(公開)されていればtrue。
     * @type {boolean}
     * @memberof ActivityWrite
     */
    enabled?: boolean;
    /**
     * 
     * @type {HashedValue}
     * @memberof ActivityWrite
     */
    text?: HashedValue;
}
/**
 * 色。
 * @export
 * @interface Color
 */
export interface Color {
    /**
     * 
     * @type {number}
     * @memberof Color
     */
    red: number;
    /**
     * 
     * @type {number}
     * @memberof Color
     */
    green: number;
    /**
     * 
     * @type {number}
     * @memberof Color
     */
    blue: number;
}
/**
 * 数値評価カリキュラムの観点の内容構成。  * リソース名: /neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId} * 作成・削除: 可能  観点 : 内容構成 = 1 : n
 * @export
 * @interface Content
 */
export interface Content {
    /**
     * リソース名。
     * @type {string}
     * @memberof Content
     */
    self: string;
    /**
     * 数値評価教科ID。
     * @type {string}
     * @memberof Content
     */
    necId: string;
    /**
     * 観点ID。
     * @type {string}
     * @memberof Content
     */
    viewPointId: string;
    /**
     * 内容構成ID。
     * @type {string}
     * @memberof Content
     */
    contentId: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof Content
     */
    name: HashedValue;
    /**
     * 並び順。0始まり。
     * @type {number}
     * @memberof Content
     */
    orderNum: number;
}
/**
 * 内容構成月。  * リソース名: /neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}/months/{1, 2, ..., 12} * 作成・削除: 不可能(他と連動)  数値評価カリキュラムの内容構成の1か月分の内容。  内容構成 : 月 = 1 : 12
 * @export
 * @interface ContentMonth
 */
export interface ContentMonth {
    /**
     * リソース名。
     * @type {string}
     * @memberof ContentMonth
     */
    self: string;
    /**
     * 数値評価教科ID。
     * @type {string}
     * @memberof ContentMonth
     */
    necId: string;
    /**
     * 観点ID。
     * @type {string}
     * @memberof ContentMonth
     */
    viewPointId: string;
    /**
     * 内容構成ID。
     * @type {string}
     * @memberof ContentMonth
     */
    contentId: string;
    /**
     * 月。
     * @type {number}
     * @memberof ContentMonth
     */
    month: number;
    /**
     * 内容構成の当月分が有効化(公開)されていればtrue。
     * @type {boolean}
     * @memberof ContentMonth
     */
    enabled: boolean;
    /**
     * 
     * @type {HashedValue}
     * @memberof ContentMonth
     */
    text: HashedValue;
}
/**
 * 内容構成月(書き込み)。
 * @export
 * @interface ContentMonthWrite
 */
export interface ContentMonthWrite {
    /**
     * 内容構成の当月分が有効化(公開)されていればtrue。
     * @type {boolean}
     * @memberof ContentMonthWrite
     */
    enabled?: boolean;
    /**
     * 
     * @type {HashedValue}
     * @memberof ContentMonthWrite
     */
    text?: HashedValue;
}
/**
 * 数値評価カリキュラムの観点の内容構成(書き込み)。
 * @export
 * @interface ContentWrite
 */
export interface ContentWrite {
    /**
     * 
     * @type {HashedValue}
     * @memberof ContentWrite
     */
    name?: HashedValue;
}
/**
 * 文章評価教科月。  * リソース名: /eeCurriculums/{eecId}/months/{1, 2, ..., 12} * 作成・削除: 不可能(他と連動)  文書評価の学習活動は各月の下に所属するので、それをまとめるためのエンティティ。  文章評価教科 : 月 = 1 : 12
 * @export
 * @interface EECMonth
 */
export interface EECMonth {
    /**
     * リソース名。
     * @type {string}
     * @memberof EECMonth
     */
    self: string;
    /**
     * 文書評価教科ID。
     * @type {string}
     * @memberof EECMonth
     */
    eecId: string;
    /**
     * 月。
     * @type {number}
     * @memberof EECMonth
     */
    month: number;
}
/**
 * 文書評価教科。  * リソース名: /eeCurriculums/{eecId} * 作成・削除: 可能  (年度, 学年, 科目)ごとに1エンティティとする。  EEC = Essay-Evaluated Curriculum の略。
 * @export
 * @interface EECurriculum
 */
export interface EECurriculum {
    /**
     * リソース名。
     * @type {string}
     * @memberof EECurriculum
     */
    self: string;
    /**
     * 数値評価教科ID。
     * @type {string}
     * @memberof EECurriculum
     */
    eecId: string;
    /**
     * 年度。
     * @type {number}
     * @memberof EECurriculum
     */
    schoolYear: number;
    /**
     * 学年。`e1`, `e2`, ..., `e6`, `j1`, `j2`, `j3` のいずれか。
     * @type {string}
     * @memberof EECurriculum
     */
    grade: string;
    /**
     * 並び順。0始まり。
     * @type {number}
     * @memberof EECurriculum
     */
    orderNum: number;
    /**
     * 
     * @type {HashedValue}
     * @memberof EECurriculum
     */
    name: HashedValue;
}
/**
 * 文書評価教科(書き込み)。
 * @export
 * @interface EECurriculumWrite
 */
export interface EECurriculumWrite {
    /**
     * 学年。
     * @type {number}
     * @memberof EECurriculumWrite
     */
    schoolYear?: number;
    /**
     * 学年。`e1`, `e2`, ..., `e6`, `j1`, `j2`, `j3` のいずれか。
     * @type {string}
     * @memberof EECurriculumWrite
     */
    grade?: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof EECurriculumWrite
     */
    name?: HashedValue;
}
/**
 * エラーレスポンス。
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * エラーコード
     * @type {number}
     * @memberof ErrorResponse
     */
    code?: number;
    /**
     * エラーメッセージ
     * @type {string}
     * @memberof ErrorResponse
     */
    message?: string;
}
/**
 * 数値評価教科の内容構成の1か月分の評価。  * リソース名: /neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}/months/{1, 2, ..., 12}/evaluations/{studentUserId} * 作成・削除: 不可能(他と連動)  月 : 評価 = 1 : n
 * @export
 * @interface Evaluation
 */
export interface Evaluation {
    /**
     * リソース名。
     * @type {string}
     * @memberof Evaluation
     */
    self: string;
    /**
     * 数値評価教科ID。
     * @type {string}
     * @memberof Evaluation
     */
    necId: string;
    /**
     * 観点ID。
     * @type {string}
     * @memberof Evaluation
     */
    viewPointId: string;
    /**
     * 内容構成ID。
     * @type {string}
     * @memberof Evaluation
     */
    contentId: string;
    /**
     * 月。
     * @type {number}
     * @memberof Evaluation
     */
    month: number;
    /**
     * 児童生徒ID。
     * @type {string}
     * @memberof Evaluation
     */
    studentUserId: string;
    /**
     * 評価。`S`, `A`, `B`, `C`のいずれか。
     * @type {string}
     * @memberof Evaluation
     */
    rating?: string;
    /**
     * 教師入力値(rating)が公開済ならtrue。
     * @type {boolean}
     * @memberof Evaluation
     */
    teacherInputPublished: boolean;
}
/**
 * 数値評価教科の内容構成の1か月分の評価(書き込み)。
 * @export
 * @interface EvaluationWrite
 */
export interface EvaluationWrite {
    /**
     * 評価。`S`, `A`, `B`, `C`のいずれか。
     * @type {string}
     * @memberof EvaluationWrite
     */
    rating?: string;
    /**
     * 教師入力値(rating)が公開済ならtrue。
     * @type {boolean}
     * @memberof EvaluationWrite
     */
    teacherInputPublished?: boolean;
}
/**
 * 値とハッシュ値のペア。 複数人による多重編集を検知したい値に関しては、これを用いる。
 * @export
 * @interface HashedValue
 */
export interface HashedValue {
    /**
     * 値。
     * @type {string}
     * @memberof HashedValue
     */
    value: string;
    /**
     * ハッシュ値。変更を検知できさえすれば良いので、`value`が常に非常に短い場合は、`value`と全く同じ値でも良い。  新規作成時も必須だが、値は無視される（空文字列で良い）。
     * @type {string}
     * @memberof HashedValue
     */
    hash: string;
}
/**
 * 文書評価教科の学習記録。  * リソース名: /eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId} * 作成・削除: 可能  学習記録児童生徒 : 学習記録 = 1 : n  ※ 学習活動(`activity`)フィールドは、基本的に定義済みの学習活動(`Activity`)エンティティから選択はするが、自由記述も可能である。よって、学習記録(`Journal`)は、学習活動をテキストとして持ち、学習活動IDとしては持たない。
 * @export
 * @interface Journal
 */
export interface Journal {
    /**
     * リソース名。
     * @type {string}
     * @memberof Journal
     */
    slelf: string;
    /**
     * 文書評価教科ID。
     * @type {string}
     * @memberof Journal
     */
    eecId: string;
    /**
     * 児童生徒ID。
     * @type {string}
     * @memberof Journal
     */
    studentUserId: string;
    /**
     * 学習記録ID。
     * @type {string}
     * @memberof Journal
     */
    journalId: string;
    /**
     * 月。
     * @type {number}
     * @memberof Journal
     */
    month: number;
    /**
     * 
     * @type {HashedValue}
     * @memberof Journal
     */
    activity: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof Journal
     */
    studentComment: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof Journal
     */
    teacherComment?: HashedValue;
    /**
     * 教師入力値が公開済なら`true`。
     * @type {boolean}
     * @memberof Journal
     */
    teacherInputPublished: boolean;
    /**
     * 作成日時。
     * @type {string}
     * @memberof Journal
     */
    createdAt: string;
    /**
     * リクエスト者が児童生徒入力値(`month`, `activity`, `studentComment`)を更新可能なら`true`。
     * @type {boolean}
     * @memberof Journal
     */
    studentInputLocked: boolean;
}
/**
 * 文書評価教科の学習記録ファイル。  * リソース名: /eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId}/journalFiles/{journalFileId} * 作成・削除: 可能  学習記録 : 学習記録ファイル  = 1 : n
 * @export
 * @interface JournalFile
 */
export interface JournalFile {
    /**
     * リソース名。
     * @type {string}
     * @memberof JournalFile
     */
    self: string;
    /**
     * 文書評価教科ID。
     * @type {string}
     * @memberof JournalFile
     */
    eecId: string;
    /**
     * 児童生徒ID。
     * @type {string}
     * @memberof JournalFile
     */
    studentUserId: string;
    /**
     * 学習記録ID。
     * @type {string}
     * @memberof JournalFile
     */
    journalId: string;
    /**
     * 学習記録ファイルID。
     * @type {string}
     * @memberof JournalFile
     */
    journalFileId: string;
    /**
     * ファイルタイプ。`image`, `video`, `audio`, `any`のいずれか。
     * @type {string}
     * @memberof JournalFile
     */
    type: string;
    /**
     * ファイルのサブタイプ。例えばタイプが`image`なら、`png`, `jpeg`など。
     * @type {string}
     * @memberof JournalFile
     */
    subtype: string;
    /**
     * MIME タイプ (IANA メディアタイプ)。
     * @type {string}
     * @memberof JournalFile
     */
    mediaType: string;
    /**
     * ファイル名。
     * @type {string}
     * @memberof JournalFile
     */
    filename: string;
    /**
     * ファイル拡張子。
     * @type {string}
     * @memberof JournalFile
     */
    ext: string;
    /**
     * Storage上での、ファイル自身のパス。
     * @type {string}
     * @memberof JournalFile
     */
    gcsObjectPath: string;
    /**
     * Storage上での、サムネイルのパス。サムネイルが存在しないファイルでも、この値自体は存在する。
     * @type {string}
     * @memberof JournalFile
     */
    thumbnailGcsObjectPath: string;
    /**
     * サムネイル画像が、ストレージ上に存在すればtrue。未作成の場合や、サムネイル不要なファイルタイプの場合はfalse。
     * @type {boolean}
     * @memberof JournalFile
     */
    hasThumbnail: boolean;
    /**
     * 横幅。(縦横サイズが存在しないファイルの場合は値なし。)
     * @type {number}
     * @memberof JournalFile
     */
    width?: number;
    /**
     * 縦幅。(縦横サイズが存在しないファイルの場合は値なし。)
     * @type {number}
     * @memberof JournalFile
     */
    height?: number;
    /**
     * 作成日時。
     * @type {string}
     * @memberof JournalFile
     */
    createdAt: string;
}
/**
 * 学習記録児童生徒。  * リソース名: /eeCurriculums/{eecId}/journalStudents/{studentUserId} * 作成・削除: 不可能(他と連動)  文章評価教科の学習記録を持つ児童生徒。  文章評価教科 : 学習記録児童生徒  = 1 : n
 * @export
 * @interface JournalStudent
 */
export interface JournalStudent {
    /**
     * リソース名。
     * @type {string}
     * @memberof JournalStudent
     */
    self: string;
    /**
     * 文書評価教科ID。
     * @type {string}
     * @memberof JournalStudent
     */
    eecId: string;
    /**
     * 児童生徒ID。
     * @type {string}
     * @memberof JournalStudent
     */
    studentUserId: string;
}
/**
 * 文書評価教科の学習記録(書き込み)。
 * @export
 * @interface JournalWrite
 */
export interface JournalWrite {
    /**
     * 月。
     * @type {number}
     * @memberof JournalWrite
     */
    month?: number;
    /**
     * 
     * @type {HashedValue}
     * @memberof JournalWrite
     */
    activity?: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof JournalWrite
     */
    studentComment?: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof JournalWrite
     */
    teacherComment?: HashedValue;
    /**
     * 教師入力値が公開済なら`true`。
     * @type {boolean}
     * @memberof JournalWrite
     */
    teacherInputPublished?: boolean;
}
/**
 * 数値評価教科。  * リソース名: /neCurriculums/{necId} * 作成・削除: 可能  (年度, 学年, 科目)ごとに1エンティティとする。  NEC = Numerically-Evaluated Curriculum の略。
 * @export
 * @interface NECurriculum
 */
export interface NECurriculum {
    /**
     * リソース名。
     * @type {string}
     * @memberof NECurriculum
     */
    self: string;
    /**
     * 数値評価教科ID。
     * @type {string}
     * @memberof NECurriculum
     */
    necId: string;
    /**
     * 年度。
     * @type {number}
     * @memberof NECurriculum
     */
    schoolYear: number;
    /**
     * 学年。`e1`, `e2`, ..., `e6`, `j1`, `j2`, `j3` のいずれか。
     * @type {string}
     * @memberof NECurriculum
     */
    grade: string;
    /**
     * 並び順。0始まり。
     * @type {number}
     * @memberof NECurriculum
     */
    orderNum: number;
    /**
     * 
     * @type {HashedValue}
     * @memberof NECurriculum
     */
    name: HashedValue;
}
/**
 * 数値評価教科(書き込み)。
 * @export
 * @interface NECurriculumWrite
 */
export interface NECurriculumWrite {
    /**
     * 学年。
     * @type {number}
     * @memberof NECurriculumWrite
     */
    schoolYear?: number;
    /**
     * 学年。`e1`, `e2`, ..., `e6`, `j1`, `j2`, `j3` のいずれか。
     * @type {string}
     * @memberof NECurriculumWrite
     */
    grade?: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof NECurriculumWrite
     */
    name?: HashedValue;
}
/**
 * 数値評価教科の観点。  * リソース名: /neCurriculums/{necId}/viewPoints/{viewPointId} * 作成・削除: 可能  数値評価教科 : 観点 = 1 : n
 * @export
 * @interface ViewPoint
 */
export interface ViewPoint {
    /**
     * リソース名。
     * @type {string}
     * @memberof ViewPoint
     */
    self: string;
    /**
     * 数値評価教科ID。
     * @type {string}
     * @memberof ViewPoint
     */
    necId: string;
    /**
     * 観点ID。
     * @type {string}
     * @memberof ViewPoint
     */
    viewPointId: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof ViewPoint
     */
    name: HashedValue;
    /**
     * 並び順。0始まり。
     * @type {number}
     * @memberof ViewPoint
     */
    orderNum: number;
    /**
     * 
     * @type {Color}
     * @memberof ViewPoint
     */
    color: Color;
}
/**
 * 数値評価教科の観点(書き込み)。
 * @export
 * @interface ViewPointWrite
 */
export interface ViewPointWrite {
    /**
     * 
     * @type {HashedValue}
     * @memberof ViewPointWrite
     */
    name?: HashedValue;
    /**
     * 
     * @type {Color}
     * @memberof ViewPointWrite
     */
    color?: Color;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 学習活動を削除する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivity: async (eecId: string, month: string, activityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('deleteActivity', 'eecId', eecId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('deleteActivity', 'month', month)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('deleteActivity', 'activityId', activityId)
            const localVarPath = `/eeCurriculums/{eecId}/months/{month}/activities/{activityId}`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成を削除する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContent: async (necId: string, viewPointId: string, contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('deleteContent', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('deleteContent', 'viewPointId', viewPointId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('deleteContent', 'contentId', contentId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成月を削除する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {number} month 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentMonth: async (necId: string, viewPointId: string, contentId: string, month: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('deleteContentMonth', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('deleteContentMonth', 'viewPointId', viewPointId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('deleteContentMonth', 'contentId', contentId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('deleteContentMonth', 'month', month)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}/months/{month}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 文書評価教科を削除する。
         * @param {string} eecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEECurriculum: async (eecId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('deleteEECurriculum', 'eecId', eecId)
            const localVarPath = `/eeCurriculums/{eecId}`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒   * `studentUserId`が自分であり、`teacherInputPublished`が`false`であるもののみ削除可能。 * 保護者   * 削除不可能。 * 教師   * なんでも削除可能。
         * @summary 学習記録を削除する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournal: async (eecId: string, studentUserId: string, journalId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('deleteJournal', 'eecId', eecId)
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('deleteJournal', 'studentUserId', studentUserId)
            // verify required parameter 'journalId' is not null or undefined
            assertParamExists('deleteJournal', 'journalId', journalId)
            const localVarPath = `/eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId}`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)))
                .replace(`{${"journalId"}}`, encodeURIComponent(String(journalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒   * 児童生徒は、`studentUserId`が自分であるもののみ削除可能。 * 保護者   * 削除不可能。 * 教師   * なんでも削除可能。
         * @summary 学習記録ファイルを削除する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalFile: async (eecId: string, studentUserId: string, journalId: string, journalFileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('deleteJournalFile', 'eecId', eecId)
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('deleteJournalFile', 'studentUserId', studentUserId)
            // verify required parameter 'journalId' is not null or undefined
            assertParamExists('deleteJournalFile', 'journalId', journalId)
            // verify required parameter 'journalFileId' is not null or undefined
            assertParamExists('deleteJournalFile', 'journalFileId', journalFileId)
            const localVarPath = `/eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId}/journalFiles/{journalFileId}`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)))
                .replace(`{${"journalId"}}`, encodeURIComponent(String(journalId)))
                .replace(`{${"journalFileId"}}`, encodeURIComponent(String(journalFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 数値評価教科を削除する。
         * @param {string} necId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNECurriculum: async (necId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('deleteNECurriculum', 'necId', necId)
            const localVarPath = `/neCurriculums/{necId}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 観点を削除する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteViewPoint: async (necId: string, viewPointId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('deleteViewPoint', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('deleteViewPoint', 'viewPointId', viewPointId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習活動を取得する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity: async (eecId: string, month: string, activityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('getActivity', 'eecId', eecId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getActivity', 'month', month)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('getActivity', 'activityId', activityId)
            const localVarPath = `/eeCurriculums/{eecId}/months/{month}/activities/{activityId}`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 内容構成を取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContent: async (necId: string, viewPointId: string, contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('getContent', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('getContent', 'viewPointId', viewPointId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('getContent', 'contentId', contentId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 内容構成月を取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {number} month 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentMonth: async (necId: string, viewPointId: string, contentId: string, month: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('getContentMonth', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('getContentMonth', 'viewPointId', viewPointId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('getContentMonth', 'contentId', contentId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getContentMonth', 'month', month)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}/months/{month}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 文章評価教科月を取得する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEECMonth: async (eecId: string, month: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('getEECMonth', 'eecId', eecId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getEECMonth', 'month', month)
            const localVarPath = `/eeCurriculums/{eecId}/months/{month}`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 文書評価教科を取得する。
         * @param {string} eecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEECurriculum: async (eecId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('getEECurriculum', 'eecId', eecId)
            const localVarPath = `/eeCurriculums/{eecId}`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`rating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary 評価を取得する。
         * @param {string} necId 
         * @param {string} viewPointId 
         * @param {string} contentId 
         * @param {string} month 
         * @param {string} studentUserId 児童生徒ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluation: async (necId: string, viewPointId: string, contentId: string, month: string, studentUserId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('getEvaluation', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('getEvaluation', 'viewPointId', viewPointId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('getEvaluation', 'contentId', contentId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getEvaluation', 'month', month)
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('getEvaluation', 'studentUserId', studentUserId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}/months/{month}/evaluations/{studentUserId}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary 学習記録を取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournal: async (eecId: string, studentUserId: string, journalId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('getJournal', 'eecId', eecId)
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('getJournal', 'studentUserId', studentUserId)
            // verify required parameter 'journalId' is not null or undefined
            assertParamExists('getJournal', 'journalId', journalId)
            const localVarPath = `/eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId}`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)))
                .replace(`{${"journalId"}}`, encodeURIComponent(String(journalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習記録ファイルを取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalFile: async (eecId: string, studentUserId: string, journalId: string, journalFileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('getJournalFile', 'eecId', eecId)
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('getJournalFile', 'studentUserId', studentUserId)
            // verify required parameter 'journalId' is not null or undefined
            assertParamExists('getJournalFile', 'journalId', journalId)
            // verify required parameter 'journalFileId' is not null or undefined
            assertParamExists('getJournalFile', 'journalFileId', journalFileId)
            const localVarPath = `/eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId}/journalFiles/{journalFileId}`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)))
                .replace(`{${"journalId"}}`, encodeURIComponent(String(journalId)))
                .replace(`{${"journalFileId"}}`, encodeURIComponent(String(journalFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習記録児童生徒を取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalStudent: async (eecId: string, studentUserId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('getJournalStudent', 'eecId', eecId)
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('getJournalStudent', 'studentUserId', studentUserId)
            const localVarPath = `/eeCurriculums/{eecId}/journalStudents/{studentUserId}`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 数値評価教科を取得する。
         * @param {string} necId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNECurriculum: async (necId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('getNECurriculum', 'necId', necId)
            const localVarPath = `/neCurriculums/{necId}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 観点を取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewPoint: async (necId: string, viewPointId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('getViewPoint', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('getViewPoint', 'viewPointId', viewPointId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `month`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習活動のリストを取得する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {boolean} [enabled] 有効化フラグ。
         * @param {string} [text] テキスト。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivity: async (eecId: string, month: string, enabled?: boolean, text?: string, schoolYear?: number, grade?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('listActivity', 'eecId', eecId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('listActivity', 'month', month)
            const localVarPath = `/eeCurriculums/{eecId}/months/{month}/activities`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (grade !== undefined) {
                localVarQueryParameter['grade'] = grade;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `viewPointId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 内容構成のリストを取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} [name] 内容構成名。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContent: async (necId: string, viewPointId: string, name?: string, schoolYear?: number, grade?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('listContent', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('listContent', 'viewPointId', viewPointId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}/contents`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (grade !== undefined) {
                localVarQueryParameter['grade'] = grade;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `viewPointId`と`contentId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 内容構成月のリストを取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {number} [month] 月。
         * @param {boolean} [enabled] 有効化フラグ。
         * @param {string} [text] テキスト。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContentMonth: async (necId: string, viewPointId: string, contentId: string, month?: number, enabled?: boolean, text?: string, schoolYear?: number, grade?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('listContentMonth', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('listContentMonth', 'viewPointId', viewPointId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('listContentMonth', 'contentId', contentId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}/months`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (grade !== undefined) {
                localVarQueryParameter['grade'] = grade;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 文章評価教科月のリストを取得する。
         * @param {string} eecId 
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEECMonth: async (eecId: string, schoolYear?: number, grade?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('listEECMonth', 'eecId', eecId)
            const localVarPath = `/eeCurriculums/{eecId}/months`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (grade !== undefined) {
                localVarQueryParameter['grade'] = grade;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 文書評価教科のリストを取得する。
         * @param {number} schoolYear 年度。
         * @param {string} grade 学年。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {string} [name] 教科名。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEECurriculum: async (schoolYear: number, grade: string, name?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolYear' is not null or undefined
            assertParamExists('listEECurriculum', 'schoolYear', schoolYear)
            // verify required parameter 'grade' is not null or undefined
            assertParamExists('listEECurriculum', 'grade', grade)
            const localVarPath = `/eeCurriculums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (grade !== undefined) {
                localVarQueryParameter['grade'] = grade;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `viewPointId`と`contentId`と`month`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`rating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary 評価のリストを取得する。
         * @param {string} necId 
         * @param {string} viewPointId 
         * @param {string} contentId 
         * @param {string} month 
         * @param {string} [classId] クラスID。&#x60;classId&#x60;と&#x60;studentUserId&#x60;のいずれか片方は必須。
         * @param {string} [studentUserId] 児童生徒ID。&#x60;classId&#x60;と&#x60;studentUserId&#x60;のいずれか片方は必須。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvaluation: async (necId: string, viewPointId: string, contentId: string, month: string, classId?: string, studentUserId?: string, schoolYear?: number, grade?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('listEvaluation', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('listEvaluation', 'viewPointId', viewPointId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('listEvaluation', 'contentId', contentId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('listEvaluation', 'month', month)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}/months/{month}/evaluations`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (classId !== undefined) {
                localVarQueryParameter['classId'] = classId;
            }

            if (studentUserId !== undefined) {
                localVarQueryParameter['studentUserId'] = studentUserId;
            }

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (grade !== undefined) {
                localVarQueryParameter['grade'] = grade;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `studentUserId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary 学習記録のリストを取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {number} [month] 月。
         * @param {string} [activity] 学習活動。
         * @param {string} [classId] クラスID。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJournal: async (eecId: string, studentUserId: string, month?: number, activity?: string, classId?: string, schoolYear?: number, grade?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('listJournal', 'eecId', eecId)
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('listJournal', 'studentUserId', studentUserId)
            const localVarPath = `/eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (activity !== undefined) {
                localVarQueryParameter['activity'] = activity;
            }

            if (classId !== undefined) {
                localVarQueryParameter['classId'] = classId;
            }

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (grade !== undefined) {
                localVarQueryParameter['grade'] = grade;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `studentUserId`と`journalId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習記録ファイルのリストを取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {string} [classId] クラスID。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJournalFile: async (eecId: string, studentUserId: string, journalId: string, classId?: string, schoolYear?: number, grade?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('listJournalFile', 'eecId', eecId)
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('listJournalFile', 'studentUserId', studentUserId)
            // verify required parameter 'journalId' is not null or undefined
            assertParamExists('listJournalFile', 'journalId', journalId)
            const localVarPath = `/eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId}/journalFiles`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)))
                .replace(`{${"journalId"}}`, encodeURIComponent(String(journalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (classId !== undefined) {
                localVarQueryParameter['classId'] = classId;
            }

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (grade !== undefined) {
                localVarQueryParameter['grade'] = grade;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 数値評価教科のリストを取得する。
         * @param {number} schoolYear 年度。
         * @param {string} grade 学年。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {string} [name] 教科名。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNECurriculum: async (schoolYear: number, grade: string, name?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolYear' is not null or undefined
            assertParamExists('listNECurriculum', 'schoolYear', schoolYear)
            // verify required parameter 'grade' is not null or undefined
            assertParamExists('listNECurriculum', 'grade', grade)
            const localVarPath = `/neCurriculums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (grade !== undefined) {
                localVarQueryParameter['grade'] = grade;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 観点のリストを取得する。
         * @param {string} necId 
         * @param {string} [name] 観点名。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listViewPoint: async (necId: string, name?: string, schoolYear?: number, grade?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('listViewPoint', 'necId', necId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (grade !== undefined) {
                localVarQueryParameter['grade'] = grade;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 学習活動を更新する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {string} activityId 
         * @param {ActivityWrite} [activityWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchActivity: async (eecId: string, month: string, activityId: string, activityWrite?: ActivityWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('patchActivity', 'eecId', eecId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('patchActivity', 'month', month)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('patchActivity', 'activityId', activityId)
            const localVarPath = `/eeCurriculums/{eecId}/months/{month}/activities/{activityId}`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成を更新する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {ContentWrite} [contentWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContent: async (necId: string, viewPointId: string, contentId: string, contentWrite?: ContentWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('patchContent', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('patchContent', 'viewPointId', viewPointId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('patchContent', 'contentId', contentId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成月を更新する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {number} month 月
         * @param {ContentMonthWrite} [contentMonthWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContentMonth: async (necId: string, viewPointId: string, contentId: string, month: number, contentMonthWrite?: ContentMonthWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('patchContentMonth', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('patchContentMonth', 'viewPointId', viewPointId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('patchContentMonth', 'contentId', contentId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('patchContentMonth', 'month', month)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}/months/{month}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentMonthWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 文書評価教科を更新する。
         * @param {string} eecId 
         * @param {EECurriculumWrite} [eECurriculumWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEECurriculum: async (eecId: string, eECurriculumWrite?: EECurriculumWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('patchEECurriculum', 'eecId', eecId)
            const localVarPath = `/eeCurriculums/{eecId}`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eECurriculumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 評価を更新する。
         * @param {string} necId 
         * @param {string} viewPointId 
         * @param {string} contentId 
         * @param {string} month 
         * @param {string} studentUserId 児童生徒ID
         * @param {EvaluationWrite} [evaluationWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEvaluation: async (necId: string, viewPointId: string, contentId: string, month: string, studentUserId: string, evaluationWrite?: EvaluationWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('patchEvaluation', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('patchEvaluation', 'viewPointId', viewPointId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('patchEvaluation', 'contentId', contentId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('patchEvaluation', 'month', month)
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('patchEvaluation', 'studentUserId', studentUserId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}/months/{month}/evaluations/{studentUserId}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(evaluationWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒   * `studentUserId`が自分であり、`teacherInputPublished`が`false`であるもののみ更新可能。   * 更新可能フィールドは、`month`, `activity`, `studentComment`。 * 保護者   * 更新不可能。 * 教師   * なんでも更新可能。
         * @summary 学習記録を更新する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {JournalWrite} [journalWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchJournal: async (eecId: string, studentUserId: string, journalId: string, journalWrite?: JournalWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('patchJournal', 'eecId', eecId)
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('patchJournal', 'studentUserId', studentUserId)
            // verify required parameter 'journalId' is not null or undefined
            assertParamExists('patchJournal', 'journalId', journalId)
            const localVarPath = `/eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId}`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)))
                .replace(`{${"journalId"}}`, encodeURIComponent(String(journalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(journalWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 数値評価教科を更新する。
         * @param {string} necId 
         * @param {NECurriculumWrite} [nECurriculumWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNECurriculum: async (necId: string, nECurriculumWrite?: NECurriculumWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('patchNECurriculum', 'necId', necId)
            const localVarPath = `/neCurriculums/{necId}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nECurriculumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 観点を更新する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {ViewPointWrite} [viewPointWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchViewPoint: async (necId: string, viewPointId: string, viewPointWrite?: ViewPointWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('patchViewPoint', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('patchViewPoint', 'viewPointId', viewPointId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(viewPointWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 学習活動を作成する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {ActivityWrite} [activityWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivity: async (eecId: string, month: string, activityWrite?: ActivityWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('postActivity', 'eecId', eecId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('postActivity', 'month', month)
            const localVarPath = `/eeCurriculums/{eecId}/months/{month}/activities`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成を作成する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {ContentWrite} [contentWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContent: async (necId: string, viewPointId: string, contentWrite?: ContentWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('postContent', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('postContent', 'viewPointId', viewPointId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}/contents`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。
         * @summary 内容構成月を作成する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {ContentMonth} [contentMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContentMonth: async (necId: string, viewPointId: string, contentId: string, contentMonth?: ContentMonth, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('postContentMonth', 'necId', necId)
            // verify required parameter 'viewPointId' is not null or undefined
            assertParamExists('postContentMonth', 'viewPointId', viewPointId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('postContentMonth', 'contentId', contentId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}/months`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)))
                .replace(`{${"viewPointId"}}`, encodeURIComponent(String(viewPointId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentMonth, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 文書評価教科を作成する。
         * @param {EECurriculumWrite} [eECurriculumWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEECurriculums: async (eECurriculumWrite?: EECurriculumWrite, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/eeCurriculums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eECurriculumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ作成可能。   * 保護者は、作成不可能。 * 教師   * なんでも作成可能。  # その他  * 作成時、教師入力値(`teacherComment`, `teacherInputPublished`)は指定できない。
         * @summary 学習記録を作成する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {JournalWrite} [journalWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJournal: async (eecId: string, studentUserId: string, journalWrite?: JournalWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('postJournal', 'eecId', eecId)
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('postJournal', 'studentUserId', studentUserId)
            const localVarPath = `/eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(journalWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒   * 児童生徒は、`studentUserId`が自分であるもののみ作成可能。 * 保護者   * 作成不可能。 * 教師   * なんでも作成可能。
         * @summary 学習記録ファイルを作成する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJournalFile: async (eecId: string, studentUserId: string, journalId: string, upfile: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eecId' is not null or undefined
            assertParamExists('postJournalFile', 'eecId', eecId)
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('postJournalFile', 'studentUserId', studentUserId)
            // verify required parameter 'journalId' is not null or undefined
            assertParamExists('postJournalFile', 'journalId', journalId)
            // verify required parameter 'upfile' is not null or undefined
            assertParamExists('postJournalFile', 'upfile', upfile)
            const localVarPath = `/eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId}/journalFiles`
                .replace(`{${"eecId"}}`, encodeURIComponent(String(eecId)))
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)))
                .replace(`{${"journalId"}}`, encodeURIComponent(String(journalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (upfile !== undefined) { 
                localVarFormParams.append('upfile', upfile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 数値評価教科を作成する。
         * @param {NECurriculumWrite} [nECurriculumWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNECurriculum: async (nECurriculumWrite?: NECurriculumWrite, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/neCurriculums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nECurriculumWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 観点を作成する。
         * @param {string} necId 
         * @param {ViewPointWrite} [viewPointWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postViewPoint: async (necId: string, viewPointWrite?: ViewPointWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'necId' is not null or undefined
            assertParamExists('postViewPoint', 'necId', necId)
            const localVarPath = `/neCurriculums/{necId}/viewPoints`
                .replace(`{${"necId"}}`, encodeURIComponent(String(necId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(viewPointWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 学習活動を削除する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivity(eecId: string, month: string, activityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivity(eecId, month, activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成を削除する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContent(necId: string, viewPointId: string, contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContent(necId, viewPointId, contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成月を削除する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {number} month 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContentMonth(necId: string, viewPointId: string, contentId: string, month: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContentMonth(necId, viewPointId, contentId, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 文書評価教科を削除する。
         * @param {string} eecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEECurriculum(eecId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEECurriculum(eecId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒   * `studentUserId`が自分であり、`teacherInputPublished`が`false`であるもののみ削除可能。 * 保護者   * 削除不可能。 * 教師   * なんでも削除可能。
         * @summary 学習記録を削除する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJournal(eecId: string, studentUserId: string, journalId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJournal(eecId, studentUserId, journalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒   * 児童生徒は、`studentUserId`が自分であるもののみ削除可能。 * 保護者   * 削除不可能。 * 教師   * なんでも削除可能。
         * @summary 学習記録ファイルを削除する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJournalFile(eecId: string, studentUserId: string, journalId: string, journalFileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJournalFile(eecId, studentUserId, journalId, journalFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 数値評価教科を削除する。
         * @param {string} necId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNECurriculum(necId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNECurriculum(necId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 観点を削除する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteViewPoint(necId: string, viewPointId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteViewPoint(necId, viewPointId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習活動を取得する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivity(eecId: string, month: string, activityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivity(eecId, month, activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 内容構成を取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContent(necId: string, viewPointId: string, contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Content>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContent(necId, viewPointId, contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 内容構成月を取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {number} month 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentMonth(necId: string, viewPointId: string, contentId: string, month: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentMonth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentMonth(necId, viewPointId, contentId, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 文章評価教科月を取得する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEECMonth(eecId: string, month: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EECMonth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEECMonth(eecId, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 文書評価教科を取得する。
         * @param {string} eecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEECurriculum(eecId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EECurriculum>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEECurriculum(eecId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`rating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary 評価を取得する。
         * @param {string} necId 
         * @param {string} viewPointId 
         * @param {string} contentId 
         * @param {string} month 
         * @param {string} studentUserId 児童生徒ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvaluation(necId: string, viewPointId: string, contentId: string, month: string, studentUserId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evaluation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvaluation(necId, viewPointId, contentId, month, studentUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary 学習記録を取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournal(eecId: string, studentUserId: string, journalId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Journal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournal(eecId, studentUserId, journalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習記録ファイルを取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalFile(eecId: string, studentUserId: string, journalId: string, journalFileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalFile(eecId, studentUserId, journalId, journalFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習記録児童生徒を取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalStudent(eecId: string, studentUserId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalStudent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalStudent(eecId, studentUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 数値評価教科を取得する。
         * @param {string} necId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNECurriculum(necId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NECurriculum>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNECurriculum(necId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 観点を取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViewPoint(necId: string, viewPointId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewPoint>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViewPoint(necId, viewPointId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `month`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習活動のリストを取得する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {boolean} [enabled] 有効化フラグ。
         * @param {string} [text] テキスト。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActivity(eecId: string, month: string, enabled?: boolean, text?: string, schoolYear?: number, grade?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Activity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActivity(eecId, month, enabled, text, schoolYear, grade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `viewPointId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 内容構成のリストを取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} [name] 内容構成名。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContent(necId: string, viewPointId: string, name?: string, schoolYear?: number, grade?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Content>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContent(necId, viewPointId, name, schoolYear, grade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `viewPointId`と`contentId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 内容構成月のリストを取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {number} [month] 月。
         * @param {boolean} [enabled] 有効化フラグ。
         * @param {string} [text] テキスト。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContentMonth(necId: string, viewPointId: string, contentId: string, month?: number, enabled?: boolean, text?: string, schoolYear?: number, grade?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentMonth>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContentMonth(necId, viewPointId, contentId, month, enabled, text, schoolYear, grade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 文章評価教科月のリストを取得する。
         * @param {string} eecId 
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEECMonth(eecId: string, schoolYear?: number, grade?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EECMonth>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEECMonth(eecId, schoolYear, grade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 文書評価教科のリストを取得する。
         * @param {number} schoolYear 年度。
         * @param {string} grade 学年。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {string} [name] 教科名。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEECurriculum(schoolYear: number, grade: string, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EECurriculum>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEECurriculum(schoolYear, grade, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `viewPointId`と`contentId`と`month`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`rating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary 評価のリストを取得する。
         * @param {string} necId 
         * @param {string} viewPointId 
         * @param {string} contentId 
         * @param {string} month 
         * @param {string} [classId] クラスID。&#x60;classId&#x60;と&#x60;studentUserId&#x60;のいずれか片方は必須。
         * @param {string} [studentUserId] 児童生徒ID。&#x60;classId&#x60;と&#x60;studentUserId&#x60;のいずれか片方は必須。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEvaluation(necId: string, viewPointId: string, contentId: string, month: string, classId?: string, studentUserId?: string, schoolYear?: number, grade?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Evaluation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEvaluation(necId, viewPointId, contentId, month, classId, studentUserId, schoolYear, grade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `studentUserId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary 学習記録のリストを取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {number} [month] 月。
         * @param {string} [activity] 学習活動。
         * @param {string} [classId] クラスID。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listJournal(eecId: string, studentUserId: string, month?: number, activity?: string, classId?: string, schoolYear?: number, grade?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Journal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listJournal(eecId, studentUserId, month, activity, classId, schoolYear, grade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `studentUserId`と`journalId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習記録ファイルのリストを取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {string} [classId] クラスID。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listJournalFile(eecId: string, studentUserId: string, journalId: string, classId?: string, schoolYear?: number, grade?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JournalFile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listJournalFile(eecId, studentUserId, journalId, classId, schoolYear, grade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 数値評価教科のリストを取得する。
         * @param {number} schoolYear 年度。
         * @param {string} grade 学年。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {string} [name] 教科名。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNECurriculum(schoolYear: number, grade: string, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NECurriculum>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNECurriculum(schoolYear, grade, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 観点のリストを取得する。
         * @param {string} necId 
         * @param {string} [name] 観点名。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listViewPoint(necId: string, name?: string, schoolYear?: number, grade?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViewPoint>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listViewPoint(necId, name, schoolYear, grade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 学習活動を更新する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {string} activityId 
         * @param {ActivityWrite} [activityWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchActivity(eecId: string, month: string, activityId: string, activityWrite?: ActivityWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchActivity(eecId, month, activityId, activityWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成を更新する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {ContentWrite} [contentWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchContent(necId: string, viewPointId: string, contentId: string, contentWrite?: ContentWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Content>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchContent(necId, viewPointId, contentId, contentWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成月を更新する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {number} month 月
         * @param {ContentMonthWrite} [contentMonthWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchContentMonth(necId: string, viewPointId: string, contentId: string, month: number, contentMonthWrite?: ContentMonthWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentMonth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchContentMonth(necId, viewPointId, contentId, month, contentMonthWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 文書評価教科を更新する。
         * @param {string} eecId 
         * @param {EECurriculumWrite} [eECurriculumWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchEECurriculum(eecId: string, eECurriculumWrite?: EECurriculumWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EECurriculum>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchEECurriculum(eecId, eECurriculumWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 評価を更新する。
         * @param {string} necId 
         * @param {string} viewPointId 
         * @param {string} contentId 
         * @param {string} month 
         * @param {string} studentUserId 児童生徒ID
         * @param {EvaluationWrite} [evaluationWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchEvaluation(necId: string, viewPointId: string, contentId: string, month: string, studentUserId: string, evaluationWrite?: EvaluationWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evaluation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchEvaluation(necId, viewPointId, contentId, month, studentUserId, evaluationWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒   * `studentUserId`が自分であり、`teacherInputPublished`が`false`であるもののみ更新可能。   * 更新可能フィールドは、`month`, `activity`, `studentComment`。 * 保護者   * 更新不可能。 * 教師   * なんでも更新可能。
         * @summary 学習記録を更新する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {JournalWrite} [journalWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchJournal(eecId: string, studentUserId: string, journalId: string, journalWrite?: JournalWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Journal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchJournal(eecId, studentUserId, journalId, journalWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 数値評価教科を更新する。
         * @param {string} necId 
         * @param {NECurriculumWrite} [nECurriculumWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNECurriculum(necId: string, nECurriculumWrite?: NECurriculumWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NECurriculum>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNECurriculum(necId, nECurriculumWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 観点を更新する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {ViewPointWrite} [viewPointWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchViewPoint(necId: string, viewPointId: string, viewPointWrite?: ViewPointWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewPoint>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchViewPoint(necId, viewPointId, viewPointWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 学習活動を作成する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {ActivityWrite} [activityWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActivity(eecId: string, month: string, activityWrite?: ActivityWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActivity(eecId, month, activityWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成を作成する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {ContentWrite} [contentWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postContent(necId: string, viewPointId: string, contentWrite?: ContentWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postContent(necId, viewPointId, contentWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。
         * @summary 内容構成月を作成する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {ContentMonth} [contentMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postContentMonth(necId: string, viewPointId: string, contentId: string, contentMonth?: ContentMonth, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postContentMonth(necId, viewPointId, contentId, contentMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 文書評価教科を作成する。
         * @param {EECurriculumWrite} [eECurriculumWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEECurriculums(eECurriculumWrite?: EECurriculumWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EECurriculum>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEECurriculums(eECurriculumWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ作成可能。   * 保護者は、作成不可能。 * 教師   * なんでも作成可能。  # その他  * 作成時、教師入力値(`teacherComment`, `teacherInputPublished`)は指定できない。
         * @summary 学習記録を作成する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {JournalWrite} [journalWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJournal(eecId: string, studentUserId: string, journalWrite?: JournalWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Journal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJournal(eecId, studentUserId, journalWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒   * 児童生徒は、`studentUserId`が自分であるもののみ作成可能。 * 保護者   * 作成不可能。 * 教師   * なんでも作成可能。
         * @summary 学習記録ファイルを作成する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJournalFile(eecId: string, studentUserId: string, journalId: string, upfile: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJournalFile(eecId, studentUserId, journalId, upfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 数値評価教科を作成する。
         * @param {NECurriculumWrite} [nECurriculumWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNECurriculum(nECurriculumWrite?: NECurriculumWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NECurriculum>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNECurriculum(nECurriculumWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 観点を作成する。
         * @param {string} necId 
         * @param {ViewPointWrite} [viewPointWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postViewPoint(necId: string, viewPointWrite?: ViewPointWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewPoint>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postViewPoint(necId, viewPointWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 学習活動を削除する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivity(eecId: string, month: string, activityId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteActivity(eecId, month, activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成を削除する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContent(necId: string, viewPointId: string, contentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContent(necId, viewPointId, contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成月を削除する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {number} month 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentMonth(necId: string, viewPointId: string, contentId: string, month: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContentMonth(necId, viewPointId, contentId, month, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 文書評価教科を削除する。
         * @param {string} eecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEECurriculum(eecId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEECurriculum(eecId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒   * `studentUserId`が自分であり、`teacherInputPublished`が`false`であるもののみ削除可能。 * 保護者   * 削除不可能。 * 教師   * なんでも削除可能。
         * @summary 学習記録を削除する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournal(eecId: string, studentUserId: string, journalId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteJournal(eecId, studentUserId, journalId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒   * 児童生徒は、`studentUserId`が自分であるもののみ削除可能。 * 保護者   * 削除不可能。 * 教師   * なんでも削除可能。
         * @summary 学習記録ファイルを削除する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalFile(eecId: string, studentUserId: string, journalId: string, journalFileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteJournalFile(eecId, studentUserId, journalId, journalFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 数値評価教科を削除する。
         * @param {string} necId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNECurriculum(necId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNECurriculum(necId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 観点を削除する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteViewPoint(necId: string, viewPointId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteViewPoint(necId, viewPointId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習活動を取得する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity(eecId: string, month: string, activityId: string, options?: any): AxiosPromise<Activity> {
            return localVarFp.getActivity(eecId, month, activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 内容構成を取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContent(necId: string, viewPointId: string, contentId: string, options?: any): AxiosPromise<Content> {
            return localVarFp.getContent(necId, viewPointId, contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 内容構成月を取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {number} month 月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentMonth(necId: string, viewPointId: string, contentId: string, month: number, options?: any): AxiosPromise<ContentMonth> {
            return localVarFp.getContentMonth(necId, viewPointId, contentId, month, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 文章評価教科月を取得する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEECMonth(eecId: string, month: string, options?: any): AxiosPromise<EECMonth> {
            return localVarFp.getEECMonth(eecId, month, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 文書評価教科を取得する。
         * @param {string} eecId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEECurriculum(eecId: string, options?: any): AxiosPromise<EECurriculum> {
            return localVarFp.getEECurriculum(eecId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`rating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary 評価を取得する。
         * @param {string} necId 
         * @param {string} viewPointId 
         * @param {string} contentId 
         * @param {string} month 
         * @param {string} studentUserId 児童生徒ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluation(necId: string, viewPointId: string, contentId: string, month: string, studentUserId: string, options?: any): AxiosPromise<Evaluation> {
            return localVarFp.getEvaluation(necId, viewPointId, contentId, month, studentUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary 学習記録を取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournal(eecId: string, studentUserId: string, journalId: string, options?: any): AxiosPromise<Journal> {
            return localVarFp.getJournal(eecId, studentUserId, journalId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習記録ファイルを取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalFile(eecId: string, studentUserId: string, journalId: string, journalFileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getJournalFile(eecId, studentUserId, journalId, journalFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習記録児童生徒を取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalStudent(eecId: string, studentUserId: string, options?: any): AxiosPromise<JournalStudent> {
            return localVarFp.getJournalStudent(eecId, studentUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 数値評価教科を取得する。
         * @param {string} necId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNECurriculum(necId: string, options?: any): AxiosPromise<NECurriculum> {
            return localVarFp.getNECurriculum(necId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 観点を取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewPoint(necId: string, viewPointId: string, options?: any): AxiosPromise<ViewPoint> {
            return localVarFp.getViewPoint(necId, viewPointId, options).then((request) => request(axios, basePath));
        },
        /**
         * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `month`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習活動のリストを取得する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {boolean} [enabled] 有効化フラグ。
         * @param {string} [text] テキスト。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivity(eecId: string, month: string, enabled?: boolean, text?: string, schoolYear?: number, grade?: string, options?: any): AxiosPromise<Array<Activity>> {
            return localVarFp.listActivity(eecId, month, enabled, text, schoolYear, grade, options).then((request) => request(axios, basePath));
        },
        /**
         * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `viewPointId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 内容構成のリストを取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} [name] 内容構成名。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContent(necId: string, viewPointId: string, name?: string, schoolYear?: number, grade?: string, options?: any): AxiosPromise<Array<Content>> {
            return localVarFp.listContent(necId, viewPointId, name, schoolYear, grade, options).then((request) => request(axios, basePath));
        },
        /**
         * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `viewPointId`と`contentId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 内容構成月のリストを取得する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {number} [month] 月。
         * @param {boolean} [enabled] 有効化フラグ。
         * @param {string} [text] テキスト。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContentMonth(necId: string, viewPointId: string, contentId: string, month?: number, enabled?: boolean, text?: string, schoolYear?: number, grade?: string, options?: any): AxiosPromise<Array<ContentMonth>> {
            return localVarFp.listContentMonth(necId, viewPointId, contentId, month, enabled, text, schoolYear, grade, options).then((request) => request(axios, basePath));
        },
        /**
         * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 文章評価教科月のリストを取得する。
         * @param {string} eecId 
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEECMonth(eecId: string, schoolYear?: number, grade?: string, options?: any): AxiosPromise<Array<EECMonth>> {
            return localVarFp.listEECMonth(eecId, schoolYear, grade, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 文書評価教科のリストを取得する。
         * @param {number} schoolYear 年度。
         * @param {string} grade 学年。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {string} [name] 教科名。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEECurriculum(schoolYear: number, grade: string, name?: string, options?: any): AxiosPromise<Array<EECurriculum>> {
            return localVarFp.listEECurriculum(schoolYear, grade, name, options).then((request) => request(axios, basePath));
        },
        /**
         * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `viewPointId`と`contentId`と`month`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`rating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary 評価のリストを取得する。
         * @param {string} necId 
         * @param {string} viewPointId 
         * @param {string} contentId 
         * @param {string} month 
         * @param {string} [classId] クラスID。&#x60;classId&#x60;と&#x60;studentUserId&#x60;のいずれか片方は必須。
         * @param {string} [studentUserId] 児童生徒ID。&#x60;classId&#x60;と&#x60;studentUserId&#x60;のいずれか片方は必須。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvaluation(necId: string, viewPointId: string, contentId: string, month: string, classId?: string, studentUserId?: string, schoolYear?: number, grade?: string, options?: any): AxiosPromise<Array<Evaluation>> {
            return localVarFp.listEvaluation(necId, viewPointId, contentId, month, classId, studentUserId, schoolYear, grade, options).then((request) => request(axios, basePath));
        },
        /**
         * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `studentUserId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary 学習記録のリストを取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {number} [month] 月。
         * @param {string} [activity] 学習活動。
         * @param {string} [classId] クラスID。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJournal(eecId: string, studentUserId: string, month?: number, activity?: string, classId?: string, schoolYear?: number, grade?: string, options?: any): AxiosPromise<Array<Journal>> {
            return localVarFp.listJournal(eecId, studentUserId, month, activity, classId, schoolYear, grade, options).then((request) => request(axios, basePath));
        },
        /**
         * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `studentUserId`と`journalId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary 学習記録ファイルのリストを取得する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {string} [classId] クラスID。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJournalFile(eecId: string, studentUserId: string, journalId: string, classId?: string, schoolYear?: number, grade?: string, options?: any): AxiosPromise<Array<JournalFile>> {
            return localVarFp.listJournalFile(eecId, studentUserId, journalId, classId, schoolYear, grade, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 数値評価教科のリストを取得する。
         * @param {number} schoolYear 年度。
         * @param {string} grade 学年。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {string} [name] 教科名。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNECurriculum(schoolYear: number, grade: string, name?: string, options?: any): AxiosPromise<Array<NECurriculum>> {
            return localVarFp.listNECurriculum(schoolYear, grade, name, options).then((request) => request(axios, basePath));
        },
        /**
         * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
         * @summary 観点のリストを取得する。
         * @param {string} necId 
         * @param {string} [name] 観点名。
         * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
         * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listViewPoint(necId: string, name?: string, schoolYear?: number, grade?: string, options?: any): AxiosPromise<Array<ViewPoint>> {
            return localVarFp.listViewPoint(necId, name, schoolYear, grade, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 学習活動を更新する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {string} activityId 
         * @param {ActivityWrite} [activityWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchActivity(eecId: string, month: string, activityId: string, activityWrite?: ActivityWrite, options?: any): AxiosPromise<Activity> {
            return localVarFp.patchActivity(eecId, month, activityId, activityWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成を更新する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {ContentWrite} [contentWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContent(necId: string, viewPointId: string, contentId: string, contentWrite?: ContentWrite, options?: any): AxiosPromise<Content> {
            return localVarFp.patchContent(necId, viewPointId, contentId, contentWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成月を更新する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {number} month 月
         * @param {ContentMonthWrite} [contentMonthWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContentMonth(necId: string, viewPointId: string, contentId: string, month: number, contentMonthWrite?: ContentMonthWrite, options?: any): AxiosPromise<ContentMonth> {
            return localVarFp.patchContentMonth(necId, viewPointId, contentId, month, contentMonthWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 文書評価教科を更新する。
         * @param {string} eecId 
         * @param {EECurriculumWrite} [eECurriculumWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEECurriculum(eecId: string, eECurriculumWrite?: EECurriculumWrite, options?: any): AxiosPromise<EECurriculum> {
            return localVarFp.patchEECurriculum(eecId, eECurriculumWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 評価を更新する。
         * @param {string} necId 
         * @param {string} viewPointId 
         * @param {string} contentId 
         * @param {string} month 
         * @param {string} studentUserId 児童生徒ID
         * @param {EvaluationWrite} [evaluationWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEvaluation(necId: string, viewPointId: string, contentId: string, month: string, studentUserId: string, evaluationWrite?: EvaluationWrite, options?: any): AxiosPromise<Evaluation> {
            return localVarFp.patchEvaluation(necId, viewPointId, contentId, month, studentUserId, evaluationWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒   * `studentUserId`が自分であり、`teacherInputPublished`が`false`であるもののみ更新可能。   * 更新可能フィールドは、`month`, `activity`, `studentComment`。 * 保護者   * 更新不可能。 * 教師   * なんでも更新可能。
         * @summary 学習記録を更新する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {JournalWrite} [journalWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchJournal(eecId: string, studentUserId: string, journalId: string, journalWrite?: JournalWrite, options?: any): AxiosPromise<Journal> {
            return localVarFp.patchJournal(eecId, studentUserId, journalId, journalWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 数値評価教科を更新する。
         * @param {string} necId 
         * @param {NECurriculumWrite} [nECurriculumWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNECurriculum(necId: string, nECurriculumWrite?: NECurriculumWrite, options?: any): AxiosPromise<NECurriculum> {
            return localVarFp.patchNECurriculum(necId, nECurriculumWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 観点を更新する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {ViewPointWrite} [viewPointWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchViewPoint(necId: string, viewPointId: string, viewPointWrite?: ViewPointWrite, options?: any): AxiosPromise<ViewPoint> {
            return localVarFp.patchViewPoint(necId, viewPointId, viewPointWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 学習活動を作成する。
         * @param {string} eecId 
         * @param {string} month 
         * @param {ActivityWrite} [activityWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivity(eecId: string, month: string, activityWrite?: ActivityWrite, options?: any): AxiosPromise<Activity> {
            return localVarFp.postActivity(eecId, month, activityWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 内容構成を作成する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {ContentWrite} [contentWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContent(necId: string, viewPointId: string, contentWrite?: ContentWrite, options?: any): AxiosPromise<void> {
            return localVarFp.postContent(necId, viewPointId, contentWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。
         * @summary 内容構成月を作成する。
         * @param {string} necId 
         * @param {string} viewPointId 観点ID
         * @param {string} contentId 内容構成ID
         * @param {ContentMonth} [contentMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContentMonth(necId: string, viewPointId: string, contentId: string, contentMonth?: ContentMonth, options?: any): AxiosPromise<void> {
            return localVarFp.postContentMonth(necId, viewPointId, contentId, contentMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 文書評価教科を作成する。
         * @param {EECurriculumWrite} [eECurriculumWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEECurriculums(eECurriculumWrite?: EECurriculumWrite, options?: any): AxiosPromise<EECurriculum> {
            return localVarFp.postEECurriculums(eECurriculumWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ作成可能。   * 保護者は、作成不可能。 * 教師   * なんでも作成可能。  # その他  * 作成時、教師入力値(`teacherComment`, `teacherInputPublished`)は指定できない。
         * @summary 学習記録を作成する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {JournalWrite} [journalWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJournal(eecId: string, studentUserId: string, journalWrite?: JournalWrite, options?: any): AxiosPromise<Journal> {
            return localVarFp.postJournal(eecId, studentUserId, journalWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒   * 児童生徒は、`studentUserId`が自分であるもののみ作成可能。 * 保護者   * 作成不可能。 * 教師   * なんでも作成可能。
         * @summary 学習記録ファイルを作成する。
         * @param {string} eecId 
         * @param {string} studentUserId 
         * @param {string} journalId 
         * @param {any} upfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJournalFile(eecId: string, studentUserId: string, journalId: string, upfile: any, options?: any): AxiosPromise<JournalFile> {
            return localVarFp.postJournalFile(eecId, studentUserId, journalId, upfile, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 数値評価教科を作成する。
         * @param {NECurriculumWrite} [nECurriculumWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNECurriculum(nECurriculumWrite?: NECurriculumWrite, options?: any): AxiosPromise<NECurriculum> {
            return localVarFp.postNECurriculum(nECurriculumWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
         * @summary 観点を作成する。
         * @param {string} necId 
         * @param {ViewPointWrite} [viewPointWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postViewPoint(necId: string, viewPointWrite?: ViewPointWrite, options?: any): AxiosPromise<ViewPoint> {
            return localVarFp.postViewPoint(necId, viewPointWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 学習活動を削除する。
     * @param {string} eecId 
     * @param {string} month 
     * @param {string} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteActivity(eecId: string, month: string, activityId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteActivity(eecId, month, activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 内容構成を削除する。
     * @param {string} necId 
     * @param {string} viewPointId 観点ID
     * @param {string} contentId 内容構成ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteContent(necId: string, viewPointId: string, contentId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteContent(necId, viewPointId, contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 内容構成月を削除する。
     * @param {string} necId 
     * @param {string} viewPointId 観点ID
     * @param {string} contentId 内容構成ID
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteContentMonth(necId: string, viewPointId: string, contentId: string, month: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteContentMonth(necId, viewPointId, contentId, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 文書評価教科を削除する。
     * @param {string} eecId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteEECurriculum(eecId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteEECurriculum(eecId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒   * `studentUserId`が自分であり、`teacherInputPublished`が`false`であるもののみ削除可能。 * 保護者   * 削除不可能。 * 教師   * なんでも削除可能。
     * @summary 学習記録を削除する。
     * @param {string} eecId 
     * @param {string} studentUserId 
     * @param {string} journalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteJournal(eecId: string, studentUserId: string, journalId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteJournal(eecId, studentUserId, journalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒   * 児童生徒は、`studentUserId`が自分であるもののみ削除可能。 * 保護者   * 削除不可能。 * 教師   * なんでも削除可能。
     * @summary 学習記録ファイルを削除する。
     * @param {string} eecId 
     * @param {string} studentUserId 
     * @param {string} journalId 
     * @param {string} journalFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteJournalFile(eecId: string, studentUserId: string, journalId: string, journalFileId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteJournalFile(eecId, studentUserId, journalId, journalFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 数値評価教科を削除する。
     * @param {string} necId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteNECurriculum(necId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteNECurriculum(necId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 観点を削除する。
     * @param {string} necId 
     * @param {string} viewPointId 観点ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteViewPoint(necId: string, viewPointId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteViewPoint(necId, viewPointId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 学習活動を取得する。
     * @param {string} eecId 
     * @param {string} month 
     * @param {string} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getActivity(eecId: string, month: string, activityId: string, options?: any) {
        return DefaultApiFp(this.configuration).getActivity(eecId, month, activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 内容構成を取得する。
     * @param {string} necId 
     * @param {string} viewPointId 観点ID
     * @param {string} contentId 内容構成ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getContent(necId: string, viewPointId: string, contentId: string, options?: any) {
        return DefaultApiFp(this.configuration).getContent(necId, viewPointId, contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 内容構成月を取得する。
     * @param {string} necId 
     * @param {string} viewPointId 観点ID
     * @param {string} contentId 内容構成ID
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getContentMonth(necId: string, viewPointId: string, contentId: string, month: number, options?: any) {
        return DefaultApiFp(this.configuration).getContentMonth(necId, viewPointId, contentId, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 文章評価教科月を取得する。
     * @param {string} eecId 
     * @param {string} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEECMonth(eecId: string, month: string, options?: any) {
        return DefaultApiFp(this.configuration).getEECMonth(eecId, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 文書評価教科を取得する。
     * @param {string} eecId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEECurriculum(eecId: string, options?: any) {
        return DefaultApiFp(this.configuration).getEECurriculum(eecId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`rating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
     * @summary 評価を取得する。
     * @param {string} necId 
     * @param {string} viewPointId 
     * @param {string} contentId 
     * @param {string} month 
     * @param {string} studentUserId 児童生徒ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEvaluation(necId: string, viewPointId: string, contentId: string, month: string, studentUserId: string, options?: any) {
        return DefaultApiFp(this.configuration).getEvaluation(necId, viewPointId, contentId, month, studentUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
     * @summary 学習記録を取得する。
     * @param {string} eecId 
     * @param {string} studentUserId 
     * @param {string} journalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getJournal(eecId: string, studentUserId: string, journalId: string, options?: any) {
        return DefaultApiFp(this.configuration).getJournal(eecId, studentUserId, journalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
     * @summary 学習記録ファイルを取得する。
     * @param {string} eecId 
     * @param {string} studentUserId 
     * @param {string} journalId 
     * @param {string} journalFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getJournalFile(eecId: string, studentUserId: string, journalId: string, journalFileId: string, options?: any) {
        return DefaultApiFp(this.configuration).getJournalFile(eecId, studentUserId, journalId, journalFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
     * @summary 学習記録児童生徒を取得する。
     * @param {string} eecId 
     * @param {string} studentUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getJournalStudent(eecId: string, studentUserId: string, options?: any) {
        return DefaultApiFp(this.configuration).getJournalStudent(eecId, studentUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 数値評価教科を取得する。
     * @param {string} necId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNECurriculum(necId: string, options?: any) {
        return DefaultApiFp(this.configuration).getNECurriculum(necId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 観点を取得する。
     * @param {string} necId 
     * @param {string} viewPointId 観点ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getViewPoint(necId: string, viewPointId: string, options?: any) {
        return DefaultApiFp(this.configuration).getViewPoint(necId, viewPointId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `month`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 学習活動のリストを取得する。
     * @param {string} eecId 
     * @param {string} month 
     * @param {boolean} [enabled] 有効化フラグ。
     * @param {string} [text] テキスト。
     * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
     * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listActivity(eecId: string, month: string, enabled?: boolean, text?: string, schoolYear?: number, grade?: string, options?: any) {
        return DefaultApiFp(this.configuration).listActivity(eecId, month, enabled, text, schoolYear, grade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `viewPointId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 内容構成のリストを取得する。
     * @param {string} necId 
     * @param {string} viewPointId 観点ID
     * @param {string} [name] 内容構成名。
     * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
     * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listContent(necId: string, viewPointId: string, name?: string, schoolYear?: number, grade?: string, options?: any) {
        return DefaultApiFp(this.configuration).listContent(necId, viewPointId, name, schoolYear, grade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `viewPointId`と`contentId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 内容構成月のリストを取得する。
     * @param {string} necId 
     * @param {string} viewPointId 観点ID
     * @param {string} contentId 内容構成ID
     * @param {number} [month] 月。
     * @param {boolean} [enabled] 有効化フラグ。
     * @param {string} [text] テキスト。
     * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
     * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listContentMonth(necId: string, viewPointId: string, contentId: string, month?: number, enabled?: boolean, text?: string, schoolYear?: number, grade?: string, options?: any) {
        return DefaultApiFp(this.configuration).listContentMonth(necId, viewPointId, contentId, month, enabled, text, schoolYear, grade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 文章評価教科月のリストを取得する。
     * @param {string} eecId 
     * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
     * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listEECMonth(eecId: string, schoolYear?: number, grade?: string, options?: any) {
        return DefaultApiFp(this.configuration).listEECMonth(eecId, schoolYear, grade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 文書評価教科のリストを取得する。
     * @param {number} schoolYear 年度。
     * @param {string} grade 学年。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
     * @param {string} [name] 教科名。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listEECurriculum(schoolYear: number, grade: string, name?: string, options?: any) {
        return DefaultApiFp(this.configuration).listEECurriculum(schoolYear, grade, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `viewPointId`と`contentId`と`month`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`rating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
     * @summary 評価のリストを取得する。
     * @param {string} necId 
     * @param {string} viewPointId 
     * @param {string} contentId 
     * @param {string} month 
     * @param {string} [classId] クラスID。&#x60;classId&#x60;と&#x60;studentUserId&#x60;のいずれか片方は必須。
     * @param {string} [studentUserId] 児童生徒ID。&#x60;classId&#x60;と&#x60;studentUserId&#x60;のいずれか片方は必須。
     * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
     * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listEvaluation(necId: string, viewPointId: string, contentId: string, month: string, classId?: string, studentUserId?: string, schoolYear?: number, grade?: string, options?: any) {
        return DefaultApiFp(this.configuration).listEvaluation(necId, viewPointId, contentId, month, classId, studentUserId, schoolYear, grade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `studentUserId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
     * @summary 学習記録のリストを取得する。
     * @param {string} eecId 
     * @param {string} studentUserId 
     * @param {number} [month] 月。
     * @param {string} [activity] 学習活動。
     * @param {string} [classId] クラスID。
     * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
     * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listJournal(eecId: string, studentUserId: string, month?: number, activity?: string, classId?: string, schoolYear?: number, grade?: string, options?: any) {
        return DefaultApiFp(this.configuration).listJournal(eecId, studentUserId, month, activity, classId, schoolYear, grade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # パスパラメータ  * `eecId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。 * `studentUserId`と`journalId`は`-`を指定可能。  # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
     * @summary 学習記録ファイルのリストを取得する。
     * @param {string} eecId 
     * @param {string} studentUserId 
     * @param {string} journalId 
     * @param {string} [classId] クラスID。
     * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
     * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listJournalFile(eecId: string, studentUserId: string, journalId: string, classId?: string, schoolYear?: number, grade?: string, options?: any) {
        return DefaultApiFp(this.configuration).listJournalFile(eecId, studentUserId, journalId, classId, schoolYear, grade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 数値評価教科のリストを取得する。
     * @param {number} schoolYear 年度。
     * @param {string} grade 学年。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
     * @param {string} [name] 教科名。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listNECurriculum(schoolYear: number, grade: string, name?: string, options?: any) {
        return DefaultApiFp(this.configuration).listNECurriculum(schoolYear, grade, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # パスパラメータ  * `necId`は`-`を指定可能。指定した場合、クエリパラメータの`schoolYear`と`grade`の両方が必須。  # ユーザー別制約  * 児童生徒と保護者   * なんでも取得可能。 * 教師   * なんでも取得可能。
     * @summary 観点のリストを取得する。
     * @param {string} necId 
     * @param {string} [name] 観点名。
     * @param {number} [schoolYear] 年度。指定する場合、&#x60;grade&#x60;も必須。
     * @param {string} [grade] 学年。指定する場合、&#x60;schoolYear&#x60;も必須。&#x60;e1&#x60;, &#x60;e2&#x60;, ..., &#x60;e6&#x60;, &#x60;j1&#x60;, &#x60;j2&#x60;, &#x60;j3&#x60;のいずれか。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listViewPoint(necId: string, name?: string, schoolYear?: number, grade?: string, options?: any) {
        return DefaultApiFp(this.configuration).listViewPoint(necId, name, schoolYear, grade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 学習活動を更新する。
     * @param {string} eecId 
     * @param {string} month 
     * @param {string} activityId 
     * @param {ActivityWrite} [activityWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchActivity(eecId: string, month: string, activityId: string, activityWrite?: ActivityWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchActivity(eecId, month, activityId, activityWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 内容構成を更新する。
     * @param {string} necId 
     * @param {string} viewPointId 観点ID
     * @param {string} contentId 内容構成ID
     * @param {ContentWrite} [contentWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchContent(necId: string, viewPointId: string, contentId: string, contentWrite?: ContentWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchContent(necId, viewPointId, contentId, contentWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 内容構成月を更新する。
     * @param {string} necId 
     * @param {string} viewPointId 観点ID
     * @param {string} contentId 内容構成ID
     * @param {number} month 月
     * @param {ContentMonthWrite} [contentMonthWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchContentMonth(necId: string, viewPointId: string, contentId: string, month: number, contentMonthWrite?: ContentMonthWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchContentMonth(necId, viewPointId, contentId, month, contentMonthWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 文書評価教科を更新する。
     * @param {string} eecId 
     * @param {EECurriculumWrite} [eECurriculumWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchEECurriculum(eecId: string, eECurriculumWrite?: EECurriculumWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchEECurriculum(eecId, eECurriculumWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 評価を更新する。
     * @param {string} necId 
     * @param {string} viewPointId 
     * @param {string} contentId 
     * @param {string} month 
     * @param {string} studentUserId 児童生徒ID
     * @param {EvaluationWrite} [evaluationWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchEvaluation(necId: string, viewPointId: string, contentId: string, month: string, studentUserId: string, evaluationWrite?: EvaluationWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchEvaluation(necId, viewPointId, contentId, month, studentUserId, evaluationWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒   * `studentUserId`が自分であり、`teacherInputPublished`が`false`であるもののみ更新可能。   * 更新可能フィールドは、`month`, `activity`, `studentComment`。 * 保護者   * 更新不可能。 * 教師   * なんでも更新可能。
     * @summary 学習記録を更新する。
     * @param {string} eecId 
     * @param {string} studentUserId 
     * @param {string} journalId 
     * @param {JournalWrite} [journalWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchJournal(eecId: string, studentUserId: string, journalId: string, journalWrite?: JournalWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchJournal(eecId, studentUserId, journalId, journalWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 数値評価教科を更新する。
     * @param {string} necId 
     * @param {NECurriculumWrite} [nECurriculumWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchNECurriculum(necId: string, nECurriculumWrite?: NECurriculumWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchNECurriculum(necId, nECurriculumWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 観点を更新する。
     * @param {string} necId 
     * @param {string} viewPointId 観点ID
     * @param {ViewPointWrite} [viewPointWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchViewPoint(necId: string, viewPointId: string, viewPointWrite?: ViewPointWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchViewPoint(necId, viewPointId, viewPointWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 学習活動を作成する。
     * @param {string} eecId 
     * @param {string} month 
     * @param {ActivityWrite} [activityWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postActivity(eecId: string, month: string, activityWrite?: ActivityWrite, options?: any) {
        return DefaultApiFp(this.configuration).postActivity(eecId, month, activityWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 内容構成を作成する。
     * @param {string} necId 
     * @param {string} viewPointId 観点ID
     * @param {ContentWrite} [contentWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postContent(necId: string, viewPointId: string, contentWrite?: ContentWrite, options?: any) {
        return DefaultApiFp(this.configuration).postContent(necId, viewPointId, contentWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。
     * @summary 内容構成月を作成する。
     * @param {string} necId 
     * @param {string} viewPointId 観点ID
     * @param {string} contentId 内容構成ID
     * @param {ContentMonth} [contentMonth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postContentMonth(necId: string, viewPointId: string, contentId: string, contentMonth?: ContentMonth, options?: any) {
        return DefaultApiFp(this.configuration).postContentMonth(necId, viewPointId, contentId, contentMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 文書評価教科を作成する。
     * @param {EECurriculumWrite} [eECurriculumWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postEECurriculums(eECurriculumWrite?: EECurriculumWrite, options?: any) {
        return DefaultApiFp(this.configuration).postEECurriculums(eECurriculumWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ作成可能。   * 保護者は、作成不可能。 * 教師   * なんでも作成可能。  # その他  * 作成時、教師入力値(`teacherComment`, `teacherInputPublished`)は指定できない。
     * @summary 学習記録を作成する。
     * @param {string} eecId 
     * @param {string} studentUserId 
     * @param {JournalWrite} [journalWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postJournal(eecId: string, studentUserId: string, journalWrite?: JournalWrite, options?: any) {
        return DefaultApiFp(this.configuration).postJournal(eecId, studentUserId, journalWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒   * 児童生徒は、`studentUserId`が自分であるもののみ作成可能。 * 保護者   * 作成不可能。 * 教師   * なんでも作成可能。
     * @summary 学習記録ファイルを作成する。
     * @param {string} eecId 
     * @param {string} studentUserId 
     * @param {string} journalId 
     * @param {any} upfile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postJournalFile(eecId: string, studentUserId: string, journalId: string, upfile: any, options?: any) {
        return DefaultApiFp(this.configuration).postJournalFile(eecId, studentUserId, journalId, upfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 数値評価教科を作成する。
     * @param {NECurriculumWrite} [nECurriculumWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postNECurriculum(nECurriculumWrite?: NECurriculumWrite, options?: any) {
        return DefaultApiFp(this.configuration).postNECurriculum(nECurriculumWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可能。 * 教師   * 使用可能。
     * @summary 観点を作成する。
     * @param {string} necId 
     * @param {ViewPointWrite} [viewPointWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postViewPoint(necId: string, viewPointWrite?: ViewPointWrite, options?: any) {
        return DefaultApiFp(this.configuration).postViewPoint(necId, viewPointWrite, options).then((request) => request(this.axios, this.basePath));
    }
}


