export class ActionLog {
  readonly actionLogId: string;

  readonly studentUserId: string;
  readonly studentUserName: string;
  readonly studentPhotoUrl: string;

  readonly subjectUserType: string;
  readonly subjectUserId: string;
  readonly subjectUserName: string;
  readonly subjectUserPhotoUrl: string;

  readonly objectService: string;
  readonly objectResourceName: string;
  readonly updatedPropertyCategory: string | undefined;

  readonly data: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(
    actionLogId: string,
    studentUserId: string,
    studentUserName: string,
    studentIconUrl: string,
    subjectUserType: string,
    subjectUserId: string,
    subjectUserName: string,
    subjectUserPhotoUrl: string,
    objectService: string,
    objectResourceName: string,
    updatedPropertyCategory: string | undefined,
    data: string,
    createdAt: Date,
    updatedAt: Date
  ) {
    this.actionLogId = actionLogId;
    this.studentUserId = studentUserId;
    this.studentUserName = studentUserName;
    this.studentPhotoUrl = studentIconUrl;
    this.subjectUserType = subjectUserType;
    this.subjectUserId = subjectUserId;
    this.subjectUserName = subjectUserName;
    this.subjectUserPhotoUrl = subjectUserPhotoUrl;
    this.objectService = objectService;
    this.objectResourceName = objectResourceName;
    this.updatedPropertyCategory = updatedPropertyCategory;
    this.data = data;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
