






















import Vue, { PropType } from "vue";
import { Project } from "@/ts/objects/noneditable/project/Project";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";

export default Vue.extend({
  name: "ProjectAboutPure",
  components: { MessageView, LoadingBlock },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },

    project: { type: Object as PropType<Project | null> }
  }
});
