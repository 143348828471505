


























































import Vue, { PropType } from "vue";
import { format } from "date-fns";
import { BasicUserInfo } from "@/ts/objects/noneditable/value/BasicUserInfo";
import { ActivityLog } from "@/ts/objects/plain/ActivityLog";
import find from "lodash/find";

export default Vue.extend({
  name: "RecentActivityItem",
  props: {
    recentActivity: { type: Object as PropType<ActivityLog>, required: true },
    basicUserInfos: { type: Array as PropType<BasicUserInfo[]>, required: true }
  },
  computed: {
    dateText(): string {
      return format(this.recentActivity.createdAt, "MM/dd");
    },
    timeText(): string {
      return format(this.recentActivity.createdAt, "HH:mm");
    },
    userName() {
      const findUser = find(this.basicUserInfos, ["userId", this.recentActivity.userId]);
      return findUser?.name;
    },
    userIconUrl() {
      const findUser = find(this.basicUserInfos, ["userId", this.recentActivity.userId]);
      return findUser?.photoUrl;
    }
  },
  methods: {
    myListClick(studentUserId: string, uuid: string, mylisted: boolean) {
      this.$emit("myListClick", studentUserId, uuid, mylisted);
    },
    selectedRecentActivity(studentUserId: string, uuid: string, date: Date) {
      this.$emit("selectedRecentActivity", studentUserId, uuid, date);
    }
  }
});
