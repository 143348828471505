import { Grade } from "@/ts/objects/noneditable/value/Grade";
import { Curriculum } from "@/ts/objects/noneditable/curriculum/Curriculum";
import { MonthValue } from "@/ts/utils";

/**
 * 文書評価教科。
 *
 * リソース名は /eeCurriculums/{eecId}。
 *
 * EEC = Essay-Evaluated Curriculumの略とする。
 */
export class EECurriculum implements Curriculum {
  constructor(
    public readonly eecId: string,
    public readonly schoolYear: number,
    public readonly grade: Grade,
    public readonly name: string
  ) {}

  get resourceName(): string {
    return `/eeCurriculums/${this.eecId}`;
  }
}

export class EECurriculumTree {
  constructor(public readonly self: EECurriculum, public readonly months: EECMonthTree[]) {}
}

/**
 * 文書評価教科の月。
 *
 * リソース名は /eeCurriculums/{eecId}/months/{1, 2, ..., 12}。
 *
 * 文書評価教科 : 月 = 1 : 12
 */
export class EECMonth {
  constructor(public readonly eecId: string, public readonly month: MonthValue) {}

  get resourceName(): string {
    return `/eeCurriculums/${this.eecId}/months/${this.month}`;
  }
}

export class EECMonthTree {
  constructor(public readonly self: EECMonth, public readonly activities: EECActivityTree[]) {}
}

/**
 * 文書評価教科の学習活動。
 *
 * リソース名は /eeCurriculums/{eecId}/months/{1, 2, ..., 12}/activities/{eecActivityId}。
 *
 * 月 : 学習活動  = 1 : n
 */
export class EECActivity {
  constructor(
    public readonly eecId: string,
    public readonly month: MonthValue,
    public readonly activityId: string,
    public readonly enabled: boolean,
    public readonly text: string
  ) {}

  get resourceName(): string {
    return `/eeCurriculums/${this.eecId}/months/${this.month}/activities/${this.activityId}`;
  }
}

export class EECActivityTree {
  constructor(public readonly self: EECActivity) {}
}
