import log from "loglevel";
import Vue from "vue";
import { DebugConfig } from "@/ts/InitializeApp";
import { Err, InternalErr } from "@/ts/objects/Err";
import { UserRepository } from "@/ts/repositories/UserRepository";
import firebase from "firebase/app";
import "firebase/auth";
import { AppStateStore } from "@/store/AppStateStore";
import { GuardianState, StudentState, TeacherState, UserState } from "@/ts/objects/appuser/UserState";
import {
  ClassSchoolTypeEnum,
  UserInfo as UserInfoResp,
  UserInfoGuardian,
  UserInfoStudent,
  UserInfoTeacher
} from "@/ts/api/user-service";
import { Class, ClassStudent, SchoolType } from "@/ts/objects/noneditable/Class";
import { StudentInfo } from "@/ts/StudentInfo";
import { UserType } from "@/ts/objects/noneditable/value/UserType";
import { Grade, gradeFromUserServiceResp } from "@/ts/objects/noneditable/value/Grade";

/**
 * AuthServiceを取得する。
 * debugConfigがnullでなければ、デバッグモードと判断してモックを返す。
 */
export async function getAuthService(
  clientId: string | null,
  appStateStore: AppStateStore,
  debugConfig: DebugConfig | null
): Promise<AuthService> {
  if (debugConfig !== null) {
    return new AuthServiceMock(appStateStore, debugConfig.debugUserId, debugConfig.debugUserType);
  }

  if (clientId === null) throw new Error("getGapiAuthInstance: clientId must not be null.");

  try {
    await new Promise(resolve => window.gapi.load("auth2", resolve)); // gapi.auth2を使う前に、loadが必要。
    await new Promise((resolve, reject) => gapiAuth2InitWrapper(clientId, resolve, reject));
    log.debug("Initialized gapi.auth2.");

    return new AuthServiceImpl(appStateStore);
  } catch (e) {
    log.error(`getGapiAuthInstance: Failed to sign in to Google: name=${e.name}, message=${e.message}`);
    throw new Error(`getGapiAuthInstance: Failed to sign in to Google: name=${e.name}, message=${e.message}`);
  }
}

export abstract class AuthService {
  protected readonly appStateStore: AppStateStore;
  protected userRepository: UserRepository | null = null;

  protected constructor(appStateStore: AppStateStore) {
    this.appStateStore = appStateStore;
  }

  abstract signInToGoogle(forceReloadAuthResponse: boolean): Promise<string | null>;

  abstract changeGoogleAccount(): Promise<void>;

  abstract signInToFirebase(googleIdToken: string): Promise<boolean>;

  setUserRepository(userRepository: UserRepository) {
    this.userRepository = userRepository;
  }
}

class AuthServiceImpl extends AuthService {
  readonly authInstance: any;

  constructor(appStateStore: AppStateStore) {
    super(appStateStore);
    const authInstance = window.gapi.auth2.getAuthInstance();
    // TODO 次行のthisはちゃんと動くのか？
    authInstance.currentUser.listen(this.googleCurrentUserListener.bind(this));
    this.authInstance = authInstance;
  }

  async signInToGoogle(this: this, forceReloadAuthResponse: boolean): Promise<string | null> {
    try {
      const isSignedIn = this.authInstance.isSignedIn.get();
      const googleUser = isSignedIn ? this.authInstance.currentUser.get() : await this.authInstance.signIn();
      const googleIdToken = forceReloadAuthResponse
        ? googleUser.reloadAuthResponse().id_token
        : googleUser.getAuthResponse().id_token;
      const userId = googleUser.getId();

      log.debug(`Google auth completed.`);
      log.debug(`userId=${userId}`);
      log.debug(`googleIdToken=${googleIdToken}`);

      Vue.prototype.$googleIdToken = googleIdToken;
      await this.setAppUser(userId);

      return googleIdToken;
    } catch (e) {
      log.debug(`Failed to sign in to Google: name=${e.name}, message=${e.message}`);
      log.error(e);
      return null;
    }
  }

  async changeGoogleAccount(this: this) {
    log.debug(`AuthServiceImpl: changeGoogleAccount`);
    await this.authInstance.signIn({
      prompt: "select_account"
    });
  }

  async signInToFirebase(googleIdToken: string): Promise<boolean> {
    const userRepository = this.userRepository;
    if (userRepository === null)
      throw new Error("AuthServiceImpl.signInToFirebase: Invalid state: userRepository not initialized.");

    // Firebaseにログイン。
    log.debug(`firebase.auth.GoogleAuthProvider.credential : start`);
    const firebaseOAuthCredential = firebase.auth.GoogleAuthProvider.credential(googleIdToken);
    log.debug(`firebase.auth.GoogleAuthProvider.credential : end`);

    try {
      // ※ もしFirebaseにID登録されていなければ(初回起動時)、IDを自動的に作成してからログインする。
      const firebaseUserCredential = await firebase.auth().signInWithCredential(firebaseOAuthCredential);
      const firebaseIdToken = await firebaseUserCredential.user?.getIdToken(); // たぶん使わないが・・・。
      log.debug(`Firebase auth completed.`);
      log.debug(`firebaseIdToken=${firebaseIdToken}`);

      const updated = await userRepository.updateFirebaseUser();
      // FirebaseUser情報(customClaims)が更新されたらページリロードする（tokenを最新化するため）
      // 初回ログイン時はreloadが発生！
      if (updated) window.location.reload();

      return true;
    } catch (e) {
      log.error(`Failed to sign in to Firebase: name=${e.name}, message=${e.message}`);
      return false;
    }
  }

  private async googleCurrentUserListener(this: this, googleUser: any) {
    if (!googleUser) {
      log.debug(`GoogleUser logged out.`);

      await this.setAppUser(null);
      Vue.prototype.$googleIdToken = null;
      return;
    }

    log.debug(`GoogleUser updated.`);

    const googleIdToken = googleUser.getAuthResponse().id_token;
    const userId = googleUser.getId();

    log.debug(`userId=${userId}`);
    log.debug(`googleIdToken=${googleIdToken}`);

    Vue.prototype.$googleIdToken = googleIdToken;
    await this.setAppUser(userId);
  }

  private async setAppUser(this: this, userId: string | null) {
    const userRepository = this.userRepository;
    if (userRepository === null)
      throw new Error("AuthService.setAppUser: Invalid state: userRepository not initialized.");

    if (userId === null) {
      this.appStateStore.setAppUser(null);
      return;
    }

    const userInfo = await userRepository.getUserInfo(userId);
    if (userInfo instanceof Err) {
      log.error("AuthService.setAppUser: Could not fetch user information.");
      return;
    }
    const userState = await userInfoToUserState(this.appStateStore, userInfo);
    if (userState instanceof Err) {
      log.error("AuthService.setAppUser: Invalid user info.");
      return;
    }

    this.appStateStore.setAppUser(userState);

    log.debug(`AuthService.setAppUser: Completed!`);
  }
}

class AuthServiceMock extends AuthService {
  readonly debugUserId: string;
  readonly debugUserType: UserType;

  constructor(appStateStore: AppStateStore, debugUserId: string, debugUserType: UserType) {
    super(appStateStore);
    this.debugUserId = debugUserId;
    this.debugUserType = debugUserType;
  }

  async signInToGoogle(this: this, _forceReloadAuthResponse: boolean): Promise<string | null> {
    log.debug(`AuthServiceMock.signInToGoogle`);
    this.appStateStore.setAppUser(this.getDebugAppUserState());
    return "dummytoken";
  }

  async changeGoogleAccount(this: this) {
    log.debug(`AuthServiceMock.changeGoogleAccount: You cannot change the account in debug mode.`);
  }

  async signInToFirebase(_googleIdToken: string): Promise<boolean> {
    log.debug(`AuthServiceMock.signInToFirebase`);
    return true;
  }

  /**
   * デバッグ用ユーザーを取得する。
   */
  private getDebugAppUserState(): UserState {
    switch (this.debugUserType) {
      case "teacher":
        return new TeacherState(this.debugUserId, "先生 瀬太郎", "photourl", [
          new Class("class000", 2000, SchoolType.ElementarySchool, 1, "A", 0, [
            new ClassStudent("student000", 2, "瀬戸 空治郎", "iconurl"),
            new ClassStudent("student001", 1, "山口 美津枝", "iconurl"),
            new ClassStudent("student002", 3, "瀬戸瀬戸 空治郎空治郎", "iconurl"),
            new ClassStudent("student003", 24, "瀬戸 空治郎2", "iconurl")
          ]),
          new Class("class001", 1999, SchoolType.ElementarySchool, 1, "B", 1, [
            new ClassStudent("student000", 2, "瀬戸 空治郎", "iconurl"),
            new ClassStudent("student001", 1, "山口 美津枝", "iconurl"),
            new ClassStudent("student002", 3, "瀬戸瀬戸 空治郎空治郎", "iconurl"),
            new ClassStudent("student003", 24, "瀬戸 空治郎2", "iconurl")
          ]),
          new Class("class002", 2000, SchoolType.JuniorHighSchool, 2, "C", 2, [
            new ClassStudent("student000", 2, "瀬戸 空治郎", "iconurl"),
            new ClassStudent("student001", 1, "山口 美津枝", "iconurl"),
            new ClassStudent("student002", 3, "瀬戸瀬戸 空治郎空治郎", "iconurl"),
            new ClassStudent("student003", 24, "瀬戸 空治郎2", "iconurl")
          ])
        ]);
      case "student":
        return new StudentState(this.debugUserId, "瀬戸 空治郎", "dummyurl", [
          {
            classId: "class000",
            schoolYear: 2000,
            grade: new Grade("e1"),
            className: "A",
            studentNumber: 10
          },
          {
            classId: "class001",
            schoolYear: 2001,
            grade: new Grade("e2"),
            className: "B",
            studentNumber: 15
          }
        ]);
      case "guardian":
        return new GuardianState(this.debugUserId, [
          new StudentInfo("student000", "瀬戸 空治郎", "iconUrl", [
            {
              classId: "class000",
              schoolYear: 2000,
              grade: new Grade("e2"),
              className: "D",
              studentNumber: 10
            },
            {
              classId: "class001",
              schoolYear: 2001,
              grade: new Grade("e3"),
              className: "A",
              studentNumber: 15
            }
          ]),
          new StudentInfo("student001", "山口 美津枝", "iconUrl", [
            {
              classId: "class000",
              schoolYear: 2000,
              grade: new Grade("e2"),
              className: "B",
              studentNumber: 1
            },
            {
              classId: "class002",
              schoolYear: 2001,
              grade: new Grade("e3"),
              className: "A",
              studentNumber: 2
            }
          ])
        ]);
      default:
        throw new Error("UserRepositoryMock: Unreachable.");
    }
  }
}

// gapi.auth2.initが返すのはPromiseではない。古いライブラリなので・・・。
// 参照: https://stackoverflow.com/questions/46720597/javascript-async-function-that-calls-and-waits-for-google-auth
// よって、ラッパーを作る。
function gapiAuth2InitWrapper(clientId: string, onSucceed: (value: unknown) => void, onFail: (err: any) => void) {
  window.gapi.auth2
    .init({
      client_id: clientId,
      fetch_basic_profile: true,
      hosted_domain: "seto-solan.ed.jp", // この制限はUI上のものでしかない。
      ux_mode: "redirect",
      redirect_uri: `https://${window.location.host}`
    })
    .then(onSucceed)
    .catch(onFail);
}

async function userInfoToUserState(appStateStore: AppStateStore, userInfoResp: UserInfoResp): Promise<UserState | Err> {
  if (userInfoResp.userType === "teacher") {
    const details = userInfoResp.details as UserInfoTeacher;
    const teacher = new TeacherState(
      userInfoResp.details.id,
      details.name,
      details.photoUrl,
      details.classes.map(cls => {
        const schoolType =
          cls.schoolType === ClassSchoolTypeEnum.Primary ? SchoolType.ElementarySchool : SchoolType.JuniorHighSchool;
        return new Class(cls.id, cls.year, schoolType, cls.grade, cls.nickName, cls.classNo);
      })
    );
    // 選択中のクラスを再選択する。
    teacher.selectClass(appStateStore.teacherState?.selectedClass()?.id ?? "");
    return teacher;
  } else if (userInfoResp.userType === "student") {
    const details = userInfoResp.details as UserInfoStudent;
    const studentClasses = details.classes.map(cls => {
      return {
        classId: cls.id,
        schoolYear: cls.year,
        grade: gradeFromUserServiceResp(cls),
        className: cls.nickName,
        studentNumber: cls.studentNumber
      };
    });
    return new StudentState(userInfoResp.details.id, details.name, details.photoUrl, studentClasses);
  } else if (userInfoResp.userType === "guardian") {
    const details = userInfoResp.details as UserInfoGuardian;
    const students = details.students.map(student => {
      const studentClasses = student.classes.map(cls => {
        return {
          classId: cls.id,
          schoolYear: cls.year,
          grade: gradeFromUserServiceResp(cls),
          className: cls.nickName,
          studentNumber: cls.studentNumber
        };
      });
      return new StudentInfo(student.id, student.name, student.photoUrl, studentClasses);
    });
    const guardian = new GuardianState(userInfoResp.details.id, students);
    // 選択中の児童生徒を再選択する。
    guardian.selectStudent(appStateStore.guardianState?.studentUserId() ?? "");
    return guardian;
  } else {
    log.info(`UserRepositoryImpl: Invalid userType: ${userInfoResp.userType}`);
    return new InternalErr(`UserRepositoryImpl: Invalid userType: ${userInfoResp.userType}`);
  }
}
