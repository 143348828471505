



























































































































































































import Vue, { PropType } from "vue";
import ToggleButton from "@/components/ToggleButton.vue";
import AutoResizeTextarea from "@/components/AutoResizeTextarea.vue";
import { NavigationGuardNext, Route } from "vue-router";
import { ProjectStore } from "@/store/ProjectStore";
import { Err } from "@/ts/objects/Err";
import { EditableProject } from "@/ts/objects/editable/project/EditableProject";
import { EditableProjectRubric } from "@/ts/objects/editable/project/EditableProjectRubric";
import { LocalEditableProjectRubric } from "@/ts/objects/editable/project/LocalEditableProjectRubric";
import PopupMenuButton, { MenuButton, MenuItem, Separator } from "@/components/PopupMenuButton.vue";
import { messages } from "@/ts/const/Messages";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { ProjectInfo, ProjectInfoOnEditStore } from "@/store/ProjectInfoOnEditStore";
import { SaveResult } from "@/ts/objects/editable/SaveResult";
import { AppStateStore } from "@/store/AppStateStore";
import TipBlock from "@/components/TipBlock.vue";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";

export default Vue.extend({
  name: "ProjectAboutT",
  components: { TipBlock, LoadingBlock, MessageView, ToggleButton, AutoResizeTextarea, PopupMenuButton },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    projectStore: { type: Object as PropType<ProjectStore>, required: true },
    projectInfoOnEditStore: { type: Object as PropType<ProjectInfoOnEditStore>, required: true },
    projectRepository: { type: Object as PropType<ProjectRepository>, required: true }
  },
  created() {
    this.pageLeaveService = new PageLeaveService(
      async () => {
        // 編集中でなければ、セーブ漏れを拾うために念のため再セーブする。
        // 編集中なら、キャンセルのためにleaveしようとしている可能性があるため、セーブしない。
        if (!this.editing) {
          await this.saveAllAndReloadProjectsIfNeeded();
        }
      },
      async () => !this.needSave,
      0
    );

    this.projectStore.project.getDataWithTimeout().then(project => {
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectProject };
        return;
      }

      Promise.all([
        this.projectRepository.getEditableProject(project.projectId, true),
        this.projectRepository.listEditableProjectRubrics(project.projectId, true)
      ]).then(([projectResp, rubricResp]) => {
        if (projectResp instanceof Err || rubricResp instanceof Err) {
          log.debug("Error loading project or rubrics!");
          this.messageView = { message: messages.failedToLoadData, fadeIn: true };
          return;
        }

        this.project = projectResp;
        this.rubrics = rubricResp;
      });
    });
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    this.projectInfoOnEditStore.finishEditing();
  },
  data(): {
    messageView: MessageViewParam | null;

    editing: boolean;
    project: EditableProject | null;
    rubrics: (EditableProjectRubric | LocalEditableProjectRubric)[];
    rubricPopupMenuItems: MenuItem[];

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      messageView: null,

      editing: false,
      project: null,
      rubrics: [],

      rubricPopupMenuItems: [
        new MenuButton("up", "上へ", ["fas", "arrow-up"]),
        new MenuButton("down", "下へ", ["fas", "arrow-down"]),
        new Separator(),
        new MenuButton("delete", "削除", ["fas", "trash-alt"])
      ],

      pageLeaveService: null
    };
  },
  computed: {
    isProjectPublished(): boolean {
      return this.project?.published ?? false;
    },
    infoMessage(): string {
      if (this.isProjectPublished) return "変更は編集終了時に保存されます。";
      return "このプロジェクトは非公開です。公開後も設定を変更できます。";
    },
    needSave(): boolean {
      const project = this.project;
      if (project === null) return false;
      if (project.needSave()) return true;

      return this.rubrics.some((r: EditableProjectRubric | LocalEditableProjectRubric) => {
        if (r instanceof LocalEditableProjectRubric) return true;
        return r.needSave();
      });
    },
    headerProjectInfo(): ProjectInfo | null {
      return this.projectInfoOnEditStore.info;
    }
  },
  watch: {
    async editing(editing: boolean) {
      log.debug(`watch editing triggered: ${editing}`);
      const project = this.project;
      if (editing && project !== null) {
        this.projectInfoOnEditStore.startEditing({
          name: project.name,
          startingYear: project.startingYear,
          startingMonth: project.startingMonth,
          endingYear: project.endingYear,
          endingMonth: project.endingMonth
        });
      } else {
        this.projectInfoOnEditStore.finishEditing();
        this.saveAllAndReloadProjectsIfNeeded();
      }
    },
    headerProjectInfo: {
      handler: function(info: ProjectInfo | null) {
        const project = this.project;
        if (info === null || project === null) return;

        // プロジェクトヘッダ部分の編集内容を反映。
        project.name = info.name;
        project.startingYear = info.startingYear;
        project.startingMonth = info.startingMonth;
        project.endingYear = info.endingYear;
        project.endingMonth = info.endingMonth;
      },
      deep: true
    }
  },
  methods: {
    async saveAllAndReloadProjectsIfNeeded(): Promise<void> {
      const needProjectsReload = await this.saveAll();
      if (!needProjectsReload) return;
      const classId = this.appStateStore.teacherState?.selectedClass()?.id;
      if (classId === undefined) return;
      await this.projectStore.reloadProjects({ projectRepository: this.projectRepository, classIds: [classId] });
    },
    async saveAll(): Promise<boolean> {
      // TODO ちゃんとセーブできたかチェックして、セーブできてなければデータが消えるという警告を出す。
      log.debug("SAVING!");

      const project: EditableProject | null = this.project;
      if (project === null) return false;

      // ルーブリックのセーブ。
      const promises = this.rubrics.map((rubric: EditableProjectRubric | LocalEditableProjectRubric) => {
        if (rubric instanceof EditableProjectRubric) {
          rubric.saveAllChanges(true);
          return Promise.resolve(rubric);
        } else {
          return rubric.save().then(resp => {
            if (resp instanceof Err) return rubric;
            return resp;
          });
        }
      });
      this.rubrics = await Promise.all(promises);
      log.debug(`AboutT: rubrics updated to ${JSON.stringify(this.rubrics)}`);

      // ルーブリックの追加・削除・並び順を反映するために、セーブ済のルーブリック一覧でproject.rubricを上書きする。
      // まだLocalEditableProjectRubricであるrubricは、セーブ失敗したということなので、この一覧には含めない。
      project.rubrics = this.rubrics
        .filter((r): r is EditableProjectRubric => r instanceof EditableProjectRubric)
        .map(r => r.self);
      const projectSaveResult: SaveResult = await project.saveAllChanges(true);
      const needProjectsReload = projectSaveResult.didSave || projectSaveResult.someSkipped;
      return needProjectsReload;
    },
    addRubric() {
      log.debug("ADD RUBRIC!");
      const project: EditableProject | null = this.project;
      if (project === null) return;
      this.rubrics.push(new LocalEditableProjectRubric(this.projectRepository, project.projectId));
    },
    async deleteRubric(rubricIdx: number) {
      log.debug("DELETE RUBRIC!");

      const deletingRubric = this.rubrics[rubricIdx];

      // まだ実際に作成していないルーブリックの削除
      if (deletingRubric instanceof LocalEditableProjectRubric) {
        this.rubrics.splice(rubricIdx, 1);
        return;
      }

      // 実際に作成済のルーブリックの削除
      const warning =
        "本ルーブリックに紐づく、児童生徒の学習の記録もすべて削除されますが、よろしいですか？削除はすぐに反映されます。";
      if (!window.confirm(warning)) {
        return;
      }

      const resp = await this.projectRepository.deleteRubric(deletingRubric.projectId, deletingRubric.rubricId);
      if (resp instanceof Err) {
        log.debug("Failed to delete rubric.");
        return;
      }
      this.rubrics = this.rubrics.filter(r => r !== deletingRubric);
    },
    onRubricPopupMenuClicked(menuKey: string, rubricIdx: number) {
      log.debug(`onRubricPopupMenuClicked: ${menuKey}, ${rubricIdx}`);
      switch (menuKey) {
        case "up":
          if (rubricIdx >= 1) {
            const selectedRubric = this.rubrics[rubricIdx];
            const swappedWith = this.rubrics[rubricIdx - 1];
            this.rubrics.splice(rubricIdx - 1, 2, selectedRubric, swappedWith);
          }
          return;
        case "down":
          if (rubricIdx <= this.rubrics.length - 2) {
            const selectedRubric = this.rubrics[rubricIdx];
            const swappedWith = this.rubrics[rubricIdx + 1];
            this.rubrics.splice(rubricIdx, 2, swappedWith, selectedRubric);
          }
          return;
        case "delete":
          this.deleteRubric(rubricIdx);
          return;
      }
    },
    async deleteProject() {
      if (!this.editing) return;

      const project = this.project;
      if (project === null) return;

      const classId = this.appStateStore.teacherState?.selectedClass()?.id;
      if (classId === undefined) {
        return;
      }

      if (!window.confirm(`プロジェクト ${project.name} を削除してよろしいですか？`)) return;

      const resp = await this.projectRepository.deleteProject(project.projectId);
      if (resp instanceof Err) return; // TODO エラー処理

      this.projectInfoOnEditStore.finishEditing();

      await this.projectStore.reloadProjects({ projectRepository: this.projectRepository, classIds: [classId] });
      await this.$router.push(this.projectStore.teacherInitPath).catch(() => {});
    },
    async publishProject() {
      if (!this.editing) return;

      const project = this.project;
      if (project === null) return;

      if (
        !window.confirm(
          `一旦公開すると、非公開に戻すことも、削除することもできません。プロジェクト ${project.name} を公開してよろしいですか？`
        )
      )
        return;

      project.published = true;

      await this.saveAllAndReloadProjectsIfNeeded();
    }
  }
});
