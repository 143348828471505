import { getModule, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import { store } from "@/store/Store";
import {
  UserState,
  TeacherState,
  StudentOrGuardianState,
  GuardianState,
  StudentState
} from "@/ts/objects/appuser/UserState";
import { Quarter } from "@/ts/objects/noneditable/value/Quarter";
import log from "loglevel";
import clamp from "lodash/clamp";
import { CurriculumTabName } from "@/ts/objects/enum/CurriculumTabName";
import { formatDate } from "@/ts/objects/noneditable/FormattedDatetime";

export type RouteState = {
  readonly path: string;
  readonly params: Record<string, string>;
  readonly query: Record<string, string>;
};

@Module({ dynamic: true, store, name: "appStateStore", namespaced: true })
export class AppStateStore extends VuexModule {
  userState: UserState | null = null;

  currentQuarter = new Quarter(1970, 1);

  now = formatDate(new Date());

  @MutationAction({ mutate: ["now"] })
  async updateNow() {
    return { now: formatDate(new Date()) };
  }

  /**
   * 現在のURLのパス、パラメータ、クエリパラメータを返す。
   */
  get route(): RouteState {
    const routeModule = (store.state as any).route;
    const routeState = {
      path: routeModule.path,
      params: routeModule.params,
      query: routeModule.query
    };
    log.debug(`routeState=${JSON.stringify(routeState)}`);
    return routeState;
  }

  get isAppUserTeacher(): boolean {
    return this.userState?.isTeacher ?? false;
  }

  get isAppUserStudent(): boolean {
    return this.userState?.isStudent ?? false;
  }

  get isAppUserGuardian(): boolean {
    return this.userState?.isGuardian ?? false;
  }

  get teacherState(): TeacherState | null {
    const userState = this.userState;
    if (!(userState instanceof TeacherState)) return null;
    return userState;
  }

  get studentOrGuardianState(): StudentOrGuardianState | null {
    const userState = this.userState;
    if (!(userState instanceof StudentOrGuardianState)) return null; // TODO うまくいく？
    return userState;
  }

  get guardianState(): GuardianState | null {
    const userState = this.userState;
    if (!(userState instanceof GuardianState)) return null;
    return userState;
  }

  get teacherBasePath(): string {
    const userState = this.userState;
    if (userState instanceof TeacherState) {
      const selectedClassId = userState.selectedClass()?.id;
      return selectedClassId === undefined ? "/t" : `/t/${selectedClassId}`;
    } else {
      return "";
    }
  }

  get studentOrGuardianBasePath(): string {
    const userState = this.userState;
    if (userState instanceof StudentOrGuardianState) {
      const studentUserId = userState.studentUserId() ?? "-";
      return `/s/${studentUserId}`;
    } else {
      return "";
    }
  }

  get studentOrGuardianCurriculumDefaultPath(): string {
    const userState = this.userState;
    if (userState instanceof StudentOrGuardianState) {
      const schoolYear = clamp(
        this.currentQuarter.schoolYear,
        userState.studentMinSchoolYear() ?? 1,
        userState.studentMaxSchoolYear() ?? 9999
      );
      const tab: CurriculumTabName = userState.isStudent ? "write" : "read";

      return `${this.studentOrGuardianBasePath}/curriculum/${schoolYear}/${tab}/-/-/-/-`;
    } else {
      return "";
    }
  }

  get needRuby(): boolean {
    const userState = this.userState;
    if (!(userState instanceof StudentState)) return false;

    const grade = userState.studentInfo()?.classOfSchoolYear(this.currentQuarter.schoolYear)?.grade;
    return grade !== undefined && grade.intValue <= 3;
  }

  @Mutation
  public setUserId(userId: string) {
    const userState = this.userState;
    if (userState === null) return;
    userState.userId = userId;
  }

  @Mutation
  public setAppUser(user: UserState | null) {
    this.userState = user;
  }

  @Mutation
  public setCurrentQuarter(value: Quarter) {
    this.currentQuarter = value;
  }
}

/**
 * AppStateStoreのグローバル変数。
 *
 * - 他のストアからの（タイプセーフな）アクセスを可能にするには、恐らくこの方法しかない。
 * - TypeScriptにはパッケージプライベートにあたるものが無い。
 *
 * というような理由により、グローバル変数として公開せざるを得ない。
 * が、本当に必要な場合以外は、コンポーネントプロパティとして受け渡した値からアクセスするようにすること。
 */
export const appStateStoreGlobal = getModule(AppStateStore);
