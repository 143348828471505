





























import Vue, { PropType } from "vue";
import { NECContentTree, NECViewPointTree } from "@/ts/objects/noneditable/curriculum/NECurriculum";
import { Loadable } from "@/ts/Loadable";
import { NECEvaluation } from "@/ts/objects/noneditable/curriculum/NECEvaluation";

export default Vue.extend({
  name: "CurriculumReadNECMonthlyViewPointRow",
  props: {
    /**
     * 月。
     */
    month: { type: Number as PropType<number | null>, required: true },

    /**
     * 数値評価教科の観点。
     */
    viewPointTree: { type: Object as PropType<NECViewPointTree>, required: true },

    /**
     * 現在表示中の教科・児童生徒配下の評価。
     */
    evaluations: { type: Object as PropType<Loadable<NECEvaluation[]>>, required: true }
  },
  computed: {
    /**
     * この月、有効化されている内容構成のリスト。
     */
    enabledContents(): NECContentTree[] {
      return this.viewPointTree.contents.filter(c => c.getMonth(this.month)?.enabled === true);
    }
  },
  methods: {
    evaluationOf(viewPointId: string, contentId: string): NECEvaluation | null {
      return this.evaluations.data?.find(e => e.viewPointId === viewPointId && e.contentId === contentId) ?? null;
    }
  }
});
