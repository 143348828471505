
























































































































































import Vue, { PropType } from "vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import firebase from "firebase/app";
import "firebase/storage";
import { EditableSolanJournal } from "@/ts/objects/editable/solan/EditableSolanJournal";
import { solanProcessTextOf } from "@/ts/utils/AppUtil";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import JournalFilePreviewArea from "@/components/journalfile/JournalFilePreviewArea/JournalFilePreviewArea.vue";
import { JournalFile } from "@/ts/objects/noneditable/JournalFile";
import JournalFilesView from "@/components/journalfile/JournalFilesView.vue";

export default Vue.extend({
  name: "SolanJournalsPure",
  components: {
    JournalFilePreviewArea,
    LoadingBlock,
    MessageView,
    DisableableAutoResizeTextarea,
    JournalFilesView
  },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },

    storage: { type: Object as PropType<firebase.storage.Storage | null> },

    journals: { type: Array as PropType<EditableSolanJournal[] | null> },

    filesViewJournal: { type: Object as PropType<EditableSolanJournal | null> },

    reloadData: { type: Function },
    saveAll: { type: Function },
    setFilesView: { type: Function },
    isMyFilesViewOpen: { type: Function },

    /**
     * 学習記録ファイルをアップロードする関数。
     */
    uploadJournalFile: {
      type: Function as PropType<(file: File, journal: EditableSolanJournal) => Promise<void>>,
      required: true
    },

    /**
     * 学習記録ファイルを削除する関数。
     */
    deleteJournalFile: {
      type: Function as PropType<(file: JournalFile, journal: EditableSolanJournal) => Promise<void>>,
      required: true
    }
  },
  data(): {
    process0FilesViewColor: string;
    process1FilesViewColor: string;
    process2FilesViewColor: string;
    process3FilesViewColor: string;
  } {
    return {
      process0FilesViewColor: "#ced0ff",
      process1FilesViewColor: "#b2f38b",
      process2FilesViewColor: "#f5d467",
      process3FilesViewColor: "#ffb4b4"
    };
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--process-0-files-view-color": this.process0FilesViewColor,
        "--process-1-files-view-color": this.process1FilesViewColor,
        "--process-2-files-view-color": this.process2FilesViewColor,
        "--process-3-files-view-color": this.process3FilesViewColor
      };
    },
    needSave(): boolean {
      const journals = this.journals;
      if (journals === null) return false;
      return journals.some(j => j.needSave());
    },
    reversedJournals(): EditableSolanJournal[] | null {
      const journals = this.journals;
      if (journals === null) return null;
      return journals.slice().reverse();
    }
  },
  methods: {
    filesViewColorFor(process: number): string {
      switch (process) {
        case 0:
          return this.process0FilesViewColor;
        case 1:
          return this.process1FilesViewColor;
        case 2:
          return this.process2FilesViewColor;
        case 3:
          return this.process3FilesViewColor;
        default:
          return "#ffffff";
      }
    },
    _solanProcessTextOf(process: number): string {
      return solanProcessTextOf(process);
    }
  }
});
