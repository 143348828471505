import { EditableObject } from "@/ts/objects/editable/EditableObject";
import { EditableSolanJournal } from "@/ts/objects/editable/solan/EditableSolanJournal";
import { EditableSolanLookback } from "@/ts/objects/editable/solan/EditableSolanLookback";
import { SolanProject } from "@/ts/objects/noneditable/solan/SolanProject";

export class EditableSolanStudent extends EditableObject {
  readonly studentUserId: string;
  readonly studentNumber: number;
  readonly name: string;
  readonly projects: EditableSolanStudentProject[];

  protected allEditables() {
    return this.projects;
  }

  constructor(studentUserId: string, studentNumber: number, name: string, projects: EditableSolanStudentProject[]) {
    super();
    this.studentUserId = studentUserId;
    this.studentNumber = studentNumber;
    this.name = name;
    this.projects = projects;
  }
}

export class EditableSolanStudentProject extends EditableObject {
  readonly project: SolanProject;
  readonly journals: EditableSolanJournal[];
  readonly lookback: EditableSolanLookback;

  protected allEditables() {
    return [...this.journals, this.lookback];
  }

  constructor(project: SolanProject, journals: EditableSolanJournal[], lookback: EditableSolanLookback) {
    super();
    this.project = project;
    this.journals = journals;
    this.lookback = lookback;
  }
}
