import { EditableObject } from "@/ts/objects/editable/EditableObject";
import { EditableBoolean, EditableHashedString } from "@/ts/objects/editable/value/EditablePrimitiveValue";
import { EditableYearMonth } from "@/ts/objects/editable/value/EditableYearMonth";
import { EditableStringArray } from "@/ts/objects/editable/value/EditableArray";
import { projectRnameToId } from "@/ts/utils/AppUtil";
import { Quarter } from "@/ts/objects/noneditable/value/Quarter";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";
import { Err } from "@/ts/objects/Err";

export class EditableProject extends EditableObject {
  readonly self: string;
  readonly projectId: string;
  readonly classId: string;
  readonly quarter: Quarter;
  readonly _name: EditableHashedString;
  readonly _description: EditableHashedString;
  readonly _relatedSyllabus: EditableHashedString;
  readonly _viewPointSEnabled: EditableBoolean;
  readonly _viewPointAEnabled: EditableBoolean;
  readonly _viewPointBEnabled: EditableBoolean;
  readonly _viewPointCEnabled: EditableBoolean;
  readonly _startingMonth: EditableYearMonth;
  readonly _endingMonth: EditableYearMonth;
  readonly _published: EditableBoolean;
  readonly _completed: EditableBoolean;
  readonly _rubrics: EditableStringArray;

  constructor(
    repo: ProjectRepository,
    savable: boolean,
    self: string,
    classId: string,
    quarter: Quarter,
    name: string,
    nameHash: string,
    description: string,
    descriptionHash: string,
    relatedSyllabus: string,
    relatedSyllabusHash: string,
    viewPointSEnabled: boolean,
    viewPointAEnabled: boolean,
    viewPointBEnabled: boolean,
    viewPointCEnabled: boolean,
    startingYear: number,
    startingMonth: number,
    endingYear: number,
    endingMonth: number,
    published: boolean,
    completed: boolean,
    rubrics: string[]
  ) {
    super();
    this.self = self;
    const projectId = projectRnameToId(self) ?? "unknown"; // TODO これどうにかする？
    this.projectId = projectId;
    this.classId = classId;
    this.quarter = quarter;
    this._name = new EditableHashedString("name", name, nameHash, savable, async (value, hash) => {
      const resp = await repo.patchProject(projectId, { name: { value, hash } });
      if (resp instanceof Err) return resp;
      return [resp.name.value, resp.name.hash];
    });
    this._description = new EditableHashedString(
      "description",
      description,
      descriptionHash,
      savable,
      async (value, hash) => {
        const resp = await repo.patchProject(projectId, { description: { value, hash } });
        if (resp instanceof Err) return resp;
        return [resp.description.value, resp.description.hash];
      }
    );
    this._relatedSyllabus = new EditableHashedString(
      "relatedSyllabus",
      relatedSyllabus,
      relatedSyllabusHash,
      savable,
      async (value, hash) => {
        const resp = await repo.patchProject(projectId, { relatedSyllabus: { value, hash } });
        if (resp instanceof Err) return resp;
        return [resp.relatedSyllabus.value, resp.relatedSyllabus.hash];
      }
    );
    this._viewPointSEnabled = new EditableBoolean("viewPointSEnabled", viewPointSEnabled, savable, async value => {
      const resp = await repo.patchProject(projectId, { viewPointSEnabled: value });
      if (resp instanceof Err) return resp;
      return resp.viewPointSEnabled;
    });
    this._viewPointAEnabled = new EditableBoolean("viewPointAEnabled", viewPointAEnabled, savable, async value => {
      const resp = await repo.patchProject(projectId, { viewPointAEnabled: value });
      if (resp instanceof Err) return resp;
      return resp.viewPointAEnabled;
    });
    this._viewPointBEnabled = new EditableBoolean("viewPointBEnabled", viewPointBEnabled, savable, async value => {
      const resp = await repo.patchProject(projectId, { viewPointBEnabled: value });
      if (resp instanceof Err) return resp;
      return resp.viewPointBEnabled;
    });
    this._viewPointCEnabled = new EditableBoolean("viewPointCEnabled", viewPointCEnabled, savable, async value => {
      const resp = await repo.patchProject(projectId, { viewPointCEnabled: value });
      if (resp instanceof Err) return resp;
      return resp.viewPointCEnabled;
    });
    this._startingMonth = new EditableYearMonth(
      "startingMonth",
      startingYear,
      startingMonth,
      savable,
      async (year, month) => {
        const resp = await repo.patchProject(projectId, { startingMonth: { year, month } });
        if (resp instanceof Err) return resp;
        return [resp.startingMonth.year, resp.startingMonth.month];
      }
    );
    this._endingMonth = new EditableYearMonth("endingMonth", endingYear, endingMonth, savable, async (year, month) => {
      const resp = await repo.patchProject(projectId, { endingMonth: { year, month } });
      if (resp instanceof Err) return resp;
      return [resp.endingMonth.year, resp.endingMonth.month];
    });
    this._published = new EditableBoolean("published", published, savable, async value => {
      const resp = await repo.patchProject(projectId, { published: value });
      if (resp instanceof Err) return resp;
      return resp.published;
    });
    this._completed = new EditableBoolean("completed", completed, savable, async value => {
      const resp = await repo.patchProject(projectId, { completed: value });
      if (resp instanceof Err) return resp;
      return resp.completed;
    });
    this._rubrics = new EditableStringArray("rubrics", rubrics, savable, async value => {
      const resp = await repo.patchProject(projectId, { rubrics: value });
      if (resp instanceof Err) return resp;
      return resp.rubrics;
    });
  }

  protected allEditables() {
    return [
      this._name,
      this._description,
      this._relatedSyllabus,
      this._viewPointSEnabled,
      this._viewPointAEnabled,
      this._viewPointBEnabled,
      this._viewPointCEnabled,
      this._startingMonth,
      this._endingMonth,
      this._published,
      this._completed,
      this._rubrics
    ];
  }

  get name(): string {
    return this._name.value;
  }

  set name(value: string) {
    this._name.value = value;
  }

  get description(): string {
    return this._description.value;
  }

  set description(value: string) {
    this._description.value = value;
  }

  get relatedSyllabus(): string {
    return this._relatedSyllabus.value;
  }

  set relatedSyllabus(value: string) {
    this._relatedSyllabus.value = value;
  }

  get viewPointSEnabled(): boolean {
    return this._viewPointSEnabled.value;
  }

  set viewPointSEnabled(value: boolean) {
    this._viewPointSEnabled.value = value;
  }

  get viewPointAEnabled(): boolean {
    return this._viewPointAEnabled.value;
  }

  set viewPointAEnabled(value: boolean) {
    this._viewPointAEnabled.value = value;
  }

  get viewPointBEnabled(): boolean {
    return this._viewPointBEnabled.value;
  }

  set viewPointBEnabled(value: boolean) {
    this._viewPointBEnabled.value = value;
  }

  get viewPointCEnabled(): boolean {
    return this._viewPointCEnabled.value;
  }

  set viewPointCEnabled(value: boolean) {
    this._viewPointCEnabled.value = value;
  }

  get startingYear(): number {
    return this._startingMonth.year;
  }

  set startingYear(value: number) {
    this._startingMonth.year = value;
  }

  get startingMonth(): number {
    return this._startingMonth.month;
  }

  set startingMonth(value: number) {
    this._startingMonth.month = value;
  }

  get endingYear(): number {
    return this._endingMonth.year;
  }

  set endingYear(value: number) {
    this._endingMonth.year = value;
  }

  get endingMonth(): number {
    return this._endingMonth.month;
  }

  set endingMonth(value: number) {
    this._endingMonth.month = value;
  }

  get published(): boolean {
    return this._published.value;
  }

  set published(value: boolean) {
    this._published.value = value;
  }

  get completed(): boolean {
    return this._completed.value;
  }

  set completed(value: boolean) {
    this._completed.value = value;
  }

  get rubrics(): string[] {
    return this._rubrics.value;
  }

  set rubrics(value: string[]) {
    this._rubrics.value = value;
  }
}
