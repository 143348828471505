import { EditableObject } from "@/ts/objects/editable/EditableObject";
import { EditableProjectJournal } from "@/ts/objects/editable/project/EditableProjectJournal";
import { EditableProjectLookback } from "@/ts/objects/editable/project/EditableProjectLookback";

export class EditableProjectStudent extends EditableObject {
  readonly studentUserId: string;
  readonly studentNumber: number;
  readonly name: string;
  readonly projectJournals: EditableProjectJournal[];
  readonly projectLookback: EditableProjectLookback;

  protected allEditables() {
    return [...this.projectJournals, this.projectLookback];
  }

  constructor(
    studentUserId: string,
    studentNumber: number,
    name: string,
    projectJournals: EditableProjectJournal[],
    projectLookback: EditableProjectLookback
  ) {
    super();
    this.studentUserId = studentUserId;
    this.studentNumber = studentNumber;
    this.name = name;
    this.projectJournals = projectJournals;
    this.projectLookback = projectLookback;
  }
}

// Loaders

// export async function loadEditableProjectStudents(
//   projectId: string,
//   cls: Class | null,
//   teacherInputSavable: boolean,
//   studentInputSavable: boolean,
//   guardianInputSavable: boolean
// ): Promise<{ rubrics: ProjectRubric[]; editableStudents: EditableProjectStudent[] } | Err> {
//   if (cls === null) {
//     return new DisplayableErr(`loadEditableProjectStudents: class must not be null`, messages.pleaseSelectClass);
//   }
//   const [journalResp, lookbackResp, classStudents] = await Promise.all<
//     { rubrics: ProjectRubric[]; editableJournals: EditableProjectJournal[] } | Err,
//     EditableProjectLookback[] | Err,
//     Map<string, ClassStudent>
//   >([
//     loadEditableProjectJournals(projectId, undefined, teacherInputSavable, studentInputSavable),
//     loadEditableProjectLookbacks(projectId, undefined, teacherInputSavable, studentInputSavable, guardianInputSavable),
//     cls.classStudents()
//   ]);
//   if (journalResp instanceof Err) return journalResp;
//   if (lookbackResp instanceof Err) return lookbackResp;
//
//   const groupedJournals = _.groupBy(journalResp.editableJournals, j => j.studentUserId);
//   const students = Object.entries(groupedJournals)
//     .map(([studentUserId, journals]) => {
//       const student = classStudents.get(studentUserId);
//       const lookback = lookbackResp.find(l => l.studentUserId === studentUserId);
//       if (student === undefined || lookback === undefined) return null;
//       return new EditableProjectStudent(student.studentUserId, student.studentNumber, student.name, journals, lookback);
//     })
//     .filter((v): v is EditableProjectStudent => v !== null);
//
//   const sortedStudents = _.sortBy(students, ["studentNumber"]);
//
//   return {
//     rubrics: journalResp.rubrics,
//     editableStudents: sortedStudents
//   };
// }
