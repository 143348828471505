










































































































































































































































































































































































import Vue, { PropType } from "vue";
import AutoResizeTextarea from "@/components/AutoResizeTextarea.vue";
import FilterableHeaderButton, { CheckboxItem } from "@/components/FilterableHeaderButton.vue";
import { EditableProjectJournal } from "@/ts/objects/editable/project/EditableProjectJournal";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { SaveResult } from "@/ts/objects/editable/SaveResult";
import ErrorNotification, { ErrorNotificationParam } from "@/components/ErrorNotification.vue";
import TipBlock from "@/components/TipBlock.vue";
import PopupMenuButton, { MenuButton } from "@/components/PopupMenuButton.vue";
import JournalFilePreviewArea from "@/components/journalfile/JournalFilePreviewArea/JournalFilePreviewArea.vue";
import JournalFilesView from "@/components/journalfile/JournalFilesView.vue";
import {
  ColFilterState,
  EmptyFilterCheckState,
  RowFilterState,
  StudentRow
} from "@/views/project/teacher/ProjectJournalsT/ProjectJournalsT.vue";
import SaveStateIndicator from "@/components/SaveStateIndicator/SaveStateIndicator.vue";

export enum WholeSelectionState {
  AllSelected,
  SomeSelected,
  NoneSelected
}

const studentColWidth = 140;
const learningActivityColWidth = 160;
const journalFilesColWidth = 160;
const studentCommentColWidth = 200;
const studentRatingColWidth = 60;
const studentInputHeaderWidth = studentCommentColWidth + studentRatingColWidth;
const teacherRatingColWidth = 60;
const teacherCommentColWidth = 200;
const guardianCommentColWidth = 160;
const teacherInputPublishedColWidth = 97;
const selectionColWidth = 60;

export default Vue.extend({
  name: "ProjectJournalsTPure",
  components: {
    SaveStateIndicator,
    JournalFilePreviewArea,
    TipBlock,
    LoadingBlock,
    MessageView,
    ErrorNotification,
    AutoResizeTextarea,
    FilterableHeaderButton,
    PopupMenuButton,
    JournalFilesView
  },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },
    loading: { type: Boolean, required: true },
    needSave: { type: Boolean, required: true },
    currentErrors: { type: Array as PropType<ErrorNotificationParam[]>, required: true },

    projectBasePath: { type: String, required: true },

    extraMenuItems: { type: Array as PropType<MenuButton[]>, required: true },

    studentRows: { type: Array as PropType<StudentRow[]>, required: true },

    studentRowFilterCheckboxItems: { type: Array as PropType<CheckboxItem[]>, required: true },
    learningActivityRowFilterCheckboxItems: { type: Array as PropType<CheckboxItem[]>, required: true },
    teacherRatingRowFilterCheckboxItems: { type: Array as PropType<CheckboxItem[]>, required: true },
    teacherCommentRowFilterCheckboxItems: { type: Array as PropType<CheckboxItem[]>, required: true },
    guardianCommentRowFilterCheckboxItems: { type: Array as PropType<CheckboxItem[]>, required: true },

    rowFilterState: { type: Object as PropType<RowFilterState>, required: true },
    colFilterState: { type: Object as PropType<ColFilterState>, required: true },

    filesViewJournal: { type: Object as PropType<EditableProjectJournal | null> },

    highlightUnsaved: { type: Boolean, required: true },

    saveAll: { type: Function as PropType<(force: boolean) => Promise<SaveResult>>, required: true },

    toggleLearningActivityColFilter: { type: Function as PropType<() => void>, required: true },
    toggleJournalFilesColFilter: { type: Function as PropType<() => void>, required: true },
    toggleRatingsAndCommentsColFilter: { type: Function as PropType<() => void>, required: true },
    toggleGuardianCommentColFilter: { type: Function as PropType<() => void>, required: true },

    onStudentRowFilterChanged: {
      type: Function as PropType<(state: Record<string, boolean>) => void>,
      required: true
    },
    onLearningActivityRowFilterChanged: {
      type: Function as PropType<(state: Record<string, boolean>) => void>,
      required: true
    },
    onTeacherRatingRowFilterChanged: {
      type: Function as PropType<(state: EmptyFilterCheckState) => void>,
      required: true
    },
    onTeacherCommentRowFilterChanged: {
      type: Function as PropType<(state: EmptyFilterCheckState) => void>,
      required: true
    },
    onGuardianCommentRowFilterChanged: {
      type: Function as PropType<(state: EmptyFilterCheckState) => void>,
      required: true
    },

    toggleSelectionOfJournal: {
      type: Function as PropType<(checked: boolean, studentUserId: string, journal: string) => void>,
      required: true
    },
    toggleSelectionOfLookback: {
      type: Function as PropType<(checked: boolean, studentUserId: string, lookback: string) => void>,
      required: true
    },
    changeAllSelections: {
      type: Function as PropType<(changeTo: boolean) => void>,
      required: true
    },
    publishSelectedRows: {
      type: Function as PropType<() => void>,
      required: true
    },
    unpublishSelectedRows: {
      type: Function as PropType<() => void>,
      required: true
    },
    setFilesView: {
      type: Function as PropType<(studentUserId: string | null, journalRname: string | null) => void>,
      required: true
    },
    selectExtraMenu: {
      type: Function as PropType<(menuItem: string) => void>,
      required: true
    }
  },
  computed: {
    styles(): any {
      return {
        "--studentColWidth": `${studentColWidth}px`,
        "--learningActivityColWidth": `${learningActivityColWidth}px`,
        "--journalFilesColWidth": `${journalFilesColWidth}px`,
        "--studentCommentColWidth": `${studentCommentColWidth}px`,
        "--studentRatingColWidth": `${studentRatingColWidth}px`,
        "--studentInputHeaderWidth": `${studentInputHeaderWidth}px`,
        "--teacherRatingColWidth": `${teacherRatingColWidth}px`,
        "--teacherCommentColWidth": `${teacherCommentColWidth}px`,
        "--guardianCommentColWidth": `${guardianCommentColWidth}px`,
        "--teacherInputPublishedColWidth": `${teacherInputPublishedColWidth}px`,
        "--selectionColWidth": `${selectionColWidth}px`,
        "--currentJournalRowWidth": `${this.currentJournalRowWidth}px`,
        "--currentTableWidth": `${this.currentTableWidth}px`
      };
    },
    currentJournalRowWidth(): number {
      let width = teacherInputPublishedColWidth + selectionColWidth;
      if (this.colFilterState.learningActivity) {
        width += learningActivityColWidth;
      }
      if (this.colFilterState.journalFiles) {
        width += journalFilesColWidth;
      }
      if (this.colFilterState.ratingsAndComments) {
        width += studentInputHeaderWidth + teacherRatingColWidth + teacherCommentColWidth;
      }
      if (this.colFilterState.guardianComment) {
        width += guardianCommentColWidth;
      }
      return width;
    },
    currentTableWidth(): number {
      return studentColWidth + this.currentJournalRowWidth;
    },
    wholeSelectionState(): WholeSelectionState {
      // TODO この程度重くないとは思うが、要確認？
      const values = this.studentRows.flatMap(sr => {
        const journalSelections = sr.journalRows.map(jr => jr.selected);
        const lookbackSelections = sr.lookbackRow !== null ? [sr.lookbackRow.selected] : [];
        return [...journalSelections, ...lookbackSelections];
      });
      let foundSelected = false;
      let foundUnselected = false;
      for (const v of values) {
        if (v === true) {
          foundSelected = true;
        } else {
          foundUnselected = true;
        }
      }

      if (foundSelected && !foundUnselected) {
        return WholeSelectionState.AllSelected;
      } else if (foundSelected && foundUnselected) {
        return WholeSelectionState.SomeSelected;
      } else {
        return WholeSelectionState.NoneSelected;
      }
    },
    allSelected(): boolean {
      return this.wholeSelectionState === WholeSelectionState.AllSelected;
    },
    someSelected(): boolean {
      return this.wholeSelectionState === WholeSelectionState.SomeSelected;
    },
    noneSelected(): boolean {
      return this.wholeSelectionState === WholeSelectionState.NoneSelected;
    }
  },
  methods: {
    isMyFilesViewOpen(journalRname: string): boolean {
      const filesViewJournal = this.filesViewJournal;
      if (filesViewJournal === null) return false;
      return filesViewJournal.self === journalRname;
    },
    toggleAllSelections() {
      const changeTo = this.noneSelected ? true : false;
      this.changeAllSelections(changeTo);
    }
  }
});
