// TODO 本番ビルドでは消えてくれるだろうか？ tree-shakingがどう働くのか良く分かっていない。
import { Quarter } from "@/ts/objects/noneditable/value/Quarter";
import { Project } from "@/ts/objects/noneditable/project/Project";
import { ProjectRubric } from "@/ts/objects/noneditable/project/ProjectRubric";
import { EditableProject } from "@/ts/objects/editable/project/EditableProject";
import { ProjectRepositoryMock } from "@/test-tools/mocks/ProjectRepositoryMock";
import { EditableProjectLookback } from "@/ts/objects/editable/project/EditableProjectLookback";
import { EditableProjectJournal } from "@/ts/objects/editable/project/EditableProjectJournal";
import { EditableProjectRubric } from "@/ts/objects/editable/project/EditableProjectRubric";
import { ProjectJournalFile } from "@/ts/objects/noneditable/project/ProjectJournalFile";

export type ProjectTestData = {
  project: Record<string, Project>;
  editableProject: Record<string, EditableProject>;
  editableLookback: Record<string, EditableProjectLookback>;
  rubric: Record<string, ProjectRubric>;
  editableRubric: Record<string, EditableProjectRubric>;
  editableJournal: Record<string, EditableProjectJournal>;
  journalFile: Record<string, ProjectJournalFile>;
};

export function getProjectTestData(repo: ProjectRepositoryMock): ProjectTestData {
  /**
   * プロジェクト学習記録ファイル。
   */
  const journalFile = {
    journalFile000: new ProjectJournalFile(
      "/projects/project000/rubrics/rubric000/journals/journal000/journalFiles/journalFile000",
      "/projects/project000/rubrics/rubric000/journals/journal000",
      "any",
      "any",
      "application/octet-stream",
      "myfilename",
      "ext",
      "gcs-object-path",
      "thumbnail-gcs-object-path",
      false,
      null,
      null,
      "2000-01-01T00:00:00Z",
      "2000-01-01T00:00:00Z"
    ),
    journalFile001: new ProjectJournalFile(
      "/projects/project000/rubrics/rubric000/journals/journal000/journalFiles/journalFile001",
      "/projects/project000/rubrics/rubric000/journals/journal000",
      "any",
      "any",
      "application/octet-stream",
      "myfilename1",
      "ext1",
      "gcs-object-path",
      "thumbnail-gcs-object-path",
      false,
      null,
      null,
      "2000-01-01T00:00:00Z",
      "2000-01-01T00:00:00Z"
    )
  };

  return {
    /**
     * プロジェクト。
     */
    project: {
      project000: new Project(
        "/projects/project000",
        "class000",
        new Quarter(2000, 1),
        "プロジェクト000",
        "プロジェクト000の説明",
        "プロジェクト000の関連するシラバス",
        true,
        true,
        false,
        true,
        1999,
        4,
        2001,
        3,
        true,
        false,
        [
          "/projects/project000/rubrics/rubric000",
          "/projects/project000/rubrics/rubric001",
          "/projects/project000/rubrics/rubric002",
          "/projects/project000/rubrics/rubric003"
        ]
      ),
      project001: new Project(
        "/projects/project001",
        "class000",
        new Quarter(2000, 1),
        "プロジェクト001",
        "プロジェクト001の説明",
        "プロジェクト001の関連するシラバス",
        true,
        true,
        false,
        true,
        2000,
        5,
        2000,
        8,
        true,
        false,
        []
      )
    },
    /**
     * プロジェクト(編集可能)。
     */
    editableProject: {
      project000: new EditableProject(
        repo,
        true,
        "/projects/project000",
        "class000",
        new Quarter(2000, 1),
        "プロジェクト000",
        "",
        "プロジェクト000の説明",
        "",
        "プロジェクト000の関連するシラバス",
        "",
        true,
        true,
        false,
        true,
        1999,
        4,
        2001,
        3,
        true,
        false,
        [
          "/projects/project000/rubrics/rubric000",
          "/projects/project000/rubrics/rubric001",
          "/projects/project000/rubrics/rubric002",
          "/projects/project000/rubrics/rubric003"
        ]
      ),
      project001: new EditableProject(
        repo,
        true,
        "/projects/project001",
        "class000",
        new Quarter(2000, 1),
        "プロジェクト001",
        "",
        "プロジェクト001の説明",
        "",
        "プロジェクト001の関連するシラバス",
        "",
        true,
        true,
        false,
        true,
        2000,
        5,
        2000,
        8,
        true,
        false,
        []
      )
    },

    /**
     * プロジェクトふりかえり(編集可能)。
     */
    editableLookback: {
      lookback000: new EditableProjectLookback(
        repo,
        false,
        true,
        false,
        "/projects/project000/lookbacks/lookback000",
        "student000",
        "ふりかえり000の児童生徒コメント",
        "",
        "C",
        "ふりかえり000の教師コメント",
        "",
        "B",
        true,
        "ふりかえり000の保護者コメント",
        "",
        false,
        true
      )
    },

    /**
     * プロジェクトルーブリック。
     */
    rubric: {
      rubric000: new ProjectRubric(
        "/projects/project000/rubrics/rubric000",
        0,
        "学習活動000",
        "観点S 000",
        "観点A 000",
        "観点B 000",
        "観点C 000"
      ),
      rubric001: new ProjectRubric(
        "/projects/project000/rubrics/rubric001",
        1,
        "学習活動001",
        "観点S 001",
        "観点A 001",
        "観点B 001",
        "観点C 001"
      ),
      rubric002: new ProjectRubric(
        "/projects/project000/rubrics/rubric002",
        2,
        "学習活動002",
        "観点S 002",
        "観点A 002",
        "観点B 002",
        "観点C 002"
      ),
      長文: new ProjectRubric(
        "/projects/project000/rubrics/rubric003",
        2,
        "おれはその時その青黒く淀んだ室の中の堅い灰色の自分の席にそわそわ立ったり座ったりしてゐた。\n" +
          "　二人の男がその室の中に居た。一人はたしかに獣医の有本でも一人はさまざまのやつらのもやもやした区分キメラであった。\n" +
          "　おれはどこかへ出て行かうと考へてゐるらしかった。飛ぶんだぞ霧の中をきいっとふっとんでやるんだなどと頭の奥で叫んでゐた。ところがその二人がしきりに着物のはなしをした。",
        "おれはひどくむしゃくしゃした。そして卓をガタガタゆすってゐた。\n" +
          "　いきなり霧積が入って来た。霧積は変に白くぴかぴかする金襴の羽織を着てゐた。そしてひどく嬉しさうに見えた。今朝は支那版画展覧会があって自分はその幹事になってゐるからそっちへ行くんだと云ってかなり大声で笑った。おれはそれがしゃくにさわった。第一霧積は今日はおれと北の方の野原へ出かける約束だったのだ、それを白っぽい金襴の羽織などを着込んでわけもわからない処へ行ってけらけら笑ったりしやうといふのはあんまり失敬だと　おれは考へた。",
        "観点A 003",
        "観点B 003",
        "観点C 003"
      )
    },

    /**
     * プロジェクトルーブリック(編集可能)。
     */
    editableRubric: {
      rubric000: new EditableProjectRubric(
        repo,
        true,
        "/projects/project000/rubrics/rubric000",
        0,
        "学習活動000",
        "",
        "観点S 000",
        "",
        "観点A 000",
        "",
        "観点B 000",
        "",
        "観点C 000",
        ""
      ),
      rubric001: new EditableProjectRubric(
        repo,
        true,
        "/projects/project000/rubrics/rubric001",
        1,
        "学習活動001",
        "",
        "観点S 001",
        "",
        "観点A 001",
        "",
        "観点B 001",
        "",
        "観点C 001",
        ""
      ),
      rubric002: new EditableProjectRubric(
        repo,
        true,
        "/projects/project000/rubrics/rubric002",
        2,
        "学習活動002",
        "",
        "観点S 002",
        "",
        "観点A 002",
        "",
        "観点B 002",
        "",
        "観点C 002",
        ""
      ),
      長文: new EditableProjectRubric(
        repo,
        true,
        "/projects/project000/rubrics/rubric003",
        2,
        "おれはその時その青黒く淀んだ室の中の堅い灰色の自分の席にそわそわ立ったり座ったりしてゐた。\n" +
          "　二人の男がその室の中に居た。一人はたしかに獣医の有本でも一人はさまざまのやつらのもやもやした区分キメラであった。\n" +
          "　おれはどこかへ出て行かうと考へてゐるらしかった。飛ぶんだぞ霧の中をきいっとふっとんでやるんだなどと頭の奥で叫んでゐた。ところがその二人がしきりに着物のはなしをした。",
        "",
        "おれはひどくむしゃくしゃした。そして卓をガタガタゆすってゐた。\n" +
          "　いきなり霧積が入って来た。霧積は変に白くぴかぴかする金襴の羽織を着てゐた。そしてひどく嬉しさうに見えた。今朝は支那版画展覧会があって自分はその幹事になってゐるからそっちへ行くんだと云ってかなり大声で笑った。おれはそれがしゃくにさわった。第一霧積は今日はおれと北の方の野原へ出かける約束だったのだ、それを白っぽい金襴の羽織などを着込んでわけもわからない処へ行ってけらけら笑ったりしやうといふのはあんまり失敬だと　おれは考へた。",
        "",
        "観点A 003",
        "",
        "観点B 003",
        "",
        "観点C 003",
        ""
      )
    },

    /**
     * プロジェクト学習記録(編集可能)。
     */
    editableJournal: {
      journal000: new EditableProjectJournal(
        repo,
        true,
        false,
        "/projects/project000/rubrics/rubric000/journals/journal000",
        "/projects/project000/rubrics/rubric000",
        "学習活動000",
        "student000",
        [journalFile.journalFile000, journalFile.journalFile001],
        "児童生徒コメント000",
        "",
        "A",
        "教師コメント000",
        "",
        "S",
        true,
        false
      ),
      journal001: new EditableProjectJournal(
        repo,
        true,
        false,
        "/projects/project000/rubrics/rubric001/journals/journal001",
        "/projects/project000/rubrics/rubric001",
        "学習活動001",
        "student000",
        [],
        "児童生徒コメント001",
        "",
        "",
        "教師コメント001",
        "",
        "B",
        true,
        false
      ),
      journal002: new EditableProjectJournal(
        repo,
        true,
        false,
        "/projects/project000/rubrics/rubric002/journals/journal002",
        "/projects/project000/rubrics/rubric002",
        "学習活動002",
        "student000",
        [],
        "児童生徒コメント002",
        "",
        "A",
        "教師コメント002",
        "",
        "",
        true,
        false
      )
    },

    journalFile
  };
}
