










































































import Vue, { PropType } from "vue";
import { SolanProject } from "@/ts/objects/noneditable/solan/SolanProject";
import PopupSelectButton, { SelectItem } from "@/components/PopupSelectButton.vue";
import PopupMenuButton, { MenuButton } from "@/components/PopupMenuButton.vue";
import SchoolYearControl from "@/components/SchoolYearControl/SchoolYearControl.vue";

export default Vue.extend({
  name: "SolanHeaderPure",
  components: { SchoolYearControl, PopupSelectButton, PopupMenuButton },
  props: {
    studentOrGuardianBasePath: { type: String },
    projectBasePath: { type: String },
    isCurrentTabJournals: { type: Boolean },
    isCurrentTabRubrics: { type: Boolean },
    isCurrentTabAbout: { type: Boolean },
    isCurrentTabLookback: { type: Boolean },
    project: { type: Object as PropType<SolanProject | null> },
    editing: { type: Boolean },
    projectName: { type: String },
    schoolYear: { type: Number as PropType<number | null>, required: true },
    minSchoolYear: { type: Number as PropType<number | null>, required: true },
    maxSchoolYear: { type: Number as PropType<number | null>, required: true },
    projectSelections: { type: Array as PropType<SelectItem[]> },

    /**
     * 新プロジェクトを作成可能ならtrue。
     * 選択中の年度が、現在の年度である場合のみtrueとする。
     */
    canCreateNewProject: { type: Boolean },

    selectProject: { type: Function },
    selectExtraMenu: { type: Function },
    createNewProjectAndGoEdit: { type: Function },
    updateProjectName: { type: Function }
  },
  data() {
    return {
      extraMenuItems: [new MenuButton("newProject", "新しいテーマ", ["fas", "plus"])]
    };
  }
});
