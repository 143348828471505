import { EditableObject } from "@/ts/objects/editable/EditableObject";
import { EditableBoolean, EditableHashedString } from "@/ts/objects/editable/value/EditablePrimitiveValue";
import { Err } from "@/ts/objects/Err";
import { SolanRepository } from "@/ts/repositories/SolanRepository";

export class EditableSolanProject extends EditableObject {
  readonly projectId: string;
  readonly studentUserId: string;
  readonly schoolYear: number;
  readonly _name: EditableHashedString;
  readonly _description: EditableHashedString;
  readonly _started: EditableBoolean;
  readonly _completed: EditableBoolean;

  constructor(
    repo: SolanRepository,
    savable: boolean,
    projectId: string,
    studentUserId: string,
    schoolYear: number,
    name: string,
    nameHash: string,
    description: string,
    descriptionHash: string,
    started: boolean,
    completed: boolean
  ) {
    super();
    this.projectId = projectId;
    this.studentUserId = studentUserId;
    this.schoolYear = schoolYear;
    this._name = new EditableHashedString("name", name, nameHash, savable, async (value, hash) => {
      const resp = await repo.patchProject(projectId, { name: { value, hash } });
      if (resp instanceof Err) return resp;
      return [resp.name.value, resp.name.hash];
    });
    this._description = new EditableHashedString(
      "description",
      description,
      descriptionHash,
      savable,
      async (value, hash) => {
        const resp = await repo.patchProject(projectId, { description: { value, hash } });
        if (resp instanceof Err) return resp;
        return [resp.description.value, resp.description.hash];
      }
    );
    this._started = new EditableBoolean("started", started, savable, async value => {
      const resp = await repo.patchProject(projectId, { started: value });
      if (resp instanceof Err) return resp;
      return resp.started;
    });
    this._completed = new EditableBoolean("completed", completed, savable, async value => {
      const resp = await repo.patchProject(projectId, { completed: value });
      if (resp instanceof Err) return resp;
      return resp.completed;
    });
  }

  protected allEditables() {
    return [this._name, this._description, this._started, this._completed];
  }

  get name(): string {
    return this._name.value;
  }

  set name(value: string) {
    this._name.value = value;
  }

  get description(): string {
    return this._description.value;
  }

  set description(value: string) {
    this._description.value = value;
  }

  get started(): boolean {
    return this._started.value;
  }

  set started(value: boolean) {
    this._started.value = value;
  }

  get completed(): boolean {
    return this._completed.value;
  }

  set completed(value: boolean) {
    this._completed.value = value;
  }
}
