








































































import Vue, { PropType } from "vue";
import { AppStateStore } from "@/store/AppStateStore";
import { StudentInfo } from "@/ts/StudentInfo";
import PopupMenuButton, { MenuButton, MenuItem } from "@/components/PopupMenuButton.vue";
import { AuthService } from "@/ts/AuthService";

export default Vue.extend({
  name: "AppHeader",
  components: { PopupMenuButton },
  props: {
    authService: { type: Object as PropType<AuthService>, required: true },
    appStateStore: { type: Object as PropType<AppStateStore>, required: true }
  },
  data(): {
    userMenuItems: MenuItem[];
  } {
    return {
      userMenuItems: [new MenuButton("changeGoogleAccount", "別のアカウントでログイン", undefined)]
    };
  },
  computed: {
    isCurrentTabHome(): boolean {
      return this.$route.name === "S.Home";
    },
    isCurrentTabCurriculum(): boolean {
      return this.$route.name === "S.Curriculum";
    },
    isCurrentTabProject(): boolean {
      return this.$route.name?.startsWith("S.Project.") ?? false;
    },
    isCurrentTabSolan(): boolean {
      return this.$route.name?.startsWith("S.Solan.") ?? false;
    },
    studentInfo(): StudentInfo | null {
      return this.appStateStore.studentOrGuardianState?.studentInfo() ?? null;
    },
    studentName(): string {
      return this.studentInfo?.name ?? "";
    },
    studentIconUrl(): string {
      return this.studentInfo?.iconUrl ?? "";
    },
    guardianStudents(): StudentInfo[] {
      return this.appStateStore.guardianState?.students ?? [];
    }
  },
  methods: {
    guardianStudentSelected(e: Event) {
      const studentUserId = (e.target as HTMLInputElement | null)?.value;
      if (studentUserId === undefined) return;
      this.$router.push(`/s/${studentUserId}`).catch(() => {});
    },
    async selectUserMenu(menuItem: string) {
      switch (menuItem) {
        case "changeGoogleAccount":
          await this.authService.changeGoogleAccount();
          break;
      }
    }
  }
});
