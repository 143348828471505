import sortBy from "lodash/sortBy";
import { Err } from "@/ts/objects/Err";
import log from "loglevel";
import { UserRepository } from "@/ts/repositories/UserRepository";

export enum SchoolType {
  ElementarySchool,
  JuniorHighSchool
}

export class Class {
  readonly id: string;
  readonly schoolYear: number;
  readonly schoolType: SchoolType;
  readonly grade: number;
  readonly name: string;
  readonly classNo: number;

  /**
   * classStudentsのキャッシュ。
   */
  private _classStudents: Map<string, ClassStudent> | null = null;

  /**
   * ソート済classStudentsのキャッシュ。
   */
  private _sortedClassStudents: ClassStudent[] | null = null;

  constructor(
    id: string,
    schoolYear: number,
    schoolType: SchoolType,
    grade: number,
    name: string,
    classNo: number,
    classStudents?: ClassStudent[] // ここで指定しなかった場合、lazyに取得する。
  ) {
    this.id = id;
    this.schoolYear = schoolYear;
    this.schoolType = schoolType;
    this.grade = grade;
    this.name = name;
    this.classNo = classNo;
    if (classStudents !== undefined)
      this._classStudents = new Map<string, ClassStudent>(classStudents.map(s => [s.studentUserId, s]));
  }

  async classStudents(userRepository: UserRepository): Promise<Map<string, ClassStudent>> {
    if (this._classStudents !== null) {
      // キャッシュがあればキャッシュを返す。
      return this._classStudents;
    }

    const classId = this.id;
    const resp = await userRepository.getClassStudents(classId);
    if (resp instanceof Err) {
      log.info("Failed to fetch class students from user-service.");
      return new Map<string, ClassStudent>();
    }

    const classStudents = new Map<string, ClassStudent>(resp.map(s => [s.studentUserId, s]));

    this._classStudents = classStudents;
    return classStudents;
  }

  async sortedClassStudents(userRepository: UserRepository): Promise<ClassStudent[]> {
    if (this._sortedClassStudents !== null) {
      // キャッシュがあればキャッシュを返す。
      return this._sortedClassStudents;
    }

    const classStudents = await this.classStudents(userRepository);
    const sortedClassStudents = sortBy<ClassStudent>([...classStudents.values()], [s => s.studentNumber]);
    this._sortedClassStudents = sortedClassStudents;
    return sortedClassStudents;
  }
}

export class ClassStudent {
  readonly studentUserId: string;
  readonly studentNumber: number;
  readonly name: string;
  readonly iconUrl: string;

  constructor(studentUserId: string, studentNumber: number, name: string, iconUrl: string) {
    this.studentUserId = studentUserId;
    this.studentNumber = studentNumber;
    this.name = name;
    this.iconUrl = iconUrl;
  }
}
