


















































import Vue, { PropType } from "vue";
import { CurriculumPeriodMode } from "@/ts/objects/enum/CurriculumPeriodMode";
import RouterLinkWrapper from "@/components/RouterLinkWrapper.vue";
import { CurriculumTabName } from "@/ts/objects/enum/CurriculumTabName";

export default Vue.extend({
  name: "YearMonthControl",
  components: { RouterLinkWrapper },
  props: {
    /**
     * 年度。
     */
    schoolYear: { type: Number, required: true },

    /**
     * 月別表示/年間表示。
     */
    periodMode: { type: String as PropType<CurriculumPeriodMode>, required: true },

    /**
     * 今月。
     */
    thisMonth: { type: Number, required: true },

    /**
     * 選択中の月。
     */
    selectedMonth: { type: Number as PropType<number | null> },

    /**
     * 選択中の教科のリソース名。
     */
    selectedCurriculum: { type: String as PropType<string | null> },

    /**
     * 教科学習のパスを取得する関数。
     */
    getPath: {
      type: Function as PropType<
        (
          tab: CurriculumTabName,
          periodMode: CurriculumPeriodMode | null,
          month: number | null,
          resourceName: string | null
        ) => string
      >,
      required: true
    }
  },
  computed: {
    enabled(): boolean {
      return (
        (this.periodMode === "yearly" || (this.periodMode === "monthly" && this.selectedMonth !== null)) &&
        this.selectedCurriculum !== null
      );
    },
    prevMonth(): number {
      const month = this.selectedMonth;
      if (month === null) return 4;

      if (month === 4) {
        return 4;
      } else if (5 <= month && month <= 12) {
        return month - 1;
      } else if (month === 1) {
        return 12;
      } else if (2 <= month && month <= 3) {
        return month - 1;
      }
      return 4;
    },
    nextMonth(): number {
      const month = this.selectedMonth;
      if (month === null) return 4;

      if (4 <= month && month <= 11) {
        return month + 1;
      } else if (month === 12) {
        return 1;
      } else if (1 <= month && month <= 2) {
        return month + 1;
      } else if (month === 3) {
        return 3;
      }
      return 4;
    }
  }
});
