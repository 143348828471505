import Vue from "vue";
import log from "loglevel";
import { parseUTCOrZero, projectJournalFileRnameToId } from "@/ts/utils/AppUtil";
import { JournalFile } from "@/ts/objects/noneditable/JournalFile";
import { isNullish } from "@/ts/utils/CommonUtil";

export class ProjectJournalFile extends JournalFile {
  readonly self: string;
  readonly projectId: string;
  readonly rubricId: string;
  readonly journalId: string;
  readonly journalFileId: string;
  readonly journal: string;
  readonly type: string;
  readonly subtype: string;
  readonly mediaType: string;
  readonly filename: string;
  readonly ext: string;
  readonly gcsObjectPath: string;
  readonly thumbnailGcsObjectPath: string | null;
  readonly hasThumbnail: boolean;
  readonly width: number | null;
  readonly height: number | null;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  private _fileUrl: string | null = null;
  private _thumbnailUrl: string | null = null;

  constructor(
    self: string,
    journal: string,
    type: string,
    subtype: string,
    mediaType: string,
    filename: string,
    ext: string,
    gcsObjectPath: string,
    thumbnailGcsObjectPath: string | null,
    hasThumbnail: boolean,
    width: number | null,
    height: number | null,
    createdAt: string,
    updatedAt: string
  ) {
    super();
    this.self = self;
    [this.projectId, this.rubricId, this.journalId, this.journalFileId] = projectJournalFileRnameToId(self) ?? [
      "unknown",
      "unknown",
      "unknown",
      "unknown"
    ]; // TODO これどうにかする？
    this.journal = journal;
    this.type = type;
    this.subtype = subtype;
    this.mediaType = mediaType;
    this.filename = filename;
    this.ext = ext;
    this.gcsObjectPath = gcsObjectPath;
    this.thumbnailGcsObjectPath = thumbnailGcsObjectPath;
    this.hasThumbnail = hasThumbnail;
    this.width = width;
    this.height = height;
    this.createdAt = parseUTCOrZero(createdAt);
    this.updatedAt = parseUTCOrZero(updatedAt);

    this.initUrls(hasThumbnail, gcsObjectPath, thumbnailGcsObjectPath);
  }

  private async initUrls(
    hasThumbnail: boolean,
    gcsObjectPath: string,
    thumbnailGcsObjectPath: string | null
  ): Promise<void> {
    if (hasThumbnail && !isNullish(thumbnailGcsObjectPath)) {
      const fileRef = Vue.prototype.$storage?.ref(gcsObjectPath);
      const thumbnailRef = Vue.prototype.$storage?.ref(thumbnailGcsObjectPath);
      if (fileRef === undefined || thumbnailRef === undefined) return;

      const [fileUrl, thumbnailUrl] = await Promise.all([
        fileRef.getDownloadURL(),
        thumbnailRef.getDownloadURL()
      ]).catch((e: any) => {
        log.info(`ProjectJournalFile.initUrl(): error=${JSON.stringify(e)}`);
        return ["", ""];
      });
      log.debug(`ProjectJournalFile.fileUrl(): fileUrl=${fileUrl}, thumbnailUrl=${thumbnailUrl}`);
      this._fileUrl = fileUrl;
      this._thumbnailUrl = thumbnailUrl;
    } else {
      const fileRef = Vue.prototype.$storage?.ref(gcsObjectPath);
      if (fileRef === undefined) return;

      const fileUrl = await fileRef.getDownloadURL().catch((e: any) => {
        log.info(`ProjectJournalFile.initUrl(): error=${JSON.stringify(e)}`);
        return ["", ""];
      });
      log.debug(`ProjectJournalFile.fileUrl(): fileUrl=${fileUrl}`);
      this._fileUrl = fileUrl;
    }
  }

  get fileUrl(): string | null {
    return this._fileUrl;
  }

  get thumbnailUrl(): string | null {
    return this._thumbnailUrl;
  }
}

// export async function loadProjectJournalFiles(journal: string): Promise<ProjectJournalFile[] | Err> {
//   const ids = projectJournalRnameToId(journal);
//   if (ids === null) return new DisplayableErr(`invalid journal resourceName: ${journal}`, messages.failedToLoadData);
//   const [projectId, rubricId, journalId] = ids;
//   const resp = await doReq(() => projectService.listJournalFile(projectId, rubricId, journalId));
//   if (resp instanceof Err) return resp;
//
//   return resp.map(
//     r =>
//       new ProjectJournalFile(
//         r.self,
//         r.journal,
//         r.type,
//         r.subtype,
//         r.mediaType,
//         r.filename,
//         r.ext,
//         r.gcsObjectPath,
//         r.thumbnailGcsObjectPath,
//         r.hasThumbnail,
//         r.width,
//         r.height,
//         r.createdAt,
//         r.updatedAt
//       )
//   );
// }
