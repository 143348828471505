import { ClassStudent } from "@/ts/objects/noneditable/Class";
import { doReq } from "@/ts/Services";
import { Err, InternalErr } from "@/ts/objects/Err";
import log from "loglevel";
import { Configuration, DefaultApi as UserDefaultApi, UserInfo as UserInfoResp } from "@/ts/api/user-service";
import { AxiosInstance } from "axios";
import { Quarter } from "@/ts/objects/noneditable/value/Quarter";
import { BasicUserInfo } from "@/ts/objects/noneditable/value/BasicUserInfo";
import { UserType, userTypes } from "@/ts/objects/noneditable/value/UserType";

export abstract class UserRepository {
  abstract getUserInfo(userId: string): Promise<UserInfoResp | Err>;

  abstract getBasicUserInfo(userId: string): Promise<BasicUserInfo | Err>;

  abstract getCurrentQuarter(): Promise<Quarter | Err>;

  abstract getClassStudents(classId: string): Promise<ClassStudent[] | Err>;

  /**
   * (storageやFireStoreで使うための)Firebaseユーザーの設定(カスタムクレーム)の更新を行う。
   */
  abstract updateFirebaseUser(): Promise<boolean | Err>;
}

export class UserRepositoryImpl extends UserRepository {
  private readonly userService: UserDefaultApi;

  constructor(serviceBasePath: string, axiosConf: Configuration | undefined, axiosInstance: AxiosInstance) {
    super();
    this.userService = new UserDefaultApi(axiosConf, serviceBasePath, axiosInstance);
  }

  async getUserInfo(userId: string): Promise<UserInfoResp | Err> {
    return await doReq(() => this.userService.getUserInfo(userId));
  }

  async getBasicUserInfo(userId: string): Promise<BasicUserInfo | Err> {
    // TODO 一応キャッシュはブラウザレベルでしているはずだが、アプリレベルでもしても良い。
    const resp = await doReq(() => this.userService.getBasicUserInfo(userId));
    if (resp instanceof Err) return resp;
    if (!(userTypes as readonly string[]).includes(resp.userType))
      return new InternalErr(`UserRepositoryImpl: Invalid userType: ${resp.userType}`);
    return {
      userId: resp.userId,
      email: resp.email,
      name: resp.name,
      userType: resp.userType as UserType,
      photoUrl: resp.photoUrl
    };
  }

  async getCurrentQuarter(): Promise<Quarter | Err> {
    const resp = await doReq(() => this.userService.getSemester());
    if (resp instanceof Err) return resp;
    return new Quarter(resp.year, resp.semester);
  }

  async getClassStudents(classId: string): Promise<ClassStudent[] | Err> {
    const resp = await doReq(() => this.userService.getClassStudents(classId));
    if (resp instanceof Err) {
      log.info("UserRepositoryImpl: Failed to fetch class students from user-service.");
      return new InternalErr("UserRepositoryImpl: Failed to fetch class students from user-service.");
    }

    return resp.map(s => new ClassStudent(s.id, s.number ?? 0, s.name, s.photoUrl));
  }

  async updateFirebaseUser(): Promise<boolean | Err> {
    const resp = await doReq(() => this.userService.firebaseAuthUser());
    if (resp instanceof Err) {
      log.info(`UserRepositoryImpl: Error from firebaseAuthUser: ${resp.internalMessage}`);
      return resp;
    }
    return resp.updated ?? false;
  }
}
