
















































import Vue, { PropType } from "vue";
import { EECJournal, EECJournalFile, EECJournalTree } from "@/ts/objects/noneditable/curriculum/EECJournal";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import { isNullish } from "@/ts/utils/CommonUtil";
import JournalFilePreviewArea from "@/components/journalfile/JournalFilePreviewArea/JournalFilePreviewArea.vue";
import JournalFilesView from "@/components/journalfile/JournalFilesView.vue";
import { JournalFile } from "@/ts/objects/noneditable/JournalFile";

export type CurriculumJournalRowEditEvent = {
  readonly month: number;
  readonly activity: string;
  readonly studentComment: string;
  // TODO journalFileもeditしたい。
};

export default Vue.extend({
  name: "CurriculumJournalRow",
  components: { JournalFilesView, JournalFilePreviewArea, DisableableAutoResizeTextarea },
  props: {
    /**
     * 学習記録。
     */
    journalTree: { type: Object as PropType<EECJournalTree>, required: true },

    /**
     * trueなら、他の条件を満たした場合には編集可能。
     * falseなら、何があっても編集不可能。
     */
    editableIfPossible: { type: Boolean, required: true },

    /**
     * 編集イベントを受け取る関数。
     */
    onEdit: {
      type: Function as PropType<(e: CurriculumJournalRowEditEvent) => void>
    },

    /**
     * 現在ファイルビューを開いている学習記録を変更する関数。
     */
    selectFilesViewJournal: { type: Function as PropType<(rname: string | null) => void>, required: true },

    /**
     * 現在、この学習記録のファイルビューが開かれていればtrue。
     */
    isFilesViewOpen: { type: Boolean, required: true },

    /**
     * 月カラムの幅（px）。
     */
    monthColWidth: { type: Number, required: true },

    /**
     * メニューボタンカラムの幅（px）。
     */
    extraMenuColWidth: { type: Number, required: true },

    /**
     * その他のカラムの最小幅（px）。
     */
    otherColMinWidth: { type: Number, required: true }
  },
  data(): {
    cellColor0: string;
    cellColor1: string;
  } {
    return {
      cellColor0: "#e8e9f4",
      cellColor1: "transparent"
    };
  },
  computed: {
    journal(): EECJournal {
      return this.journalTree.self;
    },
    journalFiles(): EECJournalFile[] {
      return this.journalTree.journalFiles.map(jf => jf.self);
    },
    styles(): any {
      return {
        "--cell-color-0": this.cellColor0,
        "--cell-color-1": this.cellColor1,
        "--monthColWidth": `${this.monthColWidth}px`,
        "--extraMenuColWidth": `${this.extraMenuColWidth}px`,
        "--otherColMinWidth": `${this.otherColMinWidth}px`
      };
    },
    editable(): boolean {
      return this.editableIfPossible && !isNullish(this.onEdit) && !this.journal.studentInputLocked;
    }
  },
  methods: {
    onChangeMonth(e: Event) {
      if (!this.editable) return;

      const target = e?.target;
      if (!(target instanceof HTMLSelectElement)) return;
      const intValue = parseInt(target.value, 10);
      if (isNaN(intValue)) return;
      this.onEdit({
        month: intValue,
        activity: this.journal.activity,
        studentComment: this.journal.studentComment
      });
    },
    onInputActivity(value: string) {
      if (!this.editable) return;
      this.onEdit({ month: this.journal.month, activity: value, studentComment: this.journal.studentComment });
    },
    onInputStudentComment(value: string) {
      if (!this.editable) return;
      this.onEdit({ month: this.journal.month, activity: this.journal.activity, studentComment: value });
    },
    onClickFilesCell() {
      if (this.isFilesViewOpen) this.selectFilesViewJournal(null);
      else this.selectFilesViewJournal(this.journal.resourceName);
    },
    async uploadJournalFile(file: File) {
      // TODO implement
    },
    async deleteJournalFile(file: JournalFile) {
      // TODO implement
    }
  }
});
