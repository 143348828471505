




































import Vue, { PropType } from "vue";
import { Loadable } from "@/ts/Loadable";
import { EECurriculum } from "@/ts/objects/noneditable/curriculum/EECurriculum";
import { EECJournalTree } from "@/ts/objects/noneditable/curriculum/EECJournal";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { messages } from "@/ts/const/Messages";
import CurriculumJournalRow from "@/views/curriculum/components/CurriculumJournalRow/CurriculumJournalRow.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import CurriculumWriteMainHeader from "@/views/curriculum/student/components/CurriculumWriteMainHeader/CurriculumWriteMainHeader.vue";
import CurriculumJournalHeaderRow from "@/views/curriculum/components/CurriculumJournalHeaderRow/CurriculumJournalHeaderRow.vue";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "CurriculumWriteEECPure",
  components: {
    CurriculumJournalHeaderRow,
    CurriculumJournalRow,
    MessageView,
    LoadingBlock,
    CurriculumWriteMainHeader
  },

  props: {
    /**
     * 児童生徒ID。
     */
    studentUserId: { type: String as PropType<string | null>, required: true },

    /**
     * 文書評価教科。
     */
    eeCurriculum: { type: Object as PropType<Loadable<EECurriculum | null | undefined>> },

    /**
     * 現在表示中の教科・児童生徒配下の学習記録。
     */
    journalTrees: { type: Object as PropType<Loadable<EECJournalTree[]>>, required: true },

    /**
     * 現在ファイルビューを開いている学習記録のリソース名。
     */
    filesViewJournal: { type: String as PropType<string | null> },

    /**
     * 現在ファイルビューを開いている学習記録を変更する関数。
     */
    selectFilesViewJournal: { type: Function as PropType<(rname: string | null) => void>, required: true }
  },
  data(): {
    months: number[];
    monthColWidth: number;
    extraMenuColWidth: number;
    otherColMinWidth: number;
  } {
    return {
      months: [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
      monthColWidth: 71,
      extraMenuColWidth: 20,
      otherColMinWidth: 60
    };
  },
  computed: {
    messageView(): MessageViewParam | null {
      if (this.studentUserId === null) return { message: messages.pleaseSelectStudent };
      if (
        this.eeCurriculum.isError ||
        (this.eeCurriculum.isDataLoaded && isNullish(this.eeCurriculum.data)) ||
        this.journalTrees.isError
      )
        return { message: messages.failedToLoadData };
      return null;
    },
    styles(): Record<string, string> {
      return {
        "--monthColWidth": `${this.monthColWidth}px`,
        "--extraMenuColWidth": `${this.extraMenuColWidth}px`,
        "--otherColMinWidth": `${this.otherColMinWidth}px`
      };
    },
    reversedJournalTrees(): EECJournalTree[] | null {
      const journalTrees = this.journalTrees.data;
      if (journalTrees === null) return null;
      return journalTrees.slice().reverse();
    }
  }
});
