




















import Vue, { PropType } from "vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { NECurriculumTree } from "@/ts/objects/noneditable/curriculum/NECurriculum";
import { NECEvaluation } from "@/ts/objects/noneditable/curriculum/NECEvaluation";
import { messages } from "@/ts/const/Messages";
import { Loadable } from "@/ts/Loadable";
import CurriculumReadMainHeader from "@/views/curriculum/student/components/CurriculumReadMainHeader/CurriculumReadMainHeader.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import CurriculumReadNECYearlyViewPointRow from "@/views/curriculum/student/CurriculumReadNECYearly/components/CurriculumReadNECYearlyViewPointRow/CurriculumReadNECYearlyViewPointRow.vue";
import { CurriculumTabName } from "@/ts/objects/enum/CurriculumTabName";
import { CurriculumPeriodMode } from "@/ts/objects/enum/CurriculumPeriodMode";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "CurriculumReadNECYearly",
  components: { CurriculumReadNECYearlyViewPointRow, CurriculumReadMainHeader, MessageView, LoadingBlock },
  props: {
    /**
     * 児童生徒ID。
     */
    studentUserId: { type: String as PropType<string | null>, required: true },

    /**
     * 数値評価教科。
     */
    neCurriculumTree: { type: Object as PropType<Loadable<NECurriculumTree | null | undefined>> },

    /**
     * 現在表示中の教科・児童生徒配下の評価。
     */
    evaluations: { type: Object as PropType<Loadable<NECEvaluation[]>>, required: true },

    /**
     * 教科学習のパスを取得する関数。
     */
    getPath: {
      type: Function as PropType<
        (
          tab: CurriculumTabName,
          periodMode: CurriculumPeriodMode | null,
          month: number | null,
          resourceName: string | null
        ) => string
      >,
      required: true
    }
  },
  computed: {
    messageView(): MessageViewParam | null {
      if (this.studentUserId === null) return { message: messages.pleaseSelectStudent };
      if (
        this.neCurriculumTree.isError ||
        (this.neCurriculumTree.isDataLoaded && isNullish(this.neCurriculumTree.data)) ||
        this.evaluations.isError
      )
        return { message: messages.failedToLoadData };
      return null;
    }
  }
});
