import { SolanProject } from "@/ts/objects/noneditable/solan/SolanProject";
import { EditableSolanProject } from "@/ts/objects/editable/solan/EditableSolanProject";
import { EditableSolanLookback } from "@/ts/objects/editable/solan/EditableSolanLookback";
import { SolanRubric } from "@/ts/objects/noneditable/solan/SolanRubric";
import { EditableSolanRubric } from "@/ts/objects/editable/solan/EditableSolanRubric";
import { EditableSolanJournal } from "@/ts/objects/editable/solan/EditableSolanJournal";
import { SolanJournalFile } from "@/ts/objects/noneditable/solan/SolanJournalFile";
import { SolanRepositoryMock } from "@/test-tools/mocks/SolanRepositoryMock";

export type SolanTestData = {
  project: Record<string, SolanProject>;
  editableProject: Record<string, EditableSolanProject>;
  editableLookback: Record<string, EditableSolanLookback>;
  rubric: Record<string, SolanRubric>;
  editableRubric: Record<string, EditableSolanRubric>;
  editableJournal: Record<string, EditableSolanJournal>;
  journalFile: Record<string, SolanJournalFile>;
};

export function getSolanTestData(repo: SolanRepositoryMock): SolanTestData {
  return {
    project: {
      project000: new SolanProject(
        "project000",
        "student000",
        2021,
        "SOLANプロジェクト000",
        "テーマ000を決めた理由です。",
        false,
        false,
        false
      ),
      開始済: new SolanProject(
        "project001",
        "student000",
        2021,
        "SOLANプロジェクト001",
        "テーマ001を決めた理由です。",
        true,
        false,
        false
      )
    },
    editableProject: {
      project000: new EditableSolanProject(
        repo,
        true,
        "project000",
        "student000",
        2000,
        "SOLANプロジェクト000",
        "",
        "テーマ000を決めた理由です。",
        "",
        false,
        false
      ),
      開始済: new EditableSolanProject(
        repo,
        true,
        "project001",
        "student000",
        2000,
        "SOLANプロジェクト001",
        "",
        "テーマ001を決めた理由です。",
        "",
        true,
        false
      )
    },
    editableLookback: {
      project000: new EditableSolanLookback(
        repo,
        false,
        true,
        false,
        "project000",
        "student000",
        "ふりかえり000の児童生徒コメント",
        "",
        "C",
        "ふりかえり000の教師コメント",
        "",
        "B",
        true,
        "ふりかえり000の保護者コメント",
        "",
        false,
        true
      )
    },
    rubric: {
      rubric000: new EditableSolanRubric(
        repo,
        true,
        "project000",
        "rubric000",
        "student000",
        0,
        "学習活動000",
        "",
        "観点S",
        "",
        "観点A",
        "",
        "観点B",
        "",
        "観点C",
        "",
        "",
        false
      ),
      rubric001: new EditableSolanRubric(
        repo,
        true,
        "project000",
        "rubric001",
        "student000",
        2,
        "学習活動001",
        "",
        "観点S!",
        "",
        "観点A!",
        "",
        "観点B!",
        "",
        "観点C1",
        "",
        "",
        false
      )
    },
    editableRubric: {},
    editableJournal: {
      rubric000: new EditableSolanJournal(
        repo,
        true,
        false,
        "project000",
        "rubric000",
        1,
        "ルーブリック000の学習活動",
        "ルーブリック000の観点S",
        "ルーブリック000の観点A",
        "ルーブリック000の観点B",
        "ルーブリック000の観点C",
        "student000",
        [],
        "ルーブリック000の児童生徒コメント",
        "",
        "A",
        "ルーブリック000の教師コメント",
        "",
        "S",
        true,
        true
      )
    },
    journalFile: {
      journalFile000: new SolanJournalFile(
        "project000",
        "journalFile000",
        "rubric000",
        "any",
        "any",
        "application/octet-stream",
        "myfilename",
        "ext",
        "gcs-object-path",
        "thumbnail-gcs-object-path",
        false,
        null,
        null,
        "2000-01-01T00:00:00Z",
        "2000-01-01T00:00:00Z"
      )
    }
  };
}
