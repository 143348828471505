





























import Vue, { PropType } from "vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { SolanProject } from "@/ts/objects/noneditable/solan/SolanProject";
import { SolanStoreT } from "@/store/SolanStore";
import { NavigationGuardNext, Route } from "vue-router";
import { messages } from "@/ts/const/Messages";
import TipBlock from "@/components/TipBlock.vue";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { SolanRepository } from "@/ts/repositories/SolanRepository";

export default Vue.extend({
  name: "SolanStudentViewAboutT",
  components: { TipBlock, MessageView, LoadingBlock },
  props: {
    solanStoreT: { type: Object as PropType<SolanStoreT>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true }
  },
  created() {
    this.reloadData();
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.reloadData();
    next();
  },
  data(): {
    messageView: MessageViewParam | null;

    project: SolanProject | null;
  } {
    return {
      messageView: null,

      project: null
    };
  },
  computed: {
    isProjectStarted(): boolean {
      return this.project?.started ?? false;
    },
    infoMessage(): string {
      if (this.isProjectStarted) return "";
      return "このテーマは、まだ準備中です。";
    }
  },
  methods: {
    async reloadData() {
      this.messageView = null;

      const studentUserId = this.$route.params["solanStudentUserId"];
      if (studentUserId === null || studentUserId === undefined) {
        this.messageView = { message: messages.pleaseSelectStudent };
        return;
      }

      await this.solanStoreT.reloadProjectsIfStudentChanged({
        userRepository: this.userRepository,
        solanRepository: this.solanRepository,
        studentUserId
      });

      const projectId = this.$route.params["solanProjectId"];
      if (projectId === null || projectId === undefined) {
        this.messageView = { message: messages.pleaseSelectSolanTheme };
        return;
      }

      const project = await this.solanStoreT.getProject(projectId).getDataWithTimeout();
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectSolanTheme };
        return;
      }

      this.project = project;
    }
  }
});
