import { parseUTCOrZero } from "@/ts/utils/AppUtil";
import { JournalFile } from "@/ts/objects/noneditable/JournalFile";

/**
 * 文書評価教科の学習活動記録を持つ児童生徒。
 *
 * リソース名は /eeCurriculums/{eecId}/journalStudents/{studentUserId}。
 *
 * 文書評価教科 : 学習活動記録児童生徒  = 1 : n
 */
export class EECJournalStudent {
  /**
   * コンストラクタ。
   *
   * @param eecId 文書評価教科ID。
   * @param studentUserId 児童生徒ID。
   */
  constructor(public readonly eecId: string, public readonly studentUserId: string) {}

  get resourceName(): string {
    return `/eeCurriculums/${this.eecId}/journalStudents/${this.studentUserId}`;
  }
}

export class EECJournalStudentTree {
  constructor(public readonly self: EECJournalStudent, public readonly journals: EECJournalTree[]) {}
}

/**
 * 文書評価教科の学習活動記録。
 *
 * リソース名は /eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId}。
 *
 * 学習活動記録児童生徒 : 学習活動記録  = 1 : n
 */
export class EECJournal {
  /**
   * コンストラクタ。
   *
   * @param eecId 文書評価教科ID。
   * @param studentUserId 児童生徒ID。
   * @param journalId 学習記録ID。
   * @param month
   * @param activity
   * @param studentComment
   * @param teacherComment
   * @param teacherInputPublished
   * @param createdAt
   * @param studentInputLocked
   */
  constructor(
    public readonly eecId: string,
    public readonly studentUserId: string,
    public readonly journalId: string,
    public readonly month: number,
    public readonly activity: string,
    public readonly studentComment: string,
    public readonly teacherComment: string,
    public readonly teacherInputPublished: boolean,
    createdAt: string,
    public readonly studentInputLocked: boolean
  ) {
    this.createdAt = parseUTCOrZero(createdAt);
  }

  readonly createdAt: Date;

  get resourceName(): string {
    return `/eeCurriculums/${this.eecId}/journalStudents/${this.studentUserId}/journals/${this.journalId}`;
  }
}

export class EECJournalTree {
  constructor(public readonly self: EECJournal, public readonly journalFiles: EECJournalFileTree[]) {}
}

/**
 * 文書評価教科の学習活動記録ファイル。
 *
 * リソース名は /eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId}/journalFiles/{journalFileId}。
 *
 * 学習活動記録 : 学習活動記録ファイル  = 1 : n
 */
export class EECJournalFile extends JournalFile {
  constructor(
    public readonly eecId: string,
    public readonly studentUserId: string,
    public readonly journalId: string,
    public readonly journalFileId: string,
    createdAt: string
  ) {
    super();
    this.createdAt = parseUTCOrZero(createdAt);
  }

  readonly createdAt: Date;

  readonly type: string = "type";
  readonly subtype: string = "subtype";
  readonly mediaType: string = "mediaType";

  readonly width: number | null = null;
  readonly height: number | null = null;

  readonly filename: string = "filename";
  readonly ext: string = "ext";

  readonly fileUrl: string | null = "fileUrl";
  readonly thumbnailUrl: string | null = "thumbnailUrl";

  readonly updatedAt: Date = new Date();
}

export class EECJournalFileTree {
  constructor(public readonly self: EECJournalFile) {}
}
