

















import Vue, { PropType } from "vue";
import { format } from "date-fns";
import { ActionLog } from "@/ts/objects/noneditable/ActionLog";
import { rnameToRnameObject } from "@/ts/utils/AppUtil";
import log from "loglevel";
import last from "lodash/last";
import { Quarter } from "@/ts/objects/noneditable/value/Quarter";

type ActionObject = {
  text: string;
  cssClass: string;
  linkPath: string;
};

export default Vue.extend({
  name: "ActionLogRow",
  props: {
    actionLog: { type: Object as PropType<ActionLog>, required: true },
    studentOrGuardianBasePath: { type: String, required: true },
    currentQuarter: { type: Object as PropType<Quarter>, required: true }
  },
  computed: {
    dateText(): string {
      return format(this.actionLog.updatedAt, "M/d");
    },
    timeText(): string {
      return format(this.actionLog.updatedAt, "H:mm");
    },
    actionObject(): ActionObject {
      switch (this.actionLog.objectService) {
        case "curriculum": {
          return {
            text: "教科学習",
            cssClass: "curriculum",
            linkPath: `${this.studentOrGuardianBasePath}/curriculum`
          };
        }

        case "project": {
          const rnameObject = rnameToRnameObject(this.actionLog.objectResourceName);
          log.debug(
            `rnameObject=${JSON.stringify(rnameObject)}, updatedPropertyCategory=${
              this.actionLog.updatedPropertyCategory
            }`
          );
          let linkPathTail = "";
          if (rnameObject === null || rnameObject.fragments.length < 2) {
            linkPathTail = "init";
          } else {
            linkPathTail = rnameObject.fragments[0].resourceId;
            const subCollectionId = last(rnameObject.fragments)?.collectionId;
            switch (subCollectionId) {
              case "lookbacks":
                linkPathTail += "/lookback";
                break;
              case "journals":
                linkPathTail += "/journals";
                break;
              case "journalFiles":
                linkPathTail += "/journals";
                break;
              default:
                break;
            }
          }

          return {
            text: "プロジェクト学習",
            cssClass: "project",
            linkPath: `${this.studentOrGuardianBasePath}/project/${this.currentQuarter.schoolYear}/${this.currentQuarter.quarter}/${linkPathTail}`
          };
        }

        case "solan": {
          const rnameObject = rnameToRnameObject(this.actionLog.objectResourceName);
          log.debug(
            `rnameObject=${JSON.stringify(rnameObject)}, updatedPropertyCategory=${
              this.actionLog.updatedPropertyCategory
            }`
          );
          let linkPathTail = "";
          if (rnameObject === null || rnameObject.fragments.length === 0) {
            linkPathTail = "init";
          } else if (rnameObject.fragments.length === 1) {
            linkPathTail = rnameObject.fragments[0].resourceId;
            if (this.actionLog.updatedPropertyCategory === "lookback") {
              linkPathTail += "/lookback";
            } else {
              linkPathTail += "/about";
            }
          } else {
            linkPathTail = rnameObject.fragments[0].resourceId;
            const subCollectionId = last(rnameObject.fragments)?.collectionId;
            switch (subCollectionId) {
              case "rubrics":
                if (this.actionLog.updatedPropertyCategory === "journal") {
                  linkPathTail += "/journals";
                } else {
                  linkPathTail += "/rubrics";
                }
                break;
              case "journalFiles":
                linkPathTail += "/journals";
                break;
              default:
                break;
            }
          }

          return {
            text: "SOLAN学習",
            cssClass: "solan",
            linkPath: `${this.studentOrGuardianBasePath}/solan/${this.currentQuarter.schoolYear}/${linkPathTail}`
          };
        }

        default:
          return {
            text: "",
            cssClass: "",
            linkPath: ""
          };
      }
    }
  }
});
