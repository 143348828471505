export const messages = {
  projectUnpublished: "プロジェクトは非公開です。",
  pleaseSelectProject: "プロジェクトを選択してください。",
  solanThemeNotStarted: "テーマは準備中です。",
  pleaseSelectSolanTheme: "テーマを決めましょう。",
  pleaseSelectStudent: "児童生徒を選択してください。",
  pleaseSelectClass: "クラスを選択してください。",
  failedToLoadData: "データを取得できません。",
  pageNotFound: "ページが見つかりません。",
  error: "エラーが発生しました。"
};
