import { projectRubricRnameToId } from "@/ts/utils/AppUtil";

export class ProjectRubric {
  readonly self: string;
  readonly projectId: string;
  readonly rubricId: string;
  readonly orderNum: number;
  readonly learningActivity: string;
  readonly viewPointS: string;
  readonly viewPointA: string;
  readonly viewPointB: string;
  readonly viewPointC: string;

  constructor(
    self: string,
    orderNum: number,
    learningActivity: string,
    viewPointS: string,
    viewPointA: string,
    viewPointB: string,
    viewPointC: string
  ) {
    this.self = self;
    [this.projectId, this.rubricId] = projectRubricRnameToId(self) ?? ["unknown", "unknown"]; // TODO これどうにかする？
    this.orderNum = orderNum;
    this.learningActivity = learningActivity;
    this.viewPointS = viewPointS;
    this.viewPointA = viewPointA;
    this.viewPointB = viewPointB;
    this.viewPointC = viewPointC;
  }
}
