export abstract class JournalFile {
  abstract readonly type: string;
  abstract readonly subtype: string;
  abstract readonly mediaType: string;

  abstract readonly filename: string;
  abstract readonly ext: string;

  abstract readonly width: number | null;
  abstract readonly height: number | null;

  abstract readonly fileUrl: string | null;
  abstract readonly thumbnailUrl: string | null;

  abstract readonly updatedAt: Date;

  private _markedAsDeleting: boolean = false;

  markAsDeleting() {
    this._markedAsDeleting = true;
  }

  get markedAsDeleting(): boolean {
    return this._markedAsDeleting;
  }
}
