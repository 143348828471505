import { StudentClassInfo } from "@/ts/objects/noneditable/StudentClassInfo";
import { Class, ClassStudent } from "@/ts/objects/noneditable/Class";
import { StudentInfo } from "@/ts/StudentInfo";
import uniq from "lodash/uniq";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import orderBy from "lodash/orderBy";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { Grade } from "@/ts/objects/noneditable/value/Grade";

export abstract class UserState {
  abstract userId: string;
  abstract readonly isTeacher: boolean;
  abstract readonly isStudent: boolean;
  abstract readonly isGuardian: boolean;
}

export class TeacherState extends UserState {
  userId: string;
  readonly isTeacher = true;
  readonly isStudent = false;
  readonly isGuardian = false;

  readonly name: string;
  readonly iconUrl: string;

  /**
   * 年度ごとにグループ化され、年度の降順に並べ替えられたクラス一覧。
   */
  private readonly _orderedGroupedClasses: { schoolYear: number; selections: Class[] }[];

  /**
   * _orderedGroupedClassesをflattenしたもの。
   */
  private readonly _orderedClasses: Class[];

  private _selectedClass: Class | null = null;

  constructor(userId: string, name: string, iconUrl: string, classes: Class[]) {
    super();
    this.userId = userId;
    this.name = name;
    this.iconUrl = iconUrl;

    const grouped = Object.entries(groupBy(classes, "schoolYear")).map(([schoolYear, selections]) => {
      return {
        schoolYear: parseInt(schoolYear, 10),
        selections: sortBy(selections, ["schoolType", "grade", "classNo", "name"])
      };
    });
    this._orderedGroupedClasses = orderBy(grouped, ["schoolYear"], ["desc"]);
    this._orderedClasses = this._orderedGroupedClasses.flatMap(group => group.selections);
  }

  allClasses(): Class[] {
    return this._orderedClasses;
  }

  orderedGroupedClasses(): { schoolYear: number; selections: Class[] }[] {
    return this._orderedGroupedClasses;
  }

  /**
   * クラスを選択状態にする。
   *
   * 直接変更せずに、routerのナビゲーションガード(beforeEach)からのみ変更すること。（それにより、パスと同期できる）
   * @param classId
   */
  selectClass(classId: string) {
    this._selectedClass = this._orderedClasses.find(c => c.id === classId) ?? null;
  }

  firstClassId(): string | null {
    const classes = this._orderedClasses;
    const firstClass = classes.length > 0 ? classes[0] : null;
    return firstClass?.id ?? null;
  }

  prevClassId(): string | null {
    const classes = this._orderedClasses;
    if (classes.length === 0) return null;

    const current = this._selectedClass;
    const currentIdx = classes.findIndex(c => c.id === current?.id);
    if (currentIdx < 0) {
      return null;
    } else if (currentIdx === 0) {
      return classes[classes.length - 1].id;
    } else {
      return classes[currentIdx - 1].id;
    }
  }

  nextClassId(): string | null {
    const classes = this._orderedClasses;
    if (classes.length === 0) return null;

    const current = this._selectedClass;
    const currentIdx = this._orderedClasses.findIndex(c => c.id === current?.id);
    if (currentIdx < 0) {
      return null;
    } else if (currentIdx < classes.length - 1) {
      return classes[currentIdx + 1].id;
    } else {
      return classes[0].id;
    }
  }

  selectedClass(): Class | null {
    return this._selectedClass;
  }

  async studentsOfSelectedClass(userRepository: UserRepository): Promise<ClassStudent[]> {
    const cls = this._selectedClass;
    if (cls === null) return [];
    return cls.sortedClassStudents(userRepository);
  }

  async studentOfSelectedClass(userRepository: UserRepository, studentUserId: string): Promise<ClassStudent | null> {
    const cls = this._selectedClass;
    if (cls === null) return null;
    const classStudents = await cls.classStudents(userRepository);
    return classStudents.get(studentUserId) ?? null;
  }
}

export abstract class StudentOrGuardianState extends UserState {
  abstract studentInfo(): StudentInfo | null;

  abstract studentUserId(): string | null;

  abstract studentClasses(): StudentClassInfo[] | null;

  abstract studentClassOfSchoolYear(schoolYear: number): StudentClassInfo | null;

  studentGradeOfSchoolYear(schoolYear: number): Grade | null {
    return this.studentClassOfSchoolYear(schoolYear)?.grade ?? null;
  }

  /**
   * 児童生徒/保護者が選択可能な年度の最小値。
   * プロジェクト学習やSOLAN学習の年度/学期選択で用いる。
   */
  abstract studentMinSchoolYear(): number | null;

  /**
   * 児童生徒/保護者が選択可能な年度の最大値。
   * プロジェクト学習やSOLAN学習の年度/学期選択で用いる。
   */
  abstract studentMaxSchoolYear(): number | null;
}

export class StudentState extends StudentOrGuardianState {
  userId: string;
  readonly isTeacher = false;
  readonly isStudent = true;
  readonly isGuardian = false;

  readonly name: string;
  readonly iconUrl: string;

  private readonly _classes: StudentClassInfo[];
  private readonly _minSchoolYear: number | null;
  private readonly _maxSchoolYear: number | null;

  constructor(userId: string, name: string, iconUrl: string, classes: StudentClassInfo[]) {
    super();
    this.userId = userId;
    this.name = name;
    this.iconUrl = iconUrl;
    this._classes = classes;

    const schoolYears = uniq(classes.map(c => c.schoolYear));
    this._minSchoolYear = schoolYears.length > 0 ? Math.min(...schoolYears) : null;
    this._maxSchoolYear = schoolYears.length > 0 ? Math.max(...schoolYears) : null;
  }

  studentInfo(): StudentInfo | null {
    return new StudentInfo(this.userId, this.name, this.iconUrl, this._classes);
  }

  studentUserId(): string | null {
    return this.userId;
  }

  studentClasses(): StudentClassInfo[] | null {
    return this._classes;
  }

  studentClassOfSchoolYear(schoolYear: number): StudentClassInfo | null {
    return this._classes.find(c => c.schoolYear === schoolYear) ?? null;
  }

  studentMinSchoolYear(): number | null {
    return this._minSchoolYear;
  }

  studentMaxSchoolYear(): number | null {
    return this._maxSchoolYear;
  }
}

export class GuardianState extends StudentOrGuardianState {
  userId: string;
  readonly isTeacher = false;
  readonly isStudent = false;
  readonly isGuardian = true;

  students: StudentInfo[];
  private _selectedStudent: StudentInfo | null = null;

  constructor(userId: string, students: StudentInfo[]) {
    super();
    this.userId = userId;
    this.students = students;
  }

  /**
   * 児童生徒を選択する。
   * 直接変更せずに、ナビゲーションガードからのみ変更すること。（それにより、パスと同期できる）
   */
  selectStudent(studentUserId: string) {
    this._selectedStudent = this.students.find(s => s.studentUserId === studentUserId) ?? null;
  }

  studentInfo(): StudentInfo | null {
    return this._selectedStudent;
  }

  studentUserId(): string | null {
    return this._selectedStudent?.studentUserId ?? null;
  }

  studentClasses(): StudentClassInfo[] | null {
    return this._selectedStudent?.classes ?? null;
  }

  studentClassOfSchoolYear(schoolYear: number): StudentClassInfo | null {
    return this._selectedStudent?.classes.find(c => c.schoolYear === schoolYear) ?? null;
  }

  studentMinSchoolYear(): number | null {
    return this._selectedStudent?._minSchoolYear ?? null;
  }

  studentMaxSchoolYear(): number | null {
    return this._selectedStudent?._maxSchoolYear ?? null;
  }

  firstStudentId(): string | null {
    if (this.students.length === 0) return null;
    return this.students[0].studentUserId;
  }
}
