import { RatingValue } from "@/ts/objects/noneditable/value/Rating";
import { MonthValue } from "@/ts/utils";

/**
 * 数値評価教科の内容構成の1か月分の評価。
 *
 * リソース名は /neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}/months/{1, 2, ..., 12}/evaluations/{studentUserId}。
 */
export class NECEvaluation {
  constructor(
    public readonly necId: string,
    public readonly viewPointId: string,
    public readonly contentId: string,
    public readonly month: MonthValue,
    public readonly studentUserId: string,
    public readonly rating: RatingValue,
    public readonly teacherInputPublished: boolean
  ) {}

  get contentResourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}`;
  }

  get monthResourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}/months/${this.month}`;
  }

  get resourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}/months/${this.month}/evaluations/${this.studentUserId}`;
  }
}
