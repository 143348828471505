







export default {
  name: "OpenSyllabusFileButton",
  props: {
    onClick: {
      type: Function,
      default: () => {}
    }
  }
};
