






































import Vue, { PropType } from "vue";
import { CheckboxItem } from "@/components/FilterableHeaderButton.vue";
import { ProjectStore } from "@/store/ProjectStore";
import { Err } from "@/ts/objects/Err";
import { NavigationGuardNext, Route } from "vue-router";
import { EditableProjectStudent } from "@/ts/objects/editable/project/EditableProjectStudent";
import { EditableProjectJournal } from "@/ts/objects/editable/project/EditableProjectJournal";
import { EditableProjectLookback } from "@/ts/objects/editable/project/EditableProjectLookback";
import { ProjectRubric } from "@/ts/objects/noneditable/project/ProjectRubric";
import { messages } from "@/ts/const/Messages";
import { MessageViewParam } from "@/components/MessageView.vue";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import { AppStateStore } from "@/store/AppStateStore";
import { unparse } from "papaparse";
import { downloadBlob } from "@/ts/utils/AppUtil";
import { format } from "date-fns";
import { MenuButton } from "@/components/PopupMenuButton.vue";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";
import { UserRepository } from "@/ts/repositories/UserRepository";
import ProjectJournalsTPure from "@/views/project/teacher/ProjectJournalsT/ProjectJournalsTPure.vue";
import { ErrorNotificationParam } from "@/components/ErrorNotification.vue";

// TODO needSaveがちゃんと働いていない。studentsに対してリアクティブになっていない気がする。
// TODO col-filterを切り替えた時、選択状態が解除されたりされなかったりする。なぜ？

export type EmptyFilterCheckState = {
  empty: boolean;
  hasValue: boolean;
};

export type RowFilterState = {
  student: Record<string, boolean>;
  learningActivity: Record<string, boolean>;
  teacherRating: EmptyFilterCheckState;
  teacherComment: EmptyFilterCheckState;
  guardianComment: EmptyFilterCheckState;
};

export type ColFilterState = {
  learningActivity: boolean;
  journalFiles: boolean;
  ratingsAndComments: boolean;
  guardianComment: boolean;
};

/**
 * 児童生徒行。
 */
export type StudentRow = {
  student: EditableProjectStudent;

  /**
   * 現在表示中の学習記録行のリスト。
   */
  journalRows: JournalRow[];

  /**
   * 現在表示中のふりかえり行。
   */
  lookbackRow: LookbackRow | null;
};

/**
 * 現在表示中の学習記録行。
 */
export type JournalRow = {
  journal: EditableProjectJournal;
  selected: boolean;
};

/**
 * 現在表示中のふりかえり行。
 */
export type LookbackRow = {
  lookback: EditableProjectLookback;
  selected: boolean;
};

export default Vue.extend({
  name: "ProjectJournalsT",
  components: {
    ProjectJournalsTPure
  },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    projectStore: { type: Object as PropType<ProjectStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    projectRepository: { type: Object as PropType<ProjectRepository>, required: true }
  },
  created() {
    const vm = this;
    this.periodicSaverId = window.setInterval(() => vm.saveAll(false), 15000);
    this.pageLeaveService = new PageLeaveService(
      async () => {
        await this.saveAll(true);
      },
      async () => !this.needSave,
      4,
      "移動すると未保存の入力が失われます。よろしいですか？",
      async () => {},
      async () => {
        this.highlightUnsaved = true;
      }
    );

    this.projectStore.project.getDataWithTimeout().then(project => {
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectProject };
        return;
      }
      if (!project.published) {
        this.messageView = { message: messages.projectUnpublished };
        return;
      }

      this.projectRepository
        .listEditableProjectStudents(
          this.userRepository,
          project.projectId,
          this.appStateStore.teacherState?.selectedClass() ?? null,
          true,
          false,
          false
        )
        .then(resp => {
          if (resp instanceof Err) {
            log.debug("Error loading journals!");
            this.messageView = { message: messages.failedToLoadData, fadeIn: true };
            return;
          }

          this.students = resp.editableStudents;
          this.studentRowFilterCheckboxItems = resp.editableStudents.map(student => {
            return {
              key: student.studentUserId,
              label: `${student.studentNumber}番 ${student.name}`
            };
          });
          this.rubrics = resp.rubrics;
          this.learningActivityRowFilterCheckboxItems = [
            ...resp.rubrics.map(rubric => {
              return {
                key: rubric.self,
                label: rubric.learningActivity
              };
            }),
            { key: "lookback", label: "プロジェクトのふりかえり" }
          ];
        });
    });
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    messageView: MessageViewParam | null;

    periodicSaverId: number | undefined;

    extraMenuItems: MenuButton[];

    students: EditableProjectStudent[] | null;
    rubrics: ProjectRubric[] | null;

    studentRowFilterCheckboxItems: CheckboxItem[];
    learningActivityRowFilterCheckboxItems: CheckboxItem[];
    teacherRatingRowFilterCheckboxItems: CheckboxItem[];
    teacherCommentRowFilterCheckboxItems: CheckboxItem[];
    guardianCommentRowFilterCheckboxItems: CheckboxItem[];

    rowFilterState: RowFilterState;
    colFilterState: ColFilterState;

    studentRows: StudentRow[];

    filesViewJournal: EditableProjectJournal | null;

    highlightUnsaved: boolean;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      messageView: null,

      periodicSaverId: undefined,

      extraMenuItems: [new MenuButton("exportCsv", "現在の状態をCSV出力", ["fas", "download"])],

      students: null,
      rubrics: null,

      studentRowFilterCheckboxItems: [],
      learningActivityRowFilterCheckboxItems: [],
      teacherRatingRowFilterCheckboxItems: [
        { key: "empty", label: "未入力" },
        { key: "hasValue", label: "入力済" }
      ],
      teacherCommentRowFilterCheckboxItems: [
        { key: "empty", label: "未入力" },
        { key: "hasValue", label: "入力済" }
      ],
      guardianCommentRowFilterCheckboxItems: [
        { key: "empty", label: "未入力" },
        { key: "hasValue", label: "入力済" }
      ],

      /**
       * 行フィルタの状態。
       */
      rowFilterState: {
        student: {},
        learningActivity: {},
        teacherRating: { empty: true, hasValue: true },
        teacherComment: { empty: true, hasValue: true },
        guardianComment: { empty: true, hasValue: true }
      },

      /**
       * 列フィルタの状態。
       */
      colFilterState: {
        learningActivity: true,
        journalFiles: true,
        ratingsAndComments: true,
        guardianComment: true
      },

      /**
       * 現在表示中の児童生徒行のリスト。
       * ※ 各々が選択状態を持つので、computedにはできない。
       */
      studentRows: [],

      filesViewJournal: null,

      highlightUnsaved: false,

      pageLeaveService: null
    };
  },
  computed: {
    needSave(): boolean {
      const students = this.students;
      if (students === null) return false;
      // return students.some(s => s.needSave());
      const needSave = students.some(s => s.needSave());
      log.debug(`ProjectJournalsT: needSave=${needSave}`);
      return needSave;
    },
    currentErrors(): ErrorNotificationParam[] {
      return (
        this.students?.flatMap(s =>
          s.currentErrors().map(e => {
            return {
              heading: `${s.studentNumber}番 ${s.name}`,
              text: `${e.message}`
            };
          })
        ) ?? []
      );
    }
  },
  methods: {
    async saveAll(force: boolean): Promise<SaveResult> {
      const students = this.students;
      if (students === null) return emptySaveResult();
      log.debug("SAVING!");
      return flattenSaveResults(await Promise.all(students.map(s => s.saveAllChanges(force))));
    },

    toggleLearningActivityColFilter() {
      this.colFilterState.learningActivity = !this.colFilterState.learningActivity;
    },
    toggleJournalFilesColFilter() {
      this.colFilterState.journalFiles = !this.colFilterState.journalFiles;
      this.filesViewJournal = null;
    },
    toggleRatingsAndCommentsColFilter() {
      this.colFilterState.ratingsAndComments = !this.colFilterState.ratingsAndComments;
    },
    toggleGuardianCommentColFilter() {
      this.colFilterState.guardianComment = !this.colFilterState.guardianComment;
    },

    onStudentRowFilterChanged(state: Record<string, boolean>) {
      this.rowFilterState.student = state;
      this.applyRowFilter();
    },
    onLearningActivityRowFilterChanged(state: Record<string, boolean>) {
      this.rowFilterState.learningActivity = state;
      this.applyRowFilter();
    },
    onTeacherRatingRowFilterChanged(state: EmptyFilterCheckState) {
      this.rowFilterState.teacherRating = state;
      this.applyRowFilter();
    },
    onTeacherCommentRowFilterChanged(state: EmptyFilterCheckState) {
      this.rowFilterState.teacherComment = state;
      this.applyRowFilter();
    },
    onGuardianCommentRowFilterChanged(state: EmptyFilterCheckState) {
      this.rowFilterState.guardianComment = state;
      this.applyRowFilter();
    },

    applyRowFilter() {
      const students = this.students;
      if (students === null) return [];

      // 画面初期表示時に何度も呼ばれるので、もし重ければなんとかする。
      const rowFilterState = this.rowFilterState;

      const filteredStudents = students.filter(s => rowFilterState.student[s.studentUserId] === true);
      const filteredStudentRows = filteredStudents
        .map(student => {
          return {
            student: student,
            journalRows: student.projectJournals
              .filter(j => shouldDisplayJournal(j, rowFilterState))
              .map(j => {
                return {
                  journal: j,
                  selected: false
                };
              }),
            lookbackRow: shouldDisplayLookback(student.projectLookback, rowFilterState)
              ? {
                  lookback: student.projectLookback,
                  selected: false
                }
              : null
          };
        })
        .filter(fjs => fjs.journalRows.length > 0 || fjs.lookbackRow !== null);
      this.studentRows = filteredStudentRows;
    },

    toggleSelectionOfJournal(checked: boolean, studentUserId: string, journal: string) {
      const studentRow = this.studentRows.find(sr => sr.student.studentUserId === studentUserId);
      if (studentRow === undefined) return;
      const journalRow = studentRow.journalRows.find(jr => jr.journal.self === journal);
      if (journalRow === undefined) return;
      log.debug(`toggleSelectionOfJournal: from ${journalRow.selected}`);
      journalRow.selected = checked;
      log.debug(`toggleSelectionOfJournal: to ${journalRow.selected}`);
    },
    toggleSelectionOfLookback(checked: boolean, studentUserId: string, lookback: string) {
      const studentRow = this.studentRows.find(sr => sr.student.studentUserId === studentUserId);
      if (studentRow === undefined) return;
      const lookbackRow = studentRow.lookbackRow;
      if (lookbackRow === null || lookbackRow.lookback.self !== lookback) return;
      log.debug(`toggleSelectionOfLookback: from ${lookbackRow.selected}`);
      lookbackRow.selected = checked;
      log.debug(`toggleSelectionOfLookback: to ${lookbackRow.selected}`);
    },
    changeAllSelections(changeTo: boolean) {
      // 参考: https://github.com/vuejs/vue/issues/9535#issuecomment-466217819
      // TODO WindowsのChrome以外でもちゃんと動くか確認。
      log.debug(`changeAllSelections: changeTo=${changeTo}, studentRows.length=${this.studentRows.length}`);
      setTimeout(() => {
        log.debug(`changeAllSelections: setTimeout`);
        for (const sr of this.studentRows) {
          for (const jr of sr.journalRows) {
            jr.selected = changeTo;
          }
          if (sr.lookbackRow !== null) {
            sr.lookbackRow.selected = changeTo;
          }
        }
      });
    },
    publishSelectedRows() {
      changeSelectedRowsPublishState(true, this.studentRows);
      this.saveAll(false);
    },
    unpublishSelectedRows() {
      changeSelectedRowsPublishState(false, this.studentRows);
      this.saveAll(false);
    },
    setFilesView(studentUserId: string | null, journalRname: string | null) {
      if (studentUserId === null || journalRname === null) {
        this.filesViewJournal = null;
        return;
      }

      const studentRow: StudentRow | undefined = this.studentRows.find(s => s.student.studentUserId === studentUserId);
      if (studentRow === undefined) {
        this.filesViewJournal = null;
        return;
      }

      const journalRow = studentRow.journalRows.find(j => j.journal.self === journalRname);
      if (journalRow === undefined) {
        this.filesViewJournal = null;
        return;
      }
      this.filesViewJournal = journalRow.journal;
    },
    selectExtraMenu(menuItem: string) {
      switch (menuItem) {
        case "exportCsv":
          this.exportCsv();
          break;
      }
    },
    exportCsv() {
      const projectName = this.projectStore.project.data?.name ?? null;
      if (projectName === null) return;
      exportCsv(this.studentRows, this.colFilterState, projectName);
    }
  }
});

function changeSelectedRowsPublishState(changeTo: boolean, studentRows: StudentRow[]) {
  studentRows.forEach(sr => {
    sr.journalRows
      .filter(jr => jr.selected)
      .forEach(jr => {
        jr.journal.teacherInputPublished = changeTo;
      });
    if (sr.lookbackRow !== null && sr.lookbackRow.selected) {
      sr.lookbackRow.lookback.teacherInputPublished = changeTo;
    }
  });
}

function shouldDisplayJournal(journal: EditableProjectJournal, rowFilterState: RowFilterState): boolean {
  const learningActivityOk = rowFilterState.learningActivity[journal.rubric];
  const teacherRatingOk =
    (rowFilterState.teacherRating.empty && journal.teacherRating === "") ||
    (rowFilterState.teacherRating.hasValue && journal.teacherRating !== "");
  const teacherCommentOk =
    (rowFilterState.teacherComment.empty && journal.teacherComment === "") ||
    (rowFilterState.teacherComment.hasValue && journal.teacherComment !== "");
  const guardianCommentOk = rowFilterState.guardianComment.empty;
  return learningActivityOk && teacherRatingOk && teacherCommentOk && guardianCommentOk;
}

function shouldDisplayLookback(lookback: EditableProjectLookback, rowFilterState: RowFilterState): boolean {
  const learningActivityOk = rowFilterState.learningActivity["lookback"];
  const teacherRatingOk =
    (rowFilterState.teacherRating.empty && lookback.teacherRating === "") ||
    (rowFilterState.teacherRating.hasValue && lookback.teacherRating !== "");
  const teacherCommentOk =
    (rowFilterState.teacherComment.empty && lookback.teacherComment === "") ||
    (rowFilterState.teacherComment.hasValue && lookback.teacherComment !== "");
  const guardianCommentOk =
    (rowFilterState.guardianComment.empty && lookback.guardianComment === "") ||
    (rowFilterState.guardianComment.hasValue && lookback.guardianComment !== "");
  return learningActivityOk && teacherRatingOk && teacherCommentOk && guardianCommentOk;
}

function exportCsv(studentRows: StudentRow[], colFilterState: ColFilterState, projectName: string) {
  const csvRows = studentRows.flatMap(s => {
    const journalCsvRows = s.journalRows.map(j => {
      return {
        番号: s.student.studentNumber,
        氏名: s.student.name,
        学習活動: j.journal.rubricLearningActivity,
        学習の記録: j.journal.journalFiles.length,
        "学習のふりかえり(コメント)": j.journal.studentComment,
        "学習のふりかえり(評価)": j.journal.studentRating,
        先生評価: j.journal.teacherRating,
        先生から: j.journal.teacherComment,
        保護者から: "",
        状態: j.journal.teacherInputPublished ? "公開" : "非公開"
      };
    });
    if (s.lookbackRow === null) {
      return journalCsvRows;
    }

    const lookbackRow = {
      番号: s.student.studentNumber,
      氏名: s.student.name,
      学習活動: "ふりかえり",
      学習の記録: 0,
      "学習のふりかえり(コメント)": s.lookbackRow.lookback.studentComment,
      "学習のふりかえり(評価)": s.lookbackRow.lookback.studentRating,
      先生評価: s.lookbackRow.lookback.teacherRating,
      先生から: s.lookbackRow.lookback.teacherComment,
      保護者から: s.lookbackRow.lookback.guardianComment,
      状態: s.lookbackRow.lookback.teacherInputPublished ? "公開" : "非公開"
    };

    return [...journalCsvRows, lookbackRow];
  });
  const columnNames = [
    "番号",
    "氏名",
    colFilterState.learningActivity ? "学習活動" : undefined,
    colFilterState.journalFiles ? "学習の記録" : undefined,
    colFilterState.ratingsAndComments ? "学習のふりかえり(コメント)" : undefined,
    colFilterState.ratingsAndComments ? "学習のふりかえり(評価)" : undefined,
    colFilterState.ratingsAndComments ? "先生評価" : undefined,
    colFilterState.ratingsAndComments ? "先生から" : undefined,
    colFilterState.guardianComment ? "保護者から" : undefined,
    "状態"
  ].filter((c: string | undefined): c is string => c !== undefined);

  // BOMはエクセル対策。参考: https://qiita.com/wadahiro/items/eb50ac6bbe2e18cf8813
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, unparse(csvRows, { columns: columnNames })], { type: "text/plain" });
  downloadBlob(blob, `${projectName}_${format(new Date(), "yyyyMMdd'T'HHmmss")}.csv`);
}
