import Vue from "vue";
import log from "loglevel";
import { parseUTCOrZero } from "@/ts/utils/AppUtil";
import { JournalFile } from "@/ts/objects/noneditable/JournalFile";
import { isNullish } from "@/ts/utils/CommonUtil";

export class SolanJournalFile extends JournalFile {
  readonly projectId: string;
  readonly journalFileId: string;
  readonly rubricId: string;
  readonly type: string;
  readonly subtype: string;
  readonly mediaType: string;
  readonly filename: string;
  readonly ext: string;
  readonly gcsObjectPath: string;
  readonly thumbnailGcsObjectPath: string | null;
  readonly hasThumbnail: boolean;
  readonly width: number | null;
  readonly height: number | null;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  private _fileUrl: string | null = null;
  private _thumbnailUrl: string | null = null;

  constructor(
    projectId: string,
    journalFileId: string,
    rubricId: string,
    type: string,
    subtype: string,
    mediaType: string,
    filename: string,
    ext: string,
    gcsObjectPath: string,
    thumbnailGcsObjectPath: string | null,
    hasThumbnail: boolean,
    width: number | null,
    height: number | null,
    createdAt: string,
    updatedAt: string
  ) {
    super();
    this.projectId = projectId;
    this.journalFileId = journalFileId;
    this.rubricId = rubricId;
    this.type = type;
    this.subtype = subtype;
    this.mediaType = mediaType;
    this.filename = filename;
    this.ext = ext;
    this.gcsObjectPath = gcsObjectPath;
    this.thumbnailGcsObjectPath = thumbnailGcsObjectPath;
    this.hasThumbnail = hasThumbnail;
    this.width = width;
    this.height = height;
    this.createdAt = parseUTCOrZero(createdAt);
    this.updatedAt = parseUTCOrZero(updatedAt);

    this.initUrls(hasThumbnail, gcsObjectPath, thumbnailGcsObjectPath);
  }

  private async initUrls(
    hasThumbnail: boolean,
    gcsObjectPath: string,
    thumbnailGcsObjectPath: string | null
  ): Promise<void> {
    if (hasThumbnail && !isNullish(thumbnailGcsObjectPath)) {
      const fileRef = Vue.prototype.$storage?.ref(gcsObjectPath);
      const thumbnailRef = Vue.prototype.$storage?.ref(thumbnailGcsObjectPath);
      if (fileRef === undefined || thumbnailRef === undefined) return;

      const [fileUrl, thumbnailUrl] = await Promise.all([
        fileRef.getDownloadURL(),
        thumbnailRef.getDownloadURL()
      ]).catch((e: any) => {
        log.info(`SolanJournalFile.initUrl(): error=${JSON.stringify(e)}`);
        return ["", ""];
      });
      log.debug(`SolanJournalFile.fileUrl(): fileUrl=${fileUrl}, thumbnailUrl=${thumbnailUrl}`);
      this._fileUrl = fileUrl;
      this._thumbnailUrl = thumbnailUrl;
    } else {
      const fileRef = Vue.prototype.$storage?.ref(gcsObjectPath);
      if (fileRef === undefined) return;

      const fileUrl = await fileRef.getDownloadURL().catch((e: any) => {
        log.info(`SolanJournalFile.initUrl(): error=${JSON.stringify(e)}`);
        return ["", ""];
      });
      log.debug(`SolanJournalFile.fileUrl(): fileUrl=${fileUrl}`);
      this._fileUrl = fileUrl;
    }
  }

  get fileUrl(): string | null {
    return this._fileUrl;
  }

  get thumbnailUrl(): string | null {
    return this._thumbnailUrl;
  }
}
