import { render, staticRenderFns } from "./SolanHeaderPure.vue?vue&type=template&id=95c248da&scoped=true&"
import script from "./SolanHeaderPure.vue?vue&type=script&lang=ts&"
export * from "./SolanHeaderPure.vue?vue&type=script&lang=ts&"
import style0 from "./SolanHeaderPure.vue?vue&type=style&index=0&id=95c248da&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95c248da",
  null
  
)

export default component.exports