































































import Vue, { PropType } from "vue";
import { NECViewPointTree } from "@/ts/objects/noneditable/curriculum/NECurriculum";
import { NECEvaluation } from "@/ts/objects/noneditable/curriculum/NECEvaluation";
import { Loadable } from "@/ts/Loadable";
import { CurriculumTabName } from "@/ts/objects/enum/CurriculumTabName";
import { CurriculumPeriodMode } from "@/ts/objects/enum/CurriculumPeriodMode";
import RouterLinkWrapper from "@/components/RouterLinkWrapper.vue";
import TipBlock from "@/components/TipBlock.vue";

export default Vue.extend({
  name: "CurriculumReadNECYearlyViewPointRow",
  components: { TipBlock, RouterLinkWrapper },
  props: {
    /**
     * 数値評価教科の観点。
     */
    viewPointTree: { type: Object as PropType<NECViewPointTree>, required: true },

    /**
     * 現在表示中の教科・児童生徒配下の評価。
     */
    evaluations: { type: Object as PropType<Loadable<NECEvaluation[]>>, required: true },

    /**
     * 教科学習のパスを取得する関数。
     */
    getPath: {
      type: Function as PropType<
        (
          tab: CurriculumTabName,
          periodMode: CurriculumPeriodMode | null,
          month: number | null,
          resourceName: string | null
        ) => string
      >,
      required: true
    }
  },
  data(): { months: number[] } {
    return {
      months: [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3]
    };
  },
  methods: {
    evaluationOf(viewPointId: string, contentId: string, month: number): NECEvaluation | null {
      return (
        this.evaluations.data?.find(
          e => e.viewPointId === viewPointId && e.contentId === contentId && e.month === month
        ) ?? null
      );
    }
  }
});
