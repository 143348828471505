import { DefaultApi as CurriculumDefaultApi } from "@/ts/api/curriculum-service";
import { Grade } from "@/ts/objects/noneditable/value/Grade";
import {
  NECContent,
  NECContentMonth,
  NECContentMonthTree,
  NECContentTree,
  NECurriculum,
  NECurriculumTree,
  NECViewPoint,
  NECViewPointTree
} from "@/ts/objects/noneditable/curriculum/NECurriculum";
import { Err } from "@/ts/objects/Err";
import {
  EECActivity,
  EECActivityTree,
  EECMonth,
  EECMonthTree,
  EECurriculum,
  EECurriculumTree
} from "@/ts/objects/noneditable/curriculum/EECurriculum";
import { NECEvaluation } from "@/ts/objects/noneditable/curriculum/NECEvaluation";
import {
  EECJournal,
  EECJournalFile,
  EECJournalFileTree,
  EECJournalStudent,
  EECJournalStudentTree,
  EECJournalTree
} from "@/ts/objects/noneditable/curriculum/EECJournal";
import { Configuration } from "@/ts/api/log-service";
import { AxiosInstance } from "axios";
import { doReq } from "@/ts/Services";
import { Color } from "@/ts/objects/noneditable/value/Color";
import { isMonthValue } from "@/ts/utils";
import { isRatingValue } from "@/ts/objects/noneditable/value/Rating";
import uniq from "lodash/uniq";

export abstract class CurriculumRepository {
  abstract listNECurriculums(schoolYear: number, grade: Grade): Promise<NECurriculumTree[] | Err>;

  abstract listEECurriculums(schoolYear: number, grade: Grade): Promise<EECurriculumTree[] | Err>;

  abstract listNECEvaluations(
    necId: string,
    viewPointId?: string,
    contentId?: string,
    month?: number,
    studentUserId?: string
  ): Promise<NECEvaluation[] | Err>;

  abstract listEECJournalStudents(eecId: string, studentUserId?: string): Promise<EECJournalStudentTree[] | Err>;
}

export class CurriculumRepositoryImpl extends CurriculumRepository {
  private readonly curriculumService: CurriculumDefaultApi;

  constructor(serviceBasePath: string, axiosConf: Configuration | undefined, axiosInstance: AxiosInstance) {
    super();
    this.curriculumService = new CurriculumDefaultApi(axiosConf, serviceBasePath, axiosInstance);
  }

  async listNECurriculums(schoolYear: number, grade: Grade): Promise<NECurriculumTree[] | Err> {
    const [necResp, viewPointResp, contentResp, contentMonthResp] = await Promise.all([
      doReq(() => this.curriculumService.listNECurriculum(schoolYear, grade.value, undefined)),
      doReq(() => this.curriculumService.listViewPoint("-", undefined, schoolYear, grade.value)),
      doReq(() => this.curriculumService.listContent("-", "-", undefined, schoolYear, grade.value)),
      doReq(() =>
        this.curriculumService.listContentMonth(
          "-",
          "-",
          "-",
          undefined,
          undefined,
          undefined,
          schoolYear,
          grade.value,
          undefined
        )
      )
    ]);
    if (necResp instanceof Err) return necResp;
    if (viewPointResp instanceof Err) return viewPointResp;
    if (contentResp instanceof Err) return contentResp;
    if (contentMonthResp instanceof Err) return contentMonthResp;

    return necResp.map(
      nec =>
        new NECurriculumTree(
          new NECurriculum(nec.necId, nec.schoolYear, new Grade(nec.grade), nec.name.value),
          viewPointResp
            .filter(vp => vp.necId === nec.necId)
            .map(
              vp =>
                new NECViewPointTree(
                  new NECViewPoint(
                    vp.necId,
                    vp.viewPointId,
                    vp.name.value,
                    new Color(vp.color.red, vp.color.green, vp.color.blue)
                  ),
                  contentResp
                    .filter(c => c.necId === vp.necId && c.viewPointId === vp.viewPointId)
                    .map(
                      c =>
                        new NECContentTree(
                          new NECContent(c.necId, c.viewPointId, c.contentId, c.name.value),
                          contentMonthResp
                            .filter(
                              cm =>
                                cm.necId === c.necId && cm.viewPointId === c.viewPointId && cm.contentId === c.contentId
                            )
                            .map(cm => {
                              if (!isMonthValue(cm.month))
                                throw new Error(
                                  `CurriculumRepositoryImpl.listNECurriculums: invalid month value on ${cm.self}: ${cm.month}`
                                );
                              return new NECContentMonthTree(
                                new NECContentMonth(
                                  cm.necId,
                                  cm.viewPointId,
                                  cm.contentId,
                                  cm.month,
                                  cm.enabled,
                                  cm.text.value
                                )
                              );
                            })
                        )
                    )
                )
            )
        )
    );
  }

  async listEECurriculums(schoolYear: number, grade: Grade): Promise<EECurriculumTree[] | Err> {
    const [eecResp, eecMonthResp, activityResp] = await Promise.all([
      doReq(() => this.curriculumService.listEECurriculum(schoolYear, grade.value)),
      doReq(() => this.curriculumService.listEECMonth("-", schoolYear, grade.value)),
      doReq(() => this.curriculumService.listActivity("-", "-", undefined, undefined, schoolYear, grade.value))
    ]);
    if (eecResp instanceof Err) return eecResp;
    if (eecMonthResp instanceof Err) return eecMonthResp;
    if (activityResp instanceof Err) return activityResp;

    return eecResp.map(
      eec =>
        new EECurriculumTree(
          new EECurriculum(eec.eecId, eec.schoolYear, new Grade(eec.grade), eec.name.value),
          eecMonthResp
            .filter(m => m.eecId === eec.eecId)
            .map(m => {
              if (!isMonthValue(m.month))
                throw new Error(
                  `CurriculumRepositoryImpl.listEECurriculums: invalid month value on ${m.self}: ${m.month}`
                );
              return new EECMonthTree(
                new EECMonth(m.eecId, m.month),
                activityResp
                  .filter(a => a.eecId === m.eecId && a.month === m.month)
                  .map(a => {
                    if (!isMonthValue(a.month))
                      throw new Error(
                        `CurriculumRepositoryImpl.listEECurriculums: invalid month value on ${a.self}: ${a.month}`
                      );
                    return new EECActivityTree(
                      new EECActivity(a.eecId, a.month, a.activityId, a.enabled, a.text.value)
                    );
                  })
              );
            })
        )
    );
  }

  async listNECEvaluations(
    necId?: string,
    viewPointId?: string,
    contentId?: string,
    month?: number,
    studentUserId?: string
  ): Promise<NECEvaluation[] | Err> {
    const resp = await doReq(() =>
      this.curriculumService.listEvaluation(
        necId ?? "-",
        viewPointId ?? "-",
        contentId ?? "-",
        month?.toString() ?? "-",
        undefined,
        studentUserId
      )
    );
    if (resp instanceof Err) return resp;

    return resp.map(ev => {
      if (!isMonthValue(ev.month))
        throw new Error(`CurriculumRepositoryImpl.listNECEvaluations: invalid month value on ${ev.self}: ${ev.month}`);
      const rating = ev.rating ?? "";
      if (!isRatingValue(rating))
        throw new Error(
          `CurriculumRepositoryImpl.listNECEvaluations: invalid rating value on ${ev.self}: ${ev.rating}`
        );

      return new NECEvaluation(
        ev.necId,
        ev.viewPointId,
        ev.contentId,
        ev.month,
        ev.studentUserId,
        rating,
        ev.teacherInputPublished
      );
    });
  }

  async listEECJournalStudents(eecId: string, studentUserId?: string): Promise<EECJournalStudentTree[] | Err> {
    // TODO 全児童生徒分が(例えjournal0件でも)欲しいなら、listJournalStudentsを実装して、ここで呼び出すべき。
    //  今は、journal0件ならjournalStudent自体作られない形になっていると思われる。または、student一覧を渡す形にしても良いが。

    const [journalResp, journalFileResp] = await Promise.all([
      doReq(() => this.curriculumService.listJournal(eecId, studentUserId ?? "-")),
      doReq(() => this.curriculumService.listJournalFile(eecId, studentUserId ?? "-", "-"))
    ]);
    if (journalResp instanceof Err) return journalResp;
    if (journalFileResp instanceof Err) return journalFileResp;

    const allEECIds = uniq(journalResp.map(j => j.eecId));
    const allStudentUserIds = uniq(journalResp.map(j => j.studentUserId));

    return allEECIds.flatMap(eecId =>
      allStudentUserIds.map(
        studentUserId =>
          new EECJournalStudentTree(
            new EECJournalStudent(eecId, studentUserId),
            journalResp
              .filter(j => j.eecId === eecId && j.studentUserId === studentUserId)
              .map(
                j =>
                  new EECJournalTree(
                    new EECJournal(
                      j.eecId,
                      j.studentUserId,
                      j.journalId,
                      j.month,
                      j.activity.value,
                      j.studentComment.value,
                      j.teacherComment?.value ?? "",
                      j.teacherInputPublished,
                      j.createdAt,
                      j.studentInputLocked
                    ),
                    journalFileResp
                      .filter(
                        jf =>
                          jf.eecId === j.eecId && jf.studentUserId === j.studentUserId && jf.journalId === j.journalId
                      )
                      .map(
                        jf =>
                          new EECJournalFileTree(
                            new EECJournalFile(jf.eecId, jf.studentUserId, jf.journalId, jf.journalFileId, jf.createdAt)
                          )
                      )
                  )
              )
          )
      )
    );
  }
}
