










import Vue, { PropType } from "vue";
import { Loadable } from "@/ts/Loadable";
import { EECurriculum } from "@/ts/objects/noneditable/curriculum/EECurriculum";
import { EECJournalTree } from "@/ts/objects/noneditable/curriculum/EECJournal";
import CurriculumWriteEECPure from "@/views/curriculum/student/CurriculumWriteEEC/CurriculumWriteEECPure.vue";
import log from "loglevel";

export default Vue.extend({
  name: "CurriculumWriteEEC",
  components: { CurriculumWriteEECPure },
  props: {
    /**
     * 児童生徒ID。
     */
    studentUserId: { type: String as PropType<string | null>, required: true },

    /**
     * 文書評価教科。
     */
    eeCurriculum: { type: Object as PropType<Loadable<EECurriculum | null | undefined>> },

    /**
     * 現在表示中の教科・児童生徒配下の学習記録。
     */
    journalTrees: { type: Object as PropType<Loadable<EECJournalTree[]>>, required: true }
  },
  data(): {
    /**
     * 現在ファイルビューを開いている学習記録のリソース名。
     */
    filesViewJournal: string | null;
  } {
    return {
      filesViewJournal: null
    };
  },
  methods: {
    /**
     * 現在ファイルビューを開いている学習記録を変更する。
     * 閉じる場合はnullを渡す。
     */
    selectFilesViewJournal(rname: string | null) {
      log.debug(`selectFilesViewJournal: rname=${rname}`);
      this.filesViewJournal = rname;
    }
  }
});
