










import Vue, { PropType } from "vue";
import JournalFilePreviewItem from "@/components/journalfile/JournalFilePreviewItem.vue";
import { JournalFile } from "@/ts/objects/noneditable/JournalFile";

export default Vue.extend({
  name: "JournalFilePreviewArea",
  props: {
    journalFiles: { type: Array as PropType<JournalFile[]>, required: true },
    noFilesMessage: { type: String, required: true },
    useSmallFont: { type: Boolean, default: false }
  },
  components: { JournalFilePreviewItem }
});
