import { UserRepository } from "@/ts/repositories/UserRepository";
import { Err } from "@/ts/objects/Err";
import { UserType } from "@/ts/objects/noneditable/value/UserType";
import { ClassStudent } from "@/ts/objects/noneditable/Class";
import { Quarter } from "@/ts/objects/noneditable/value/Quarter";
import { BasicUserInfo } from "@/ts/objects/noneditable/value/BasicUserInfo";
import { UserInfo as UserInfoResp } from "@/ts/api/user-service";

export class UserRepositoryMock extends UserRepository {
  readonly debugUserId: string;
  readonly debugUserType: UserType;
  readonly currentQuarter: Quarter;

  constructor(debugUserId: string, debugUserType: UserType, currentQuarter: Quarter) {
    super();
    this.debugUserId = debugUserId;
    this.debugUserType = debugUserType;
    this.currentQuarter = currentQuarter;
  }

  async getUserInfo(_userId: string): Promise<UserInfoResp | Err> {
    // AuthServiceImplからしか呼ばれない関数なので、デバッグモードではこの関数は呼ばれない。
    throw new Error("Unimplemented");
  }

  async getCurrentQuarter(): Promise<Quarter | Err> {
    return this.currentQuarter;
  }

  async getClassStudents(_classId: string): Promise<ClassStudent[] | Err> {
    // TODO UserRepositoryMock.getClassStudentsの実装。
    throw new Error("UserRepositoryMock: getClassStudents is unimplemented.");
  }

  async getBasicUserInfo(_userId: string): Promise<BasicUserInfo | Err> {
    // TODO UserRepositoryMock.getBasicUserInfoの実装。
    throw new Error("UserRepositoryMock: getBasicUserInfo is unimplemented.");
  }

  async updateFirebaseUser(): Promise<boolean | Err> {
    // 必ず成功する。 ※false updateしない
    return false;
  }
}
