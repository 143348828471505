











import Vue, { PropType } from "vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { isNullish } from "@/ts/utils/CommonUtil";

export default Vue.extend({
  name: "JournalFileUploadButton",
  components: { LoadingBlock },
  props: {
    uploadFn: { type: Function as PropType<(file: File) => Promise<void>> },
    disabled: { type: Boolean, default: false }
  },
  data(): {
    uploadStarted: boolean;
  } {
    return {
      uploadStarted: false
    };
  },
  methods: {
    startUploading(e: Event) {
      if (this.disabled || isNullish(this.uploadFn) || this.uploadStarted) return;

      const files = (e.target as HTMLInputElement).files;
      if (!(files instanceof FileList) || files.length === 0) return;
      const file = files[0];

      this.uploadStarted = true;
      this.uploadFn(file);
    }
  }
});
