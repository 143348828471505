































































import Vue, { PropType } from "vue";
import { CurriculumStoreS } from "@/store/CurriculumStoreS";
import CurriculumReadMenu from "@/views/curriculum/student/CurriculumReadMenu/CurriculumReadMenu.vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { messages } from "@/ts/const/Messages";
import CurriculumReadNECYearly from "@/views/curriculum/student/CurriculumReadNECYearly/CurriculumReadNECYearly.vue";
import CurriculumReadNECMonthly from "@/views/curriculum/student/CurriculumReadNECMonthly/CurriculumReadNECMonthly.vue";
import CurriculumReadEEC from "@/views/curriculum/student/CurriculumReadEEC/CurriculumReadEEC.vue";

export default Vue.extend({
  name: "CurriculumRead",
  components: {
    CurriculumReadNECMonthly,
    MessageView,
    CurriculumReadMenu,
    CurriculumReadNECYearly,
    CurriculumReadEEC
  },
  props: {
    curriculumStoreS: { type: Object as PropType<CurriculumStoreS>, required: true }
  },
  computed: {
    messageView(): MessageViewParam | null {
      const store = this.curriculumStoreS;
      if (store.tab === null || store.selectedSchoolYear === null || store.grade === null || store.periodMode === null)
        return { message: messages.pageNotFound };
      return null;
    }
  }
});
