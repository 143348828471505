import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store/Store";
import { Grade } from "@/ts/objects/noneditable/value/Grade";
import { appStateStoreGlobal } from "@/store/AppStateStore";
import { NECurriculum, NECurriculumTree } from "@/ts/objects/noneditable/curriculum/NECurriculum";
import { NECEvaluation } from "@/ts/objects/noneditable/curriculum/NECEvaluation";
import { EECurriculum, EECurriculumTree } from "@/ts/objects/noneditable/curriculum/EECurriculum";
import { EECJournalStudentTree, EECJournalTree } from "@/ts/objects/noneditable/curriculum/EECJournal";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { Err } from "@/ts/objects/Err";
import { CurriculumTabName, isCurriculumTabName } from "@/ts/objects/enum/CurriculumTabName";
import { CurriculumPeriodMode, isCurriculumPeriodMode } from "@/ts/objects/enum/CurriculumPeriodMode";
import { CurriculumCollectionId, isCurriculumCollectionId } from "@/ts/objects/enum/CurriculumCollectionId";
import log from "loglevel";
import { Loadable, LoadableError, LoadableIdle, LoadableLoading } from "@/ts/Loadable";

@Module({ dynamic: true, store, name: "curriculumStoreS", namespaced: true })
export class CurriculumStoreS extends VuexModule {
  /**
   * 選択中の年度。
   */
  get selectedSchoolYear(): number | null {
    const schoolYear = parseInt(appStateStoreGlobal.route.params["curriculumSchoolYear"], 10);
    if (isNaN(schoolYear) || schoolYear < 1 || schoolYear > 9999) return null;
    return schoolYear;
  }

  /**
   * 児童生徒の学年。
   */
  get grade(): Grade | null {
    const schoolYear = this.selectedSchoolYear;
    if (schoolYear === null) return null;
    return appStateStoreGlobal.studentOrGuardianState?.studentGradeOfSchoolYear(schoolYear) ?? null;
  }

  /**
   * 児童生徒ID。
   */
  get studentUserId(): string | null {
    return appStateStoreGlobal.studentOrGuardianState?.studentUserId() ?? null;
  }

  /**
   * 選択中のタブ。
   * 書く/見る/未選択(null)。
   */
  get tab(): CurriculumTabName | null {
    const tab = appStateStoreGlobal.route.params["curriculumTab"];
    if (!isCurriculumTabName(tab)) return null;
    return tab;
  }

  /**
   * 表示期間のモード。
   * 月別表示/年間表示/未選択(null)。
   */
  get periodMode(): CurriculumPeriodMode | null {
    const periodMode = appStateStoreGlobal.route.params["curriculumPeriodMode"];
    if (!isCurriculumPeriodMode(periodMode)) return null;
    return periodMode;
  }

  /**
   * 今月。
   */
  get thisMonth(): number {
    return appStateStoreGlobal.now.month;
  }

  /**
   * 選択中の月。
   */
  get selectedMonth(): number | null {
    if (this.periodMode !== "monthly") return null;

    const month = parseInt(appStateStoreGlobal.route.params["curriculumMonth"], 10);
    if (isNaN(month) || month < 1 || month > 12) return null;
    return month;
  }

  /**
   * 選択中の教科のコレクションID。
   * "neCurriculums" or "eeCurriculums"。
   */
  get selectedCurriculumCollectionId(): CurriculumCollectionId | null {
    const collectionId = appStateStoreGlobal.route.params["curriculumCollectionId"];
    if (!isCurriculumCollectionId(collectionId)) return null;
    return collectionId;
  }

  /**
   * 選択中の教科のリソース名。
   */
  get selectedCurriculumRname(): string | null {
    const collectionId = appStateStoreGlobal.route.params["curriculumCollectionId"];
    const id = appStateStoreGlobal.route.params["curriculumId"];

    if (isCurriculumCollectionId(collectionId)) return `/${collectionId}/${id}`;
    else return null;
  }

  /**
   * 選択中の(年度, 児童生徒, 学年)の、全データをロードする。
   * ※ よって、年度, 児童生徒, 学年 のいずれかが変更されたとき、リロードが必要。
   */
  @Action
  async loadAllDataOfSelectedYear(curriculumRepository: CurriculumRepository) {
    // TODO たぶん大丈夫だとは思うが、教科学習画面にいるとき、保護者で児童生徒を切り替えたときに、前の児童生徒IDでロードしに行かないか確認する。
    const schoolYear = this.selectedSchoolYear;
    const grade = this.grade;
    const studentUserId = this.studentUserId;
    if (schoolYear === null || grade === null || studentUserId === null) {
      this.context.commit("setAllData", {
        allNECurriculums: new LoadableIdle(),
        allNECEvaluations: new LoadableIdle(),
        allEECurriculums: new LoadableIdle(),
        allEECJournalStudents: new LoadableIdle()
      });
      return;
    }

    this.context.commit("setAllData", {
      allNECurriculums: new LoadableLoading(),
      allNECEvaluations: new LoadableLoading(),
      allEECurriculums: new LoadableLoading(),
      allEECJournalStudents: new LoadableLoading()
    });

    log.debug(`CurriculumStore.loadAllData: schoolYear=${schoolYear}, grade=${grade}`);

    const [allNECurriculums, allEECurriculums] = await Promise.all([
      // allNECurriculums
      (async () => {
        log.debug(`CurriculumStore.loadAllData: neCurriculums Loadable: schoolYear=${schoolYear}, grade=${grade}`);
        const resp = await curriculumRepository.listNECurriculums(schoolYear, grade);
        if (resp instanceof Err) return null;
        return resp;
      })(),
      // allEECurriculums
      (async () => {
        const resp = await curriculumRepository.listEECurriculums(schoolYear, grade);
        if (resp instanceof Err) return null;
        return resp;
      })()
    ]);

    if (allNECurriculums === null || allEECurriculums === null) {
      this.context.commit("setAllData", {
        allNECurriculums: new LoadableError(),
        allNECEvaluations: new LoadableError(),
        allEECurriculums: new LoadableError(),
        allEECJournalStudents: new LoadableError()
      });
      return;
    }

    const [allNECEvaluations, allEECJournalStudents] = await Promise.all([
      (async () => {
        const resp = await Promise.all(
          allNECurriculums.map(c =>
            curriculumRepository.listNECEvaluations(c.self.necId, undefined, undefined, undefined, studentUserId)
          )
        );
        if (resp.some(r => r instanceof Err)) return null;
        return (resp as NECEvaluation[][]).flat();
      })(),
      (async () => {
        const resp = await Promise.all(
          allEECurriculums.map(c => curriculumRepository.listEECJournalStudents(c.self.eecId, studentUserId))
        );
        if (resp.some(r => r instanceof Err)) return null;
        return (resp as EECJournalStudentTree[][]).flat();
      })()
    ]);

    if (allNECEvaluations === null || allEECJournalStudents === null) {
      this.context.commit("setAllData", {
        allNECurriculums: new LoadableError(),
        allNECEvaluations: new LoadableError(),
        allEECurriculums: new LoadableError(),
        allEECJournalStudents: new LoadableError()
      });
      return;
    }

    this.context.commit("setAllData", {
      allNECurriculums: Loadable.fromValue(allNECurriculums),
      allNECEvaluations: Loadable.fromValue(allNECEvaluations),
      allEECurriculums: Loadable.fromValue(allEECurriculums),
      allEECJournalStudents: Loadable.fromValue(allEECJournalStudents)
    });
  }

  @Mutation
  setAllData(payload: {
    allNECurriculums: Loadable<NECurriculumTree[]>;
    allNECEvaluations: Loadable<NECEvaluation[]>;
    allEECurriculums: Loadable<EECurriculumTree[]>;
    allEECJournalStudents: Loadable<EECJournalStudentTree[]>;
  }) {
    this.neCurriculumTrees = payload.allNECurriculums;
    this.allNECEvaluations = payload.allNECEvaluations;
    this.eeCurriculumTrees = payload.allEECurriculums;
    this._allEECJournalStudentTrees = payload.allEECJournalStudents;
  }

  @Action
  async loadJournalStudents(curriculumRepository: CurriculumRepository) {
    const studentUserId = this.studentUserId;
    const allEECurriculumsData = this.eeCurriculumTrees?.data ?? null;
    if (studentUserId === null || allEECurriculumsData === null) {
      this.context.commit("setEECJournalStudents", new LoadableIdle());
      return;
    }

    this.context.commit("setEECJournalStudents", new LoadableLoading());

    const resp = await Promise.all(
      allEECurriculumsData.map(c => curriculumRepository.listEECJournalStudents(c.self.eecId, studentUserId))
    );
    if (resp.some(r => r instanceof Err)) {
      this.context.commit("setEECJournalStudents", new LoadableError());
      return;
    }
    const eecJournalStudents = (resp as EECJournalStudentTree[][]).flat();

    this.context.commit("setEECJournalStudents", Loadable.fromValue(eecJournalStudents));
  }

  @Mutation
  setEECJournalStudents(value: Loadable<EECJournalStudentTree[]>) {
    this._allEECJournalStudentTrees = value;
  }

  /**
   * 選択中の(年度, 児童生徒, 学年)の、数値評価教科のリスト(ツリー)。
   */
  neCurriculumTrees: Loadable<NECurriculumTree[]> = new LoadableIdle();

  /**
   * 選択中の(年度, 児童生徒, 学年)の、数値評価教科のリスト。
   */
  get neCurriculums(): Loadable<NECurriculum[]> {
    return Loadable.fromLoadable(this.neCurriculumTrees, trees => trees.map(t => t.self));
  }

  /**
   * 選択中の数値評価教科(ツリー)。
   */
  get neCurriculumTree(): Loadable<NECurriculumTree | null> {
    const _neCurriculums = this.neCurriculumTrees;
    if (_neCurriculums === null) return new LoadableIdle();

    const selectedCurriculumRname = this.selectedCurriculumRname;

    return Loadable.fromLoadable(_neCurriculums, neCurriculums => {
      if (selectedCurriculumRname === null) return null;
      return neCurriculums.find(c => c.self.resourceName === selectedCurriculumRname) ?? null;
    });
  }

  /**
   * 選択中の数値評価教科。
   */
  get neCurriculum(): Loadable<NECurriculum | null> {
    return Loadable.fromLoadable(this.neCurriculumTree, neCurriculumTree => neCurriculumTree?.self ?? null);
  }

  /**
   * 選択中の(年度, 児童生徒, 学年)の、数値評価教科の評価。
   */
  allNECEvaluations: Loadable<NECEvaluation[]> = new LoadableIdle();

  /**
   * 選択中の数値評価教科・児童生徒でフィルタ済の、数値評価教科の評価。
   */
  get necEvaluationsOfYear(): Loadable<NECEvaluation[]> {
    const _neCurriculum = this.neCurriculumTree;
    const allNECEvaluations = this.allNECEvaluations;
    if (_neCurriculum === null || allNECEvaluations === null) return new LoadableIdle();

    const studentUserId = this.studentUserId;

    return Loadable.fromLoadable2(allNECEvaluations, _neCurriculum, (allNECEvaluations, neCurriculum) => {
      if (neCurriculum === null) return [];
      return allNECEvaluations.filter(e => e.necId === neCurriculum.self.necId && e.studentUserId === studentUserId);
    });
  }

  /**
   * 選択中の数値評価教科・児童生徒・月でフィルタ済の、数値評価教科の評価。
   */
  get necEvaluationsOfMonth(): Loadable<NECEvaluation[]> {
    const _necEvaluationsOfYear = this.necEvaluationsOfYear;
    if (_necEvaluationsOfYear === null) return new LoadableIdle();

    const month = this.selectedMonth;

    return Loadable.fromLoadable(_necEvaluationsOfYear, necEvaluationsOfYear => {
      if (necEvaluationsOfYear === null) return [];
      return necEvaluationsOfYear.filter(e => e.month === month);
    });
  }

  /**
   * 選択中の(年度, 児童生徒, 学年)の、文書評価教科のリスト(ツリー)。
   */
  eeCurriculumTrees: Loadable<EECurriculumTree[]> = new LoadableIdle();

  /**
   * 選択中の(年度, 児童生徒, 学年)の、文書評価教科のリスト。
   */
  get eeCurriculums(): Loadable<EECurriculum[]> {
    return Loadable.fromLoadable(this.eeCurriculumTrees, trees => trees.map(t => t.self));
  }

  /**
   * 選択中の文書評価教科(ツリー)。
   */
  get eeCurriculumTree(): Loadable<EECurriculumTree | null> {
    const _eeCurriculumTrees = this.eeCurriculumTrees;
    if (_eeCurriculumTrees === null) return new LoadableIdle();

    const selectedCurriculumRname = this.selectedCurriculumRname;
    if (selectedCurriculumRname === null) return new LoadableIdle();

    return Loadable.fromLoadable(_eeCurriculumTrees, eeCurriculumTrees => {
      return eeCurriculumTrees.find(c => c.self.resourceName === selectedCurriculumRname) ?? null;
    });
  }

  get eeCurriculum(): Loadable<EECurriculum | null> {
    return Loadable.fromLoadable(this.eeCurriculumTree, eeCurriculumTree => eeCurriculumTree?.self ?? null);
  }

  /**
   * 選択中の(年度, 児童生徒, 学年)の、文書評価教科の学習活動記録を持つ児童生徒。
   */
  _allEECJournalStudentTrees: Loadable<EECJournalStudentTree[]> = new LoadableIdle();

  /**
   * 選択中の(年度, 児童生徒, 学年)の、文書評価教科の学習活動記録。
   */
  get allEECJournalTrees(): Loadable<EECJournalTree[]> {
    return Loadable.fromLoadable(this._allEECJournalStudentTrees, allEECJournalStudentTrees =>
      allEECJournalStudentTrees.flatMap(s => s.journals)
    );
  }

  /**
   * 選択中の文書評価教科・児童生徒でフィルタ済の、文書評価教科の学習活動記録。
   */
  get eecJournalTreesOfYear(): Loadable<EECJournalTree[]> {
    const _allEECJournalStudents = this._allEECJournalStudentTrees;
    const _eeCurriculum = this.eeCurriculumTree;
    if (_allEECJournalStudents === null || _eeCurriculum === null) return new LoadableIdle();

    const studentUserId = this.studentUserId;

    return Loadable.fromLoadable2(_allEECJournalStudents, _eeCurriculum, (allEECJournalStudents, eeCurriculum) => {
      if (eeCurriculum === null) return [];
      return allEECJournalStudents
        .filter(s => s.self.eecId === eeCurriculum.self.eecId && s.self.studentUserId === studentUserId)
        .flatMap(s => s.journals);
    });
  }

  /**
   * 選択中の文書評価教科・児童生徒・月でフィルタ済の、文書評価教科の学習記録。
   */
  get eecJournalTreesOfMonth(): Loadable<EECJournalTree[]> {
    const _eecJournalsOfYear = this.eecJournalTreesOfYear;
    if (_eecJournalsOfYear === null) return new LoadableIdle();

    const month = this.selectedMonth;
    if (month === null) return new LoadableIdle();

    return Loadable.fromLoadable(_eecJournalsOfYear, eecJournalsOfYear => {
      if (eecJournalsOfYear === null) return [];
      return eecJournalsOfYear.filter(j => j.self.month === month);
    });
  }

  /**
   * 最初の教科を取得する。
   */
  get firstCurriculumTree(): Loadable<NECurriculumTree | EECurriculumTree | null> {
    const _neCurriculums = this.neCurriculumTrees;
    const _eeCurriculums = this.eeCurriculumTrees;
    if (_neCurriculums === null || _eeCurriculums === null) return new LoadableIdle();
    return Loadable.fromLoadable2(_neCurriculums, _eeCurriculums, (neCurriculums, eeCurriculums) => {
      log.debug(
        `CurriculumStore.firstCurriculum: neCurriculums.length=${neCurriculums.length}, eeCurriculums.length=${eeCurriculums.length}`
      );
      if (neCurriculums.length >= 1) return neCurriculums[0];
      else if (eeCurriculums.length >= 1) return eeCurriculums[0];
      else return null;
    });
  }

  /**
   * 最初の文書評価教科を取得する。
   */
  get firstEECurriculumTree(): Loadable<EECurriculumTree | null> {
    const _eeCurriculums = this.eeCurriculumTrees;
    if (_eeCurriculums === null) return new LoadableIdle();
    return Loadable.fromLoadable(_eeCurriculums, eeCurriculums => {
      if (eeCurriculums.length >= 1) return eeCurriculums[0];
      else return null;
    });
  }

  get path() {
    const _base = appStateStoreGlobal.studentOrGuardianBasePath;

    return function(
      schoolYear: number | null,
      tab: CurriculumTabName,
      periodMode: CurriculumPeriodMode | null,
      month: number | null,
      resourceName: string | null
    ) {
      const _schoolYear = schoolYear ?? 2000;
      const _periodMode = periodMode ?? "-";
      const _month = month ?? "-";
      const _resourceName = resourceName ?? "/-/-";
      return `${_base}/curriculum/${_schoolYear}/${tab}/${_periodMode}/${_month}${_resourceName}`;
    };
  }

  get pathOfSelectedYear() {
    const schoolYear = this.selectedSchoolYear;
    const getPath = this.path;

    return function(
      tab: CurriculumTabName,
      periodMode: CurriculumPeriodMode | null,
      month: number | null,
      resourceName: string | null
    ) {
      return getPath(schoolYear, tab, periodMode, month, resourceName);
    };
  }

  get firstCurriculumWritePath(): Loadable<string> {
    const firstCurriculum = this.firstEECurriculumTree;
    if (firstCurriculum === null) return new LoadableIdle();
    return Loadable.fromLoadable(firstCurriculum, curriculum => {
      if (curriculum === null) return this.pathOfSelectedYear("write", null, null, null);
      return this.pathOfSelectedYear("write", null, null, curriculum.self.resourceName);
    });
  }

  get firstCurriculumReadPath(): Loadable<string> {
    const firstCurriculum = this.firstCurriculumTree;
    if (firstCurriculum === null) return new LoadableIdle();
    return Loadable.fromLoadable(firstCurriculum, curriculum => {
      if (curriculum === null) return this.pathOfSelectedYear("read", "monthly", null, null);
      return this.pathOfSelectedYear("read", "monthly", appStateStoreGlobal.now.month, curriculum.self.resourceName);
    });
  }

  get defaultPath(): Loadable<string> {
    if (appStateStoreGlobal.isAppUserStudent) return this.firstCurriculumWritePath;
    if (appStateStoreGlobal.isAppUserGuardian) return this.firstCurriculumReadPath;
    return Loadable.fromValue("");
  }

  get defaultPathOfSelectedTab(): Loadable<string> {
    if (this.tab === "write") return this.firstCurriculumWritePath;
    if (this.tab === "read") return this.firstCurriculumReadPath;
    return Loadable.fromValue("");
  }
}
