import { doReq } from "@/ts/Services";
import Axios from "axios";
import { Err } from "@/ts/objects/Err";
import { SchoolType } from "@/ts/objects/noneditable/Class";
import chunk from "lodash/chunk";
import log from "loglevel";
import { utcToZonedTime } from "date-fns-tz";

export function parseUTCOrZero(text: string, timezone = "Asia/Tokyo"): Date {
  try {
    return utcToZonedTime(text, timezone);
  } catch (e) {
    log.debug(`parseDateOrZero: Failed to parse date. text=${text}`);
    return new Date(0);
  }
}

export async function downloadFileByUrl(url: string, filename: string, mediaType: string) {
  const resp: any = await doReq(() => Axios.get(url, { responseType: "blob" }));
  if (resp instanceof Err) {
    log.info(`Error on download: ${resp.internalMessage}`);
    return;
  }

  const blob = new Blob([resp], { type: mediaType });
  await downloadBlob(blob, filename);
}

export async function downloadBlob(blob: Blob, filename: string) {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  a.target = "_blank";
  a.click();
  URL.revokeObjectURL(a.href);
}

export function projectRnameToId(resourceName: string): string | null {
  const m = /^\/projects\/([\w-]+)$/.exec(resourceName);
  if (m === null || m[1] === undefined) return null;
  return m[1];
}

export function projectLookbackRnameToId(resourceName: string): [string, string] | null {
  const m = /^\/projects\/([\w-]+)\/lookbacks\/([\w-]+)$/.exec(resourceName);
  if (m === null || m[1] === undefined || m[2] === undefined) return null;
  return [m[1], m[2]];
}

export function projectRubricRnameToId(resourceName: string): [string, string] | null {
  const m = /^\/projects\/([\w-]+)\/rubrics\/([\w-]+)$/.exec(resourceName);
  if (m === null || m[1] === undefined || m[2] === undefined) return null;
  return [m[1], m[2]];
}

export function projectJournalRnameToId(resourceName: string): [string, string, string] | null {
  const m = /^\/projects\/([\w-]+)\/rubrics\/([\w-]+)\/journals\/([\w-]+)$/.exec(resourceName);
  if (m === null || m[1] === undefined || m[2] === undefined || m[3] === undefined) return null;
  return [m[1], m[2], m[3]];
}

export function projectJournalFileRnameToId(resourceName: string): [string, string, string, string] | null {
  const m = /^\/projects\/([\w-]+)\/rubrics\/([\w-]+)\/journals\/([\w-]+)\/journalFiles\/([\w-]+)$/.exec(resourceName);
  if (m === null || m[1] === undefined || m[2] === undefined || m[3] === undefined || m[4] === undefined) return null;
  return [m[1], m[2], m[3], m[4]];
}

export type RnameObject = {
  rname: string;
  fragments: RnameObjectFragment[];
};

export type RnameObjectFragment = {
  collectionId: string;
  resourceId: string;
};

export function rnameToRnameObject(resourceName: string): RnameObject | null {
  const splitName = resourceName.split("/");

  if (splitName.length <= 1 || splitName.length % 2 !== 1 || splitName[0] !== "") return null;

  const fragments = splitName.slice(1);

  const regex = /^[\w-]+$/;
  if (!fragments.every(fragment => regex.test(fragment))) return null;

  return {
    rname: resourceName,
    fragments: chunk(fragments, 2).map(chunk => {
      return {
        collectionId: chunk[0],
        resourceId: chunk[1]
      };
    })
  };
}

export function fileTypeToJapanese(fileType: string): string {
  switch (fileType) {
    case "image":
      return "画像";
    case "video":
      return "動画";
    case "audio":
      return "音声";
    case "slides":
      return "プレゼンテーション";
    default:
      return "ファイル";
  }
}

export function fileTypeToIcon(fileType: string): [string, string] {
  switch (fileType) {
    case "image":
      return ["fas", "image"];
    case "video":
      return ["fas", "file-video"];
    case "audio":
      return ["fas", "file-audio"];
    default:
      return ["fas", "file-alt"];
  }
}

export function solanProcessTextOf(process: number): string {
  switch (process) {
    case 0:
      return "みつける";
    case 1:
      return "しらべる";
    case 2:
      return "まとめる";
    case 3:
      return "つたえる";
    default:
      return "???";
  }
}

export function schoolTypeText(schoolType: SchoolType): string {
  switch (schoolType) {
    case SchoolType.ElementarySchool:
      return "小学校";
    case SchoolType.JuniorHighSchool:
      return "中学校";
    default:
      return "";
  }
}
