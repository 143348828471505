















import Vue, { PropType } from "vue";
import { MessageViewParam } from "@/components/MessageView.vue";
import { messages } from "@/ts/const/Messages";
import { SolanStoreS } from "@/store/SolanStore";
import { AppStateStore } from "@/store/AppStateStore";
import { Err } from "@/ts/objects/Err";
import { SaveResult } from "@/ts/objects/editable/SaveResult";
import { EditableSolanProject } from "@/ts/objects/editable/solan/EditableSolanProject";
import { NavigationGuardNext, Route } from "vue-router";
import { SolanProjectInfo, SolanProjectInfoOnEditStore } from "@/store/SolanProjectOnEditStore";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import SolanAboutPure from "@/views/solan/student/SolanAbout/SolanAboutPure.vue";
import { SolanRepository } from "@/ts/repositories/SolanRepository";

export default Vue.extend({
  name: "SolanAbout",
  components: { SolanAboutPure },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    solanStoreS: { type: Object as PropType<SolanStoreS>, required: true },
    solanProjectInfoOnEditStore: { type: Object as PropType<SolanProjectInfoOnEditStore>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true }
  },
  created() {
    this.pageLeaveService = new PageLeaveService(
      async () => {
        // 編集中でなければ、セーブ漏れを拾うために念のため再セーブする。
        // 編集中なら、キャンセルのためにleaveしようとしている可能性があるため、セーブしない。
        if (!this.editing) {
          await this.saveAllAndReloadProjectsIfNeeded();
        }
      },
      async () => !this.needSave,
      0
    );

    this.solanStoreS.project.getDataWithTimeout().then(project => {
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectSolanTheme };
        return;
      }

      this.studentInputLocked = project.studentInputLocked;

      this.solanRepository.getEditableSolanProject(project.projectId, true).then(resp => {
        if (resp instanceof Err) {
          log.debug("Error loading project!");
          this.messageView = { message: messages.failedToLoadData, fadeIn: true };
          return;
        }

        this.project = resp;
      });
    });
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    this.solanProjectInfoOnEditStore.finishEditing();
  },
  data(): {
    messageView: MessageViewParam | null;

    studentInputLocked: boolean;
    editing: boolean;
    project: EditableSolanProject | null;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      messageView: null,

      studentInputLocked: false,
      editing: false,
      project: null,

      pageLeaveService: null
    };
  },
  computed: {
    headerProjectInfo(): SolanProjectInfo | null {
      return this.solanProjectInfoOnEditStore.info;
    },
    needSave(): boolean {
      const project = this.project;
      if (project === null) return false;
      return project.needSave();
    }
  },
  watch: {
    async editing(editing: boolean) {
      log.debug(`watch editing triggered: ${editing}`);
      const project = this.project;
      if (editing && project !== null) {
        this.solanProjectInfoOnEditStore.startEditing({
          name: project.name
        });
      } else {
        this.solanProjectInfoOnEditStore.finishEditing();
        this.saveAllAndReloadProjectsIfNeeded();
      }
    },
    headerProjectInfo: {
      handler: function(info: SolanProjectInfo | null) {
        const project = this.project;
        if (info === null || project === null) return;

        // プロジェクトヘッダ部分の編集内容を反映。
        project.name = info.name;
      },
      deep: true
    }
  },
  methods: {
    setEditing(editing: boolean) {
      this.editing = editing;
    },
    async saveAllAndReloadProjectsIfNeeded(): Promise<void> {
      const needProjectsReload = await this.saveAll();
      if (!needProjectsReload) return;
      const studentUserId = this.appStateStore.studentOrGuardianState?.studentInfo()?.studentUserId;
      if (studentUserId === undefined) return;
      await this.solanStoreS.reloadProjects({ solanRepository: this.solanRepository, studentUserId });
    },
    async saveAll(): Promise<boolean> {
      log.debug("SAVING!");

      const project: EditableSolanProject | null = this.project;
      if (project === null) return false;

      const projectSaveResult: SaveResult = await project.saveAllChanges(true);
      const needProjectsReload = projectSaveResult.didSave || projectSaveResult.someSkipped;
      return needProjectsReload;
    },
    async deleteProject() {
      if (!this.editing) return;

      const project = this.project;
      if (project === null) return;

      const studentUserId = this.appStateStore.studentOrGuardianState?.studentInfo()?.studentUserId;
      if (studentUserId === undefined) {
        return;
      }

      if (!window.confirm(`このテーマを消しますか？`)) return;

      const resp = await this.solanRepository.deleteProject(project.projectId);
      if (resp instanceof Err) return; // TODO エラー処理

      this.solanProjectInfoOnEditStore.finishEditing();

      await this.solanStoreS.reloadProjects({ solanRepository: this.solanRepository, studentUserId });
      await this.$router.push(this.solanStoreS.studentInitPath).catch(() => {});
    },
    async startProject() {
      if (!this.editing) return;

      const project = this.project;
      if (project === null) return;

      if (!window.confirm(`このテーマで始めますか？`)) return;

      project.started = true;

      await this.saveAllAndReloadProjectsIfNeeded();
    }
  }
});
