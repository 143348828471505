





















































































































import Vue, { PropType } from "vue";
import PopupMenuButton, { MenuButton } from "@/components/PopupMenuButton.vue";
import ImageModal from "@/components/ImageModal.vue";
import UrlToLink from "@/components/UrlToLink.vue";
import { BasicUserInfo } from "@/ts/objects/noneditable/value/BasicUserInfo";
import { ActivityLog } from "@/ts/objects/plain/ActivityLog";
import find from "lodash/find";

export default Vue.extend({
  components: { PopupMenuButton, UrlToLink },
  name: "ActivityLogRow",
  props: {
    activitylog: { type: Object as PropType<ActivityLog>, required: true },
    unReadRow: { type: Boolean, required: true },
    basicUserInfos: { type: Array as PropType<BasicUserInfo[]>, required: true },
    isAppUserTeacher: { type: Boolean, required: true },
    trigger: { type: Boolean, default: true }
  },
  data(): {
    defaultAccountImage: string;
    activityPopupMenuItems: any;
    unReadUUID: string;
  } {
    return {
      defaultAccountImage: require("@/assets/default_account.png"),
      activityPopupMenuItems: [new MenuButton("delete", "削除", ["fas", "trash-alt"])],
      unReadUUID: ""
    };
  },
  computed: {
    userName() {
      const findUser = find(this.basicUserInfos, ["userId", this.activitylog.userId]);
      return findUser?.name;
    },
    userIconUrl() {
      const findUser = find(this.basicUserInfos, ["userId", this.activitylog.userId]);
      return findUser?.photoUrl;
    }
  },
  methods: {
    // isFirstUnRead(read: boolean): boolean {
    //   if (this.unReadUUID === "" && !read) {
    //     this.unReadUUID = "idx";
    //     return true;
    //   }

    //   //   if (idx > 0) {
    //   //     // 1つ前のactivityがunreadの場合はfalse
    //   //     if (!activitylog[idx - 1].advanced.read) return false;
    //   //   }
    //   //   return true;
    //   // }
    //   return false;
    // },
    del(menuKey: string, activityId: string) {
      this.$emit("del", menuKey, activityId);
    },
    mylist(activityId: string, flg: boolean) {
      this.$emit("mylist", activityId, flg);
    },
    async showFullImage(
      imageFileName: string,
      imageUrl: string,
      imageHeight: number,
      imageWidth: number,
      type: string,
      mediaType: string
    ) {
      if (imageUrl === null) return;
      if (imageHeight === null) return;
      if (imageWidth === null) return;

      this.$modal.show(
        ImageModal,
        {
          url: imageUrl,
          height: imageHeight,
          width: imageWidth,
          filename: imageFileName,
          type: type,
          mediatype: mediaType
        },
        {
          adaptive: true,
          minWidth: "30%",
          maxWidth: "80%",
          height: "auto"
        }
      );
    },
    async showFullOther(fileileName: string, url: string, type: string, mediaType: string) {
      if (url === null) return;

      this.$modal.show(
        ImageModal,
        {
          url: url,
          filename: fileileName,
          type: type,
          mediatype: mediaType
        },
        {
          adaptive: true,
          width: "400px",
          height: "150px"
        }
      );
    },
    async showFullAudio(fileileName: string, url: string, type: string, mediaType: string) {
      if (url === null) return;

      this.$modal.show(
        ImageModal,
        {
          url: url,
          filename: fileileName,
          type: type,
          mediatype: mediaType
        },
        {
          adaptive: true,
          width: "400px",
          height: "150px"
        }
      );
    },
    async showFullVideo(fileileName: string, url: string, type: string, mediaType: string) {
      if (url === null) return;

      this.$modal.show(
        ImageModal,
        {
          url: url,
          filename: fileileName,
          type: type,
          mediatype: mediaType
        },
        {
          minWidth: "30%",
          maxWidth: "80%",
          height: "auto"
        }
      );
    }
  }
});
