import { parseUTCOrZero } from "@/ts/utils/AppUtil";

export class SolanRubric {
  readonly rubricId: string;
  readonly projectId: string;
  readonly process: number;
  readonly learningActivity: string;
  readonly viewPointS: string;
  readonly viewPointA: string;
  readonly viewPointB: string;
  readonly viewPointC: string;
  readonly createdAt: Date;

  constructor(
    rubricId: string,
    projectId: string,
    process: number,
    learningActivity: string,
    viewPointS: string,
    viewPointA: string,
    viewPointB: string,
    viewPointC: string,
    createdAt: string
  ) {
    this.rubricId = rubricId;
    this.projectId = projectId;
    this.process = process;
    this.learningActivity = learningActivity;
    this.viewPointS = viewPointS;
    this.viewPointA = viewPointA;
    this.viewPointB = viewPointB;
    this.viewPointC = viewPointC;
    this.createdAt = parseUTCOrZero(createdAt);
  }
}
