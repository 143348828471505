






































































import Vue, { PropType } from "vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { SolanProject } from "@/ts/objects/noneditable/solan/SolanProject";
import { SolanStoreT } from "@/store/SolanStore";
import { SolanRubric } from "@/ts/objects/noneditable/solan/SolanRubric";
import { messages } from "@/ts/const/Messages";
import { Err } from "@/ts/objects/Err";
import { NavigationGuardNext, Route } from "vue-router";
import { solanProcessTextOf } from "@/ts/utils/AppUtil";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import { UserRepository } from "@/ts/repositories/UserRepository";

export default Vue.extend({
  name: "SolanStudentViewRubricsT",
  components: { LoadingBlock, MessageView },
  props: {
    solanStoreT: { type: Object as PropType<SolanStoreT>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true }
  },
  created() {
    this.reloadData();
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.reloadData();
    next();
  },
  data(): {
    messageView: MessageViewParam | null;

    project: SolanProject | null;
    rubrics: SolanRubric[] | null;
  } {
    return {
      messageView: null,

      project: null,
      rubrics: null
    };
  },
  computed: {
    reversedRubrics(): SolanRubric[] | null {
      const rubrics = this.rubrics;
      if (rubrics === null) return null;
      return rubrics.slice().reverse();
    }
  },
  methods: {
    async reloadData() {
      this.rubrics = null;
      this.messageView = null;

      const studentUserId = this.$route.params["solanStudentUserId"];
      if (studentUserId === null || studentUserId === undefined) {
        this.messageView = { message: messages.pleaseSelectStudent };
        return;
      }

      await this.solanStoreT.reloadProjectsIfStudentChanged({
        userRepository: this.userRepository,
        solanRepository: this.solanRepository,
        studentUserId
      });

      const projectId = this.$route.params["solanProjectId"];
      if (projectId === null || projectId === undefined) {
        this.messageView = { message: messages.pleaseSelectSolanTheme };
        return;
      }

      const project = await this.solanStoreT.getProject(projectId).getDataWithTimeout();
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectSolanTheme };
        return;
      }

      const resp = await this.solanRepository.listSolanRubrics(project.projectId);
      if (resp instanceof Err) {
        this.messageView = { message: messages.failedToLoadData, fadeIn: true };
        return;
      }

      this.project = project;
      this.rubrics = resp;
    },
    _solanProcessTextOf(process: number): string {
      return solanProcessTextOf(process);
    }
  }
});
