export class PageLeaveService {
  /**
   * page-leave処理の開始時に呼ばれるコールバック。
   */
  private readonly onLeaveStart: () => Promise<void>;

  /**
   * page-leaveが成功するための条件。
   * これが満たされるまで、timeoutSecで指定した秒数だけ待つ。
   */
  private readonly requirementToLeave: () => Promise<boolean>;

  /**
   * page-leaveが成功するための条件が満たされたときのコールバック。
   */
  private readonly onRequirementMet: () => Promise<void>;

  /**
   * page-leaveが成功するための条件が満たされず、タイムアウトしたときのコールバック。
   * タイムアウトした場合も、その後の確認メッセージでユーザーが「OK」を選択した場合、page-leaveする。
   */
  private readonly onRequirementUnmet: () => Promise<void>;

  /**
   * 条件が満たされず、page-leave処理がタイムアウトした後、「それでもページを離れて良いですか？」と
   * 訊くためのメッセージ。これに対してユーザーが「OK」を選択した場合、page-leaveする。
   */
  private readonly confirmationMessageAfterTimeout: string;

  /**
   * page-leave処理のタイムアウト秒数。
   */
  private readonly timeoutSec: number;

  /**
   * tryLeaveが呼ばれた回数のカウンタ。
   * 重複して呼ばれた場合、最後の呼び出し以外はすべてfalseを返したい。
   * ・・・ので、その目的で用いる。
   */
  private numFunctionCalls: number = 0;

  constructor(
    onLeaveStart: () => Promise<void>,
    requirementToLeave: () => Promise<boolean>,
    timeoutSec: number = 4,
    confirmationMessageAfterTimeout: string = "移動すると未保存の入力が失われます。よろしいですか？",
    onRequirementMet: () => Promise<void> = async () => {},
    onRequirementUnmet: () => Promise<void> = async () => {}
  ) {
    this.onLeaveStart = onLeaveStart;
    this.requirementToLeave = requirementToLeave;
    this.onRequirementMet = onRequirementMet;
    this.onRequirementUnmet = onRequirementUnmet;
    this.confirmationMessageAfterTimeout = confirmationMessageAfterTimeout;
    this.timeoutSec = timeoutSec;
  }

  /**
   * page-leaveしようとする。
   * （実際にはこの処理がページ遷移を発生させるわけではなく、ページ遷移して良いかどうかをbooleanで返すだけ。）
   * beforeRouteUpdateや、beforeRouteLeaveから呼び出される想定。
   */
  async tryLeave(): Promise<boolean> {
    const functionNumber = ++this.numFunctionCalls;

    // page-leave開始前処理を行う。
    await this.onLeaveStart();

    // 最初から条件が満たされていれば、すぐにtrueを返す。
    if (await this.requirementToLeave()) {
      await this.onRequirementMet();

      // 最後のtryLeave呼び出しであれば、trueを返す。最後以外なら問答無用でfalseを返す。
      return this.isLastActiveFunctionCall(functionNumber);
    }

    // 条件が満たされていなければ、タイムアウトまで待つ。条件が満たされたか、1秒おきにチェックする。
    for (let i = 0; i < this.timeoutSec; i++) {
      await new Promise<void>(resolve => setTimeout(() => resolve(), 1000));
      if (await this.requirementToLeave()) {
        await this.onRequirementMet();

        // 最後のtryLeave呼び出しであれば、trueを返す。最後以外なら問答無用でfalseを返す。
        return this.isLastActiveFunctionCall(functionNumber);
      }
    }

    // 条件が満たされないままタイムアウトした。
    await this.onRequirementUnmet();

    // 最後のtryLeave呼び出しであれば、ユーザーに確認メッセージを表示する。最後以外なら問答無用でfalseを返す。
    if (!this.isLastActiveFunctionCall(functionNumber)) return false;

    // 「条件が満たされていないが、良いか？」とユーザーに訊いて、OKならtrue、ダメならfalseを返す。
    return window.confirm(this.confirmationMessageAfterTimeout);
  }

  private isLastActiveFunctionCall(functionNumber: number) {
    return this.numFunctionCalls === functionNumber;
  }
}
