






import Vue from "vue";
import CurriculumTitle from "@/views/curriculum/components/CurriculumTitle/CurriculumTitle.vue";

export default Vue.extend({
  name: "CurriculumReadMainHeader",
  components: { CurriculumTitle },
  props: {
    // TODO curriculum icon

    curriculumName: { type: String, required: true }
  }
});
